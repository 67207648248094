import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { safeUtcDate } from "@shared/helpers/utilities.ts";
import { useCopyRequestMutation } from "@shared/hooks";

import CopyActionItemsTable from "./CopyActionItemsTable";
import CopyUserInputFields from "./CopyUserInputFields";
import SelectProjectCopyStep from "./SelectProjectCopyStep";

export function useGetCopySteps({
  project,
  viewScope,
  onNext,
  handleSubmitted
}) {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState();
  const [selectedActionItems, setSelectedActionItems] = useState([]);
  const [
    copyRequest,
    { error, isSuccess, isLoading: isSubmittingCopyRequest }
  ] = useCopyRequestMutation();

  useEffect(() => {
    if (isSuccess) {
      handleSubmitted();
    }
  }, [handleSubmitted, isSuccess]);

  // step 1
  const SelectProject = useCallback(() => {
    const handleSelectProject = ({ project }) => {
      setSelectedProject(project?.value);
      onNext();
    };

    const schema = yup.object({
      project: yup.object().required()
    });

    return {
      title: t(
        "requests:requests.ui.populateRequestForm.copyRequest.selectProject.title"
      ),
      schema,
      handleSubmit: handleSelectProject,
      content: <SelectProjectCopyStep project={project} />
    };
  }, [onNext, project, t]);

  //step 2 here
  const SelectActionItems = useMemo(
    () => () => {
      return {
        title: t(
          "requests:requests.ui.populateRequestForm.copyRequest.selectRequestItems.title"
        ),
        schema: yup.object({
          selectedActionItemsCount: yup.number().required().min(1)
        }),
        content: (
          <CopyActionItemsTable
            project={selectedProject}
            onSelectionChanged={setSelectedActionItems}
            targetProject={project}
            viewScope={viewScope}
          />
        )
      };
    },
    [project, selectedProject, t]
  );

  // step 3
  const [selectUserInputSchema, setSelectUserInputSchema] = useState();
  const SelectUserInputFormRef = useCallback(node => {
    if (node != null) {
      setSelectUserInputSchema(node.getSchema() ?? yup.object({}));
    }
  }, []);

  const SelectUserInput = useCallback(() => {
    const handleSubmitQuery = fields => {
      if (isSubmittingCopyRequest) {
        return;
      }
      const data = {
        queryIds: selectedActionItems?.map(a => a.id) ?? [],
        fields: {
          ...fields,
          assignedTo: { id: fields.assignedTo?.value.id },
          copiedTo: fields.copiedTo?.map(c => c.value),
          entities: fields.entities?.map(e => e.value),
          requiredBy: safeUtcDate(fields.requiredBy)
        },
        overrideMessage: fields.overrideMessage
      };
      copyRequest({ data, projectId: project.id });
    };
    return {
      title: t(
        "requests:requests.ui.populateRequestForm.copyRequest.userInput.title"
      ),
      handleSubmit: handleSubmitQuery,
      schema: selectUserInputSchema,
      content: (
        <CopyUserInputFields
          ref={SelectUserInputFormRef}
          entities={project?.entities?.map(e => ({
            name: e.name,
            value: e
          }))}
          project={project}
          actionItems={selectedActionItems}
          error={error}
        />
      )
    };
  }, [
    t,
    selectUserInputSchema,
    SelectUserInputFormRef,
    project,
    selectedActionItems,
    error,
    isSubmittingCopyRequest,
    copyRequest
  ]);

  return [SelectProject(), SelectActionItems(), SelectUserInput()];
}
