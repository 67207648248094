import React, { useCallback, useMemo, useState } from "react";

import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import ions from "@ions";

import { classNames, returnStringIfTrue } from "@app/helpers/componentHelpers";
import { SwimlaneItem, Swimlane as SwimlaneType } from "@app/types";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonSize } from "@atoms/Button";

import "./Swimlane.scss";

interface SwimlaneProps {
  swimlane: SwimlaneType;
  renderItem: (item: SwimlaneItem, isDragging?: boolean) => React.JSX.Element;
  onClickItem?: (item: SwimlaneItem) => void;
  isDragging?: boolean;
  isValidZone?: boolean;
  allLanesAcceptable?: boolean;
}

export const Swimlane = ({
  swimlane,
  renderItem,
  onClickItem,
  isDragging,
  isValidZone,
  allLanesAcceptable
}: React.PropsWithChildren<SwimlaneProps>) => {
  const { t } = useTranslation();

  const [draggingOver, setDraggingOver] = useState(false);

  const swimlaneAction = useMemo(() => {
    if (isDragging || draggingOver || !swimlane.action) {
      return <></>;
    }
    return (
      <Inline
        className={[
          "swimlane-template__action",
          returnStringIfTrue(
            swimlane.action.alwaysVisible,
            "swimlane-template__action--always-visible"
          )
        ]}
      >
        <Button
          key={swimlane.action.labelKey}
          iconName={swimlane.action.iconName ?? ""}
          label={t(swimlane.action.labelKey)}
          variant={
            ions.components.templates.kanban.swimlane.action.button_variant
          }
          onClick={swimlane.action.onClick}
          size={ButtonSize.SMALL}
        />
      </Inline>
    );
  }, [draggingOver, isDragging, swimlane.action, t]);

  const totalItemsLength = useMemo(
    () => swimlane.items.length,
    [swimlane.items]
  );

  const filteredItemsLength = useMemo(
    () =>
      swimlane.items.filter((si: { hidden: boolean }) => si.hidden !== true)
        ?.length,
    [swimlane.items]
  );

  const visibleCountText = useMemo(() => {
    if (totalItemsLength === 0 || filteredItemsLength === 0) {
      return;
    }

    if (totalItemsLength === filteredItemsLength) {
      return totalItemsLength;
    }

    return `${filteredItemsLength}/${totalItemsLength}`;
  }, [totalItemsLength, filteredItemsLength]);

  const renderDraggableItem = useCallback(
    (item, index) => {
      const isDragDisabled =
        !allLanesAcceptable && !item.acceptableLanesToMove?.length;
      return (
        <Draggable
          key={item.id}
          draggableId={`${item.id}`}
          index={index}
          isDragDisabled={isDragDisabled || item.isLoading}
        >
          {(provided, snapshot) => (
            <div
              className={classNames([
                "droppable-item",
                returnStringIfTrue(
                  snapshot.isDragging,
                  "droppable-item--dragging"
                ),
                returnStringIfTrue(
                  isDragDisabled,
                  "droppable-item--drag-disabled"
                )
              ])}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{ userSelect: "none", ...provided.draggableProps?.style }}
              onClick={() => onClickItem?.(item)}
            >
              {renderItem(item, snapshot.isDragging)}
            </div>
          )}
        </Draggable>
      );
    },
    [onClickItem, allLanesAcceptable, renderItem]
  );

  return (
    <Stack
      className={[
        "swimlane-template",
        returnStringIfTrue(
          draggingOver && isValidZone,
          `swimlane-template--dragging-over`
        ),
        returnStringIfTrue(
          isDragging && isValidZone,
          "swimlane-template--dropzone"
        )
      ]}
    >
      <Inline className="swimlane-template__title" width="100" alignment="left">
        <Inline alignment="left" gap="050">
          <Box className="swimlane-template__title__text">{swimlane.title}</Box>
          {visibleCountText && (
            <Box className="swimlane-template__count" role="count">
              {visibleCountText}
            </Box>
          )}
        </Inline>
        {swimlaneAction}
      </Inline>
      <Box className="swimlane-template__title__shadow-cover" width="100" />
      <Droppable
        className="swimlane-template__contents"
        key={swimlane.id}
        droppableId={swimlane.id}
        style={{ height: "100%" }}
      >
        {(provided, snapshot) => {
          setDraggingOver(snapshot.isDraggingOver);
          return (
            <div
              className={classNames([
                "droppable-section",
                returnStringIfTrue(
                  snapshot.isDraggingOver,
                  "droppable-section--dragging-over"
                )
              ])}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {swimlane.items
                .filter((si: { hidden: boolean }) => !si.hidden)
                .map(renderDraggableItem)}

              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
      <Box className="swimlane-template__end" width="100" alignment="left" />
    </Stack>
  );
};
