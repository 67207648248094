import React, { useCallback, useEffect, useRef, useState } from "react";

import useOnClickOutside from "use-onclickoutside";

import deepCompare from "@shared/helpers/deepCompare";

import {
  DropdownItemId,
  DropdownItemType,
  DropdownList
} from "@molecules/DropdownList";

import "./DataTableDropdownSelect.scss";
import { DataTableDropdownSelectHeader } from "./DataTableDropdownSelectHeader";

const commentIconClass = "data-table-dropdown-select__comments-icon";
const getCommentsIconClass = state =>
  `${commentIconClass} ${commentIconClass}--${state} `;

const getItemStatus = item =>
  item?.state?.some(status => status) && (
    <i
      className={`material-symbols-outlined ${getCommentsIconClass(
        item?.state?.find(status => status)
      )}`}
    >
      circle
    </i>
  );

interface DataTableDropdownSelectProps {
  label?: string;
  optionalLabel?: string;
  items: DropdownItemType[];
  value?: { [key: string]: DropdownItemId };
  onChange?: (values: DropdownItemType[]) => void;
  selectedItemHeader?: boolean;
  width?: string;
  sortComparator?: (a: DropdownItemType, b: DropdownItemType) => number;
}

export const DataTableDropdownSelect = ({
  label,
  optionalLabel,
  items,
  value,
  onChange,
  selectedItemHeader,
  width,
  sortComparator
}: DataTableDropdownSelectProps) => {
  const findItemByValue = useCallback(
    value =>
      items.find((option: DropdownItemType) =>
        deepCompare(option?.value, value)
      ),
    [items]
  );

  const [selectedItem, setSelectedItem] = useState(findItemByValue(value));

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const handleOnSelectOption = (options: DropdownItemType[]) => {
    if (options[0]?.value === "All") {
      options[0] = {
        value: undefined,
        name: undefined
      };
    }
    setIsOpen(false);
    setSelectedItem(options[0]);
    onChange?.(options[0]?.value);
  };

  const onToggleMenu = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setSelectedItem(findItemByValue(value));
  }, [findItemByValue, value]);

  return (
    <>
      <div className="data-table-dropdown-select-optional-label">
        {optionalLabel}
      </div>
      <div
        className="data-table-dropdown-select"
        ref={dropdownRef}
        style={{ width: width ?? "" }}
      >
        <DataTableDropdownSelectHeader
          onClick={onToggleMenu}
          label={label}
          additionalInformation={getItemStatus(selectedItem)}
          showMenu={isOpen}
          showFilter={true}
          isFilterActive={!!selectedItem?.value && selectedItem?.value !== "-"}
          item={selectedItemHeader ? selectedItem : undefined}
        />
        <DropdownList
          items={items}
          selectedValues={selectedItem ? [selectedItem] : []}
          setSelectedValues={handleOnSelectOption}
          parentRef={dropdownRef}
          isOpen={isOpen}
          showSearch
          sortComparator={sortComparator}
        />
      </div>
    </>
  );
};
