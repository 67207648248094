import { useCallback, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";

import { systemConstants } from "@shared/constants";
import usePubSub from "@shared/hooks/usePubSub";
import {
  actionItemServiceUtil,
  useLazyGetActionItemsWithFilterQuery
} from "@shared/services";

import { Project } from "@app/types";

export function useProjectQueries(project: Project) {
  const dispatch = useDispatch();
  const [fetchQueriesWithFilter, { data: queries, isLoading, error }] =
    useLazyGetActionItemsWithFilterQuery();
  const didMount = useRef(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshQueriesState = usePubSub() as { [key: string]: any };

  useEffect(() => {
    if (!didMount.current) {
      refreshQueriesState.subscribe(
        systemConstants.project.events.projects.refreshProjectQueries
      );
      didMount.current = true;
    }
  }, [refreshQueriesState]);

  useEffect(() => {
    if (project?.id) {
      fetchQueriesWithFilter({ projectId: project.id }, true);
    }
  }, [fetchQueriesWithFilter, project.id]);

  useEffect(() => {
    if (!project?.id || refreshQueriesState.value?.projectId !== +project.id) {
      return;
    }
    dispatch(actionItemServiceUtil.invalidateTags(["Query"]));
    // Added timestamp dependency to prevent infinite loop
  }, [
    dispatch,
    project.id,
    refreshQueriesState.value?.projectId,
    refreshQueriesState.value?.timeStamp
  ]);

  const fetchQueries = useCallback(
    filters => {
      if (project.id) {
        fetchQueriesWithFilter({ projectId: project.id, filters }, true);
      }
    },
    [fetchQueriesWithFilter, project.id]
  );

  return { queries, isLoading, error, fetchQueries };
}
