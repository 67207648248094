import React, { useCallback, useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  smartFormActions,
  smartFormHelper,
  smartFormProgressStates
} from "@app/helpers/smartForm";
import { useDataTable } from "@app/hooks/useDataTable";

import { Icon, IconColor, IconSize } from "@atoms/Icon";
import { StatusBar } from "@atoms/StatusBar";

import CommentButton from "@components/atoms/CommentButton";
import DataTable from "@components/molecules/DataTable";

import { SmartFormPerEntityResponse } from "./SmartFormPerEntityResponse";
import { SmartFormQuestionText } from "./SmartFormQuestionText";
import { SmartFormResponse } from "./SmartFormResponse";
import "./SmartFormTable.scss";

/**
 * @param {Object} props
 * @param {Array} props.questionWithComments List of questions which comments
 * @param {Array} props.questions List of questions
 * @param {Object} props.handlers Handlers for various events
 * @param {Object} props.relevantEntitiesById List of all relevant entities for the SmartForm (for lookup)
 * @param {Boolean} props.disableAnswerSubmissions Whether answer submissions is disabled or not
 * @param {Boolean} props.alwaysShowEntitiesIcon Whether to always show the entities icon or fallback to default behaviour
 * @returns
 */
const SmartFormTable = ({
  questionWithComments,
  questions,
  handlers,
  relevantEntitiesById,
  disableAnswerSubmissions,
  alwaysShowEntitiesIcon
}) => {
  const { t } = useTranslation();
  const { createColumn } = useDataTable(questions);

  const relevantEntitiesTotal = useMemo(() => {
    return Object.values(relevantEntitiesById)?.length || 0;
  }, [relevantEntitiesById]);

  const tableRef = useRef();

  const questionsById = useMemo(() => {
    return questions.reduce((acc, q) => {
      acc[q.questionId] = q;
      return acc;
    }, {});
  }, [questions]);

  const getQuestionById = useCallback(
    questionId => {
      return questionsById[questionId];
    },
    [questionsById]
  );

  const renderStatusBar = useCallback(
    ({ cell }) => (
      <StatusBar type={cell.value ?? smartFormProgressStates.NOT_STARTED} />
    ),
    []
  );

  const renderCommentCell = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      const hasComments = questionWithComments?.some(
        qId => qId === question?.questionId
      );
      return (
        <div
          className={`data-table__data--icon-cell ${
            hasComments ? "" : "data-table__data--on-hover-cell"
          }`}
          role="cell-comment-icon"
        >
          <CommentButton
            id={question?.questionId}
            onClick={() => {
              handlers[smartFormActions.OPEN_COMMENT_MODAL](question);
            }}
            icon="contact_support"
            state={hasComments ? "active" : "outlined"}
            title={t(`requests:requests.ui.smartForm.addComment.label`)}
          />
        </div>
      );
    },
    [questionWithComments, handlers, getQuestionById, t]
  );

  const renderQuestion = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      return (
        <SmartFormQuestionText
          question={question}
          alwaysShowEntitiesIcon={alwaysShowEntitiesIcon}
          relevantEntitiesById={relevantEntitiesById}
          relevantEntitiesTotal={relevantEntitiesTotal}
        />
      );
    },
    [
      alwaysShowEntitiesIcon,
      getQuestionById,
      relevantEntitiesById,
      relevantEntitiesTotal
    ]
  );

  const renderResponseTypeIcon = useCallback(
    ({ cell }) => (
      <Icon
        className="data-table__data--on-hover-cell"
        name={smartFormHelper.getResponseTypeIcon(
          cell.row.original.responseType
        )}
        size={IconSize.S}
        color={IconColor.MUTED}
      />
    ),
    []
  );

  const renderResponseType = useCallback(
    ({ cell }) => {
      const question = getQuestionById(cell.row.original.questionId);
      return (
        <SmartFormResponse
          responseType={cell.value}
          question={question}
          globalAnswer={
            question?.isAnsweredGlobally
              ? question.answers?.[0]?.value ?? null
              : null
          }
          disableAnswerSubmissions={disableAnswerSubmissions}
          handlers={handlers}
          relevantEntitiesTotal={relevantEntitiesTotal}
        />
      );
    },
    [disableAnswerSubmissions, getQuestionById, handlers, relevantEntitiesTotal]
  );

  const renderPerEntityResponseType = useCallback(
    ({ cell }) => (
      <SmartFormPerEntityResponse
        responseType={cell.value}
        question={getQuestionById(cell.row.original.questionId)}
        disableAnswerSubmissions={disableAnswerSubmissions}
        handlers={handlers}
        relevantEntitiesById={relevantEntitiesById}
        relevantEntitiesTotal={relevantEntitiesTotal}
      />
    ),
    [
      disableAnswerSubmissions,
      getQuestionById,
      handlers,
      relevantEntitiesById,
      relevantEntitiesTotal
    ]
  );

  const columns = useMemo(() => {
    return [
      createColumn({
        Header: "",
        accessor: "progressIndicator",
        width: 10,
        fixedWidth: true,
        Cell: renderStatusBar
      }),
      createColumn({
        Header: "",
        accessor: "text",
        width: 300,
        handleSortChange: "",
        Cell: renderQuestion
      }),
      createColumn({
        Header: "",
        id: "responseTypeIcon",
        accessor: "responseType",
        className: "responseTypeIcon",
        width: 30,
        handleSortChange: "",
        Cell: renderResponseTypeIcon
      }),
      createColumn({
        Header: "",
        accessor: "responseType",
        width: 100,
        Cell: renderResponseType
      }),
      createColumn({
        Header: "",
        accessor: "responseType",
        id: "response",
        width: 100,
        className: "responseTypeOther",
        Cell: renderPerEntityResponseType
      }),
      createColumn({
        Header: "",
        accessor: "comment",
        width: 50,
        fixedWidth: true,
        Cell: renderCommentCell
      })
    ];
  }, [
    createColumn,
    renderStatusBar,
    renderQuestion,
    renderResponseTypeIcon,
    renderResponseType,
    renderPerEntityResponseType,
    renderCommentCell
  ]);

  return (
    <DataTable
      ref={tableRef}
      className="smart-form-table"
      columns={columns}
      data={questions}
      hideHeaders
    />
  );
};

export const processedQuestionPropType = PropTypes.shape({
  questionId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  responseType: PropTypes.oneOf([
    "boolean",
    "text",
    "singleChoice",
    "date",
    "number",
    "document",
    "websheet"
  ]).isRequired,
  additionalInfo: PropTypes.string,
  maxLength: PropTypes.number,
  lineBreaks: PropTypes.bool,
  options: PropTypes.array,
  progressIndicator: PropTypes.string.isRequired,
  isAnsweredGlobally: PropTypes.bool,
  answeredEntities: PropTypes.arrayOf(PropTypes.number),
  assignedEntities: PropTypes.arrayOf(PropTypes.number),
  answers: PropTypes.arrayOf(PropTypes.object)
});

SmartFormTable.defaultProps = {};

SmartFormTable.propTypes = {
  questions: PropTypes.arrayOf(processedQuestionPropType),
  handlers: PropTypes.shape({
    openCommentModal: PropTypes.func,
    openEntitiesModal: PropTypes.func,
    saveAnswers: PropTypes.func,
    supplyAnswer: PropTypes.func
  }),
  relevantEntitiesById: PropTypes.any,
  disableAnswerSubmissions: PropTypes.bool,
  highlightedQuestionId: PropTypes.number,
  alwaysShowEntitiesIcon: PropTypes.bool
};

export default SmartFormTable;
