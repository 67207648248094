import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import Avatar from "@shared/components/avatar/Avatar";
import Popup from "@shared/components/popup/Popup";
import { systemConstants } from "@shared/constants/systemConstants";
import dateFormatter from "@shared/helpers/dateHelper";
import {
  useAddNoteToRiskMutation,
  useGetProjectMembers,
  useLocaleDate
} from "@shared/hooks";

import AddRiskNote from "@components/molecules/AddRiskNote";

import AddOrUpdateRisk from "../addOrUpdateRisk/AddOrUpdateRisk";
import RiskImpactLikelihood from "../riskImpactLikelihood/RiskImpactLikelihood";
import "./riskCard.scss";

const statusColorMap = {
  [systemConstants.project.risk.status.raised]:
    "risk-card__container--main-body-description-status-red",
  [systemConstants.project.risk.status.inProgress]:
    "risk-card__container--main-body-description-status-blue"
};

const RiskCard = props => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(
    windowWidth < systemConstants.mediaBreakpoints.tabLand
  );
  const [risk, setRisk] = useState(props.data);
  const { members } = useGetProjectMembers(props.project);
  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const [addNoteToRisk] = useAddNoteToRiskMutation();
  const [notes, setNotes] = useState(props.data.riskNotes || []);
  const [expand, setExpand] = useState(false);
  const [addNoteVisibility, setAddNoteVisibility] = useState(false);
  const [updateRiskVisibility, setUpdateRiskVisibility] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setMobile(window.innerWidth < systemConstants.mediaBreakpoints.tabLand);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setRisk(props.data);
  }, [props.data]);

  useEffect(() => {
    setNotes(props.data.riskNotes);
  }, [props.data.riskNotes]);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleCancelClick = () => {
    setAddNoteVisibility(false);
    setUpdateRiskVisibility(false);
  };

  const handleUpdateClick = () => {
    setUpdateRiskVisibility(false);
  };

  const handleOpenAddNote = () => {
    setAddNoteVisibility(true);
  };
  const handleAddNote = ({ note }) => {
    addNoteToRisk({ note, projectId: props.project?.id });
    setAddNoteVisibility(false);
  };
  const handleUpdateRisk = () => {
    setUpdateRiskVisibility(true);
  };

  const getStatusColor = () => {
    return (
      statusColorMap[risk.status] ||
      "risk-card__container--main-body-description-status-green"
    );
  };

  const getNoteTemplate = note => {
    const formattedNoteCreationDate = dateFormatter(
      note.createdAt,
      locale,
      shortFormat
    );
    return (
      <div key={note.id} className="risk-card__notes-body-note">
        <span className="risk-card__notes-body-note-creator">
          {t("risks:ui.riskCard.noteBy", {
            name: note.createdBy.name,
            date: formattedNoteCreationDate
          })}
        </span>
        <span className="risk-card__notes-body-note-message">
          {note.message.split("\n").map(string => {
            return <p key={uuidv4()}>{string || <br />}</p>;
          })}
        </span>
      </div>
    );
  };

  const getPopups = () => {
    return (
      <>
        <Popup
          visibility={addNoteVisibility}
          handleOutsideClick={false}
          width="60rem"
        >
          <AddRiskNote
            riskId={risk.id}
            onCancel={handleCancelClick}
            onAdd={handleAddNote}
          />
        </Popup>

        <Popup
          visibility={updateRiskVisibility}
          handleOutsideClick={false}
          width="65rem"
        >
          <AddOrUpdateRisk
            risk={risk}
            action={"update"}
            project={props.project}
            members={members}
            onUpdate={handleUpdateClick}
            onCancel={handleCancelClick}
          />
        </Popup>
      </>
    );
  };

  const renderDescriptionTail = () => {
    return (
      <div className="risk-card__container--main-body-description-tail">
        <span
          onClick={handleOpenAddNote}
          className="risk-card__container--main-body-description-tail-add-note"
        >
          {t("risks:ui.riskCard.actions.addNote.label")}
        </span>
        <span
          onClick={handleUpdateRisk}
          className="risk-card__container--main-body-description-tail-update"
        >
          {t("risks:ui.riskCard.actions.update.label")}
        </span>
        {notes.length || risk.riskResolution ? (
          <span
            onClick={handleExpand}
            className="risk-card__container--main-body-description-tail-expand"
          >
            {expand
              ? t("risks:ui.actions.hide.label")
              : t("risks:ui.actions.showMore.label")}
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderRiskNoteExpanded = () => {
    if (expand && notes.length) {
      return (
        <div className="risk-card__notes">
          <div className="risk-card__notes-gutter">
            <h3 className="risk-card__notes-gutter-label">
              {t("risks:risks.fields.notes.label")}
            </h3>
          </div>
          <div className="risk-card__notes-body">
            {notes.map(note => getNoteTemplate(note))}
          </div>
        </div>
      );
    }
  };

  const renderResolutionExpanded = () => {
    if (expand && risk.riskResolution) {
      return (
        <div className="risk-card__resolution">
          <div className="risk-card__resolution-gutter">
            <h3 className="risk-card__resolution-gutter-label">
              {t("risks:risks.fields.resolution.label")}
            </h3>
          </div>
          <div className="risk-card__resolution-body">
            <div className="risk-card__resolution-body-resolution-container">
              <span className="risk-card__resolution-body-resolution">
                {t(
                  `risks:risks.fields.resolution.status.${risk.riskResolution.resolution}.label`
                )}
              </span>
              <span className="risk-card__resolution-body-creator">
                {t("risks:ui.riskCard.fixedBy", {
                  name: risk.riskResolution.createdBy.name,
                  date: formattedRiskResolutionDate
                })}
              </span>
            </div>

            <span className="risk-card__resolution-body-reason">
              {risk.riskResolution.resolutionReason.split("\n").map(string => {
                return (
                  <p
                    className="risk-card__resolution-body-reason"
                    key={uuidv4()}
                  >
                    {string || <br />}
                  </p>
                );
              })}
            </span>
          </div>
        </div>
      );
    }
  };
  const formattedRiskRaisedDate = dateFormatter(
    risk.createdAt,
    locale,
    shortFormat
  );
  const formattedRiskResolutionDate = dateFormatter(
    risk.riskResolution?.createdAt,
    locale,
    shortFormat
  );

  const getDesktopView = () => {
    return (
      <>
        <div className="risk-card">
          <div className="risk-card__container">
            <div className="risk-card__container--main">
              <div className="risk-card__container--main-body">
                <div className="risk-card__container--main-body-control">
                  <div className="risk-card__container--main-body-control-impact-probability">
                    <RiskImpactLikelihood risk={risk} />
                  </div>
                </div>

                <div className="risk-card__container--main-body-description">
                  <div className="risk-card__container--main-body-description-owner">
                    <div>
                      <h3 className="risk-card__container--main-body-description-title">
                        {risk.title}
                      </h3>
                      <div className="risk-card__container--main-body-description-metadata">
                        <span className="risk-card__container--main-body-description-status">
                          {t("risks:risks.fields.status.label")}
                        </span>
                        <span className={getStatusColor()}>
                          {t(`risks:risks.fields.status.${risk.status}.label`)}
                        </span>
                        <span className="risk-card__container--main-body-description-divider">
                          |
                        </span>
                        <span className="risk-card__container--main-body-description-raised-by">
                          {t("risks:ui.riskCard.raisedBy", {
                            name: risk.raisedBy.name,
                            date: formattedRiskRaisedDate
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="risk-card__container--main-body-owner">
                      <div className="risk-card__container--main-body-owner-assignee-avatar">
                        <Avatar
                          user={risk.owner}
                          width={"4.3rem"}
                          height={"4.3rem"}
                        />
                      </div>
                      <div className="risk-card__container--main-body-owner-assignee-name">
                        <div className="risk-card__container--main-body-owner-assignee-name-risk-label">
                          {t("risks:risks.fields.owner.label")}
                        </div>
                        <div className="risk-card__container--main-body-owner-assignee-name-risk-owner">
                          {risk.owner.name}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="risk-card__container--main-body-description-details">
                    <div className="risk-card__container--main-body-description-details-content">
                      {risk.description.split("\n").map(string => {
                        return <p key={uuidv4()}>{string || <br />}</p>;
                      })}
                    </div>
                  </div>

                  {renderDescriptionTail()}
                </div>
              </div>
            </div>
            {renderRiskNoteExpanded()}
            {renderResolutionExpanded()}
          </div>
        </div>
        {getPopups()}
      </>
    );
  };

  const getMobileView = () => {
    return (
      <>
        <div className="risk-card">
          <div className="risk-card__container">
            <div className="risk-card__container--main">
              <div className="risk-card__container--main-body">
                <div className="risk-card__container--main-body-description">
                  <div className="risk-card__container--main-body-description-owner">
                    <div>
                      <h3 className="risk-card__container--main-body-description-title">
                        {risk.title}
                      </h3>
                      <div className="risk-card__container--main-body-description-metadata">
                        <div>
                          <span className="risk-card__container--main-body-description-status">
                            {t("risks:risks.ui.riskCard.label")}
                          </span>
                          <span className={getStatusColor()}>
                            {risk.status}
                          </span>
                        </div>
                        <div>
                          <span className="risk-card__container--main-body-description-raised-by">
                            {t("risks:ui.riskCard.raisedBy", {
                              name: risk.raisedBy.name,
                              date: formattedRiskRaisedDate
                            })}
                          </span>
                        </div>

                        <div>
                          <span className="risk-card__container--main-body-description-raised-by">
                            {`${t("risks:risks.fields.owner.label")} ${
                              risk.owner.name
                            }`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="risk-card__container--main-body-control">
                    <div className="risk-card__container--main-body-control-impact-probability">
                      <RiskImpactLikelihood risk={risk} />
                    </div>
                  </div>

                  <div className="risk-card__container--main-body-description-details">
                    <div className="risk-card__container--main-body-description-details-label">
                      {t("risks:risks.fields.description.label")}
                    </div>
                    <div className="risk-card__container--main-body-description-details-content">
                      {risk.description.split("\n").map(string => {
                        return <p key={uuidv4()}>{string || <br />}</p>;
                      })}
                    </div>
                  </div>

                  {renderDescriptionTail()}
                </div>
              </div>
            </div>
            {renderRiskNoteExpanded()}
            {renderResolutionExpanded()}
          </div>
        </div>
        {getPopups()}
      </>
    );
  };

  return mobile ? getMobileView() : getDesktopView();
};

RiskCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number
  }),
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    riskNotes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        message: PropTypes.string,
        createdBy: PropTypes.shape({
          name: PropTypes.string.isRequired
        }),
        createdAt: PropTypes.string
      })
    ).isRequired,
    status: PropTypes.string,
    impact: PropTypes.string.isRequired,
    likelihood: PropTypes.string.isRequired,
    id: PropTypes.number,
    tile: PropTypes.string,
    createdAt: PropTypes.string,
    description: PropTypes.string.isRequired,
    projectId: PropTypes.number,
    owner: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      name: PropTypes.string.isRequired
    }).isRequired,
    raisedBy: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      name: PropTypes.string.isRequired
    }).isRequired,
    project: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }).isRequired
};

export default RiskCard;
