import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import {
  useCurrentProject,
  useLazyGetMenusQuery,
  useLocaleDate,
  useWindowSize
} from "@shared/hooks";

import {
  QUERY_TYPE_ICON,
  formatQueryType,
  getActionIndicator,
  getActionsForQuery,
  isNote
} from "@app/helpers";
import { workflowStepName } from "@app/helpers/actionItems.js";
import { populateRelevantEntitiesByIdToObject } from "@app/helpers/entity";
import { getColumnWidth } from "@app/helpers/table";
import { useDataTable } from "@app/hooks";

import { ContainerWithTooltip } from "@atoms/ContainerWithTooltip";
import { Pill, PillFillStyle, PillFontWeight, PillSize } from "@atoms/Pill";
import { StatusBar } from "@atoms/StatusBar";
import { FontSize, FontWeight, Text } from "@atoms/Text";

import { DataTableDropdownSelect } from "@molecules/DataTableDropdownSelect";

import { Icon, IconFillStyle } from "@components/atoms/Icon";
import ShapeIndicator from "@components/atoms/ShapeIndicator";
import DataTable from "@components/molecules/DataTable";
import { sortWithAll } from "@components/molecules/DropdownList/DropdownHelper";
import EntitiesListIconCell from "@components/molecules/EntitiesListIconCell";

import "./ActionItemsTable.scss";

const ActionItemsTable = props => {
  const {
    columnConfig,
    queries,
    queryTypes,
    actionItemTypes,
    filterLabel,
    filterQueryType,
    filterUsers,
    filterCreatedBy,
    filter,
    sortedColumn,
    onClickQuery,
    user,
    onAssignToFilterValueChanged,
    onLabelFiltersChanged,
    onWorkflowFiltersChanged,
    onQueryTypeFilterChanged,
    onCreatedByFilterChanged,
    onSortedColumnChanged,
    enableIndicatorIcon,
    enableIndicatorPill,
    indicatorUiConfig,
    project,
    hideRequiredByField,
    requestViewFiltersEnabled,
    actionHandlers
  } = props;

  const {
    findItem,
    createColumn,
    createColumnForFilter,
    createColumnForMultiselectFilter,
    caseInsensitiveSortType,
    caseInsensitiveEmptyLastSortType,
    createColumnForDropdownMenu
  } = useDataTable(queries);

  const {
    locale,
    options: { shortFormat }
  } = useLocaleDate();
  const tableRef = useRef();
  const filterUsersRef = useRef();
  const [getInteractiveReportMenus, interactiveReportMenusData] =
    useLazyGetMenusQuery();
  const { width } = useWindowSize();
  const isSmallScreen = width < 1500;
  const [disableFlagCol, setDisableFlagCol] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (project) {
      getInteractiveReportMenus({ projectId: project.id });
    }
  }, [project, getInteractiveReportMenus]);

  useEffect(() => {
    if (sortedColumn) {
      tableRef.current?.setSortBy(sortedColumn);
    }
  }, [sortedColumn, queries]);

  useEffect(() => {
    const hasFlag = actionItemTypes?.findIndex(
      a => a?.configuration?.flags?.length
    );
    setDisableFlagCol(hasFlag < 0);
  }, [actionItemTypes]);

  // Special filter to handle lists of [Label]
  const LabelColumnFilterApply = useCallback((rows, id, tagIdToFilter) => {
    return rows.filter(row => {
      const tagsInRow = row.values[id];
      return tagsInRow.map(t => t.id).includes(+tagIdToFilter);
    });
  }, []);

  const userFilterChangeHandler = useCallback(
    users => {
      if (tableRef.current) {
        const newVal = users.map(u => u.value);
        tableRef.current.setFilter("assignedTo", newVal);
        onAssignToFilterValueChanged(users);
      }
    },
    [onAssignToFilterValueChanged]
  );

  // This is to prevent the table header rerender after selecting created by filter
  const createdByFilterRef = useRef();
  useEffect(() => {
    return () => {
      onCreatedByFilterChanged(createdByFilterRef.current);
    };
  }, [onCreatedByFilterChanged]);
  const createdByFilterChangeHandler = useCallback(creators => {
    if (tableRef.current) {
      const newVal = creators.map(u => u.value);
      tableRef.current.setFilter("createdBy", newVal);
      createdByFilterRef.current = creators;
    }
  }, []);

  const labelFilterChangeHandler = useCallback(
    e => {
      if (tableRef.current) {
        tableRef.current.setFilter("tags", e);
        onLabelFiltersChanged(e);
      }
    },
    [onLabelFiltersChanged]
  );

  const tagForLabelDropdownFilter = (preFilteredRows, id) => {
    const tagIdMap = {};
    preFilteredRows.forEach(row => {
      const tags = row.values[id];
      tags.forEach(tag => {
        tagIdMap[tag.id] = tag;
      });
    });
    return Object.values(tagIdMap);
  };

  const queryTypeFilterChangeHandler = useCallback(
    e => {
      if (tableRef.current) {
        tableRef.current.setFilter("queryType", e);
        onQueryTypeFilterChanged(e);
      }
    },
    [onQueryTypeFilterChanged]
  );

  const workflowFilterChangeHandler = useCallback(
    steps => {
      if (tableRef.current) {
        const newVal = steps.map(s => s.value);
        tableRef.current.setFilter("workflowStep", newVal);
        onWorkflowFiltersChanged(steps);
      }
    },
    [onWorkflowFiltersChanged]
  );

  const SelectLabelColumnFilter = useCallback(
    onChangeHandler =>
      ({ column: { filterValue, preFilteredRows, id } }) => {
        const options = tagForLabelDropdownFilter(preFilteredRows, id);
        return (
          <DataTableDropdownSelect
            label={`${t("requests:requests.configured.fields.tags.label")}`}
            items={[
              { label: t("common:allOption"), name: "All", value: undefined },
              ...options.map(o => ({ name: o.name, value: o.id }))
            ]}
            onChange={onChangeHandler}
            value={filterValue}
            sortComparator={sortWithAll}
          />
        );
      },
    [t]
  );

  const handleSortChange = useCallback(
    val => {
      onSortedColumnChanged(val);
    },
    [onSortedColumnChanged]
  );

  const getQueryTypeColumn = useCallback(() => {
    if (!isSmallScreen) {
      return {
        accessor: "queryType",
        className: "query-type",
        filterLabel: `${t("requests:requests.typeLabel")}`,
        allOptionLabel: t("common:allOption"),
        width: 140,
        onChangeHandler: queryTypeFilterChangeHandler,
        Cell: ({ cell }) => (
          <>
            <span className="data-table__data-query-type__text">
              {cell.value}
            </span>
            {cell.row.original.queryTypeIcon && (
              <Icon
                className="data-table__data-query-type__icon"
                name={cell.row.original.queryTypeIcon}
                hoverElement={<>{cell.value}</>}
              />
            )}
          </>
        )
      };
    } else {
      return {
        Header: t("requests:requests.typeLabel"),
        accessor: "queryType",
        className: "query-type",
        width: 60,
        disableFilters: true,
        Cell: ({ cell }) =>
          cell.row.original.queryTypeIcon && (
            <Icon
              className="data-table__data-query-type__icon"
              name={cell.row.original.queryTypeIcon}
              hoverElement={<>{cell.value}</>}
            />
          )
      };
    }
  }, [isSmallScreen, queryTypeFilterChangeHandler, t]);

  const actionsMenuForItem = useCallback(
    item => getActionsForQuery({ item, actionItemTypes, user, t }),
    [actionItemTypes, t, user]
  );

  const { currentProject } = useCurrentProject();
  const milestone = useCallback(
    query => {
      if (!currentProject?.configuration?.milestones?.labels?.enabled) {
        return "";
      }
      const projectMilestones = currentProject.milestones;
      return (
        t(projectMilestones?.find(m => m.id === query?.milestone)?.name) ?? ""
      );
    },
    [currentProject, t]
  );

  const data = useMemo(() => {
    const formatIndicator = item => {
      const referenceDate = new Date();
      return getActionIndicator(item, referenceDate, indicatorUiConfig);
    };
    const renderFlag = flag => {
      switch (flag) {
        case "REJECTED":
          return (
            <Icon
              className="flag flag-REJECTED"
              name="cancel"
              fillStyle={IconFillStyle.FILLED}
              hoverElement={t("requests:requests.configured.flags_REJECTED")}
            />
          );
        case "APPROVED":
          return (
            <Icon
              className="flag flag-APPROVED"
              name="check_circle"
              fillStyle={IconFillStyle.FILLED}
              hoverElement={t("requests:requests.configured.flags_APPROVED")}
            />
          );
      }
    };

    const result = queries.map(item => {
      const label = Object.keys(indicatorUiConfig).find(
        keyName => indicatorUiConfig[keyName].key === item.status.toLowerCase()
      );
      const formattedIndicator = enableIndicatorPill
        ? { ...indicatorUiConfig[label], status: label }
        : formatIndicator(item);
      return {
        ...item,
        indicator: formattedIndicator,
        id: item.id,
        createdAt: item.createdAt,
        requiredBy: item.requiredBy,
        description: item.description,
        entities: populateRelevantEntitiesByIdToObject(project, item),
        tags: utilities.sortBy("name")(structuredClone(item.tags)),
        assignedTo: item.assignedTo?.name ?? "",
        copiedTo:
          utilities
            .sortBy("name")(structuredClone(item.copiedTo))
            .map(c => c.name)
            .join(",") ?? "",
        createdBy: !item.lastActor
          ? `${item.requestedBy?.firstname} ${item.requestedBy?.lastname}`
          : `${item.lastActor?.name ?? ""}`,
        queryTypeIcon:
          QUERY_TYPE_ICON[
            queryTypes?.find(type => type.key === item.queryType)?.type
          ],
        queryType: formatQueryType(item.queryType, queryTypes, t),
        flag: item.flag ? renderFlag(item.flag) : "",
        actionsMenu: actionsMenuForItem(item),
        refNo: item.refNo,
        workflowStep: workflowStepName({ workflowStep: item.workflowStep, t }),
        source: item.source,
        status: item.status,
        milestone: milestone(item)
      };
    });
    let filteredResult = result;
    if (filter.dueDate) {
      filteredResult = result.filter(item => {
        return item.indicator.key == filter.dueDate && !isNote(item.status);
      });
    }
    if (filter.status) {
      filteredResult = filteredResult.filter(
        item => item.indicator.status == filter.status
      );
    }
    return filteredResult;
  }, [
    queries,
    filter.dueDate,
    filter.status,
    indicatorUiConfig,
    t,
    enableIndicatorPill,
    project,
    queryTypes,
    actionsMenuForItem,
    milestone
  ]);

  useEffect(() => {
    tableRef.current?.setFilter(
      "assignedTo",
      filterUsers?.map(filterUser => filterUser.name)
    );
    filterUsersRef.current = filterUsers;
  }, [filterUsers, data]);

  useEffect(() => {
    tableRef.current?.setFilter(
      "createdBy",
      filterCreatedBy?.map(creator => creator.name)
    );
  }, [filterCreatedBy, data]);

  useEffect(() => {
    tableRef.current?.setFilter("tags", filterLabel);
  }, [filterLabel, queries, data]);

  useEffect(() => {
    tableRef.current?.setFilter("queryType", filterQueryType);
  }, [filterQueryType, queries, data]);

  const TagsColumnCell = ({ cell }) => {
    const tags = cell.value;
    return tags.map(t => t.name).join(", ");
  };
  TagsColumnCell.propTypes = {
    cell: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.string)
    })
  };

  const getTagsColumn = useCallback(() => {
    if (requestViewFiltersEnabled) {
      return createColumn({
        Header: t("requests:requests.configured.fields.tags.label"),
        accessor: "tags",
        minWidth: 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        isHidden: isSmallScreen,
        handleSortChange,
        Cell: TagsColumnCell
      });
    }

    return {
      Header: "",
      accessor: "tags",
      className: "tags",
      width: 130,
      disableFilters: false,
      isHidden: isSmallScreen,
      Filter: SelectLabelColumnFilter(labelFilterChangeHandler),
      filter: LabelColumnFilterApply,
      Cell: TagsColumnCell
    };
  }, [
    requestViewFiltersEnabled,
    createColumn,
    t,
    hideRequiredByField,
    caseInsensitiveSortType,
    handleSortChange,
    isSmallScreen,
    SelectLabelColumnFilter,
    labelFilterChangeHandler,
    LabelColumnFilterApply
  ]);

  const getWorkflowColumn = useCallback(() => {
    if (requestViewFiltersEnabled) {
      return createColumn({
        Header: t("requests:requests.workflow.label"),
        accessor: "workflowStep",
        minWidth: 115,
        disableSortBy: false,
        isHidden: true,
        sortType: caseInsensitiveSortType,
        handleSortChange
      });
    }
    return createColumnForMultiselectFilter({
      accessor: "workflowStep",
      className: "workflow-step",
      filterLabel: `${t(
        "requests:requests.configured.fields.workflowStep.pastTenseLabel"
      )}`,
      onChangeHandler: workflowFilterChangeHandler,
      isHidden: true,
      width: 140
    });
  }, [
    requestViewFiltersEnabled,
    createColumn,
    t,
    createColumnForMultiselectFilter,
    workflowFilterChangeHandler,
    hideRequiredByField,
    caseInsensitiveSortType,
    handleSortChange
  ]);

  const getAssignedToColumn = useCallback(() => {
    if (requestViewFiltersEnabled) {
      return createColumn({
        Header: t(
          "requests:requests.configured.fields.assignedTo.pastTenseLabel"
        ),
        accessor: "assignedTo",
        minWidth: 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        handleSortChange
      });
    }

    return createColumnForMultiselectFilter({
      accessor: "assignedTo",
      className: "assigned-to",
      filterLabel: `${t(
        "requests:requests.configured.fields.assignedTo.pastTenseLabel"
      )}`,
      allOptionLabel: t("common:allOption"),
      filterExtras: filterUsersRef.current
        ? filterUsersRef.current.map(filterUser => filterUser.name)
        : [],
      onChangeHandler: userFilterChangeHandler,
      width: 140
    });
  }, [
    requestViewFiltersEnabled,
    createColumn,
    t,
    hideRequiredByField,
    caseInsensitiveSortType,
    handleSortChange,
    createColumnForMultiselectFilter,
    userFilterChangeHandler
  ]);

  const getTypeColumn = useCallback(() => {
    if (requestViewFiltersEnabled) {
      return createColumn({
        Header: t("requests:requests.typeLabel"),
        accessor: "queryType",
        minWidth: 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        handleSortChange
      });
    }

    return createColumnForFilter(getQueryTypeColumn());
  }, [
    requestViewFiltersEnabled,
    createColumn,
    t,
    hideRequiredByField,
    caseInsensitiveSortType,
    handleSortChange,
    createColumnForFilter,
    getQueryTypeColumn
  ]);

  const getCreatedByColumn = useCallback(() => {
    if (requestViewFiltersEnabled) {
      return createColumn({
        Header: t("requests:requests.configured.fields.createdBy.label"),
        accessor: "createdBy",
        minWidth: 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        handleSortChange
      });
    }

    return createColumnForMultiselectFilter({
      accessor: "createdBy",
      className: "created-by",
      filterLabel: `${t(
        "requests:requests.configured.fields.createdBy.label"
      )}`,
      allOptionLabel: t("common:allOption"),
      filterExtras: filterCreatedBy
        ? filterCreatedBy.map(creator => creator.name)
        : [],
      onChangeHandler: createdByFilterChangeHandler,
      width: 140
    });
  }, [
    requestViewFiltersEnabled,
    createColumn,
    t,
    hideRequiredByField,
    caseInsensitiveSortType,
    handleSortChange,
    createColumnForMultiselectFilter,
    filterCreatedBy,
    createdByFilterChangeHandler
  ]);
  const dropdownMenuActionHandler = useCallback(
    ({
      menuItem: { action },
      cell: {
        row: {
          original: { id: actionItemId }
        }
      }
    }) => {
      const item = findItem(actionItemId);
      actionHandlers[action]?.(item);
    },
    [actionHandlers, findItem]
  );

  const columns = useMemo(() => {
    const formatDate = date => dateFormatter(date, locale, shortFormat) ?? "";

    const renderSourceData = data => {
      if (!data) {
        return <></>;
      }
      return (
        <ContainerWithTooltip
          tooltipContent={`${data?.pageName} | ${data?.path?.join(" | ")}`}
          className="action-items-table__source-row"
        >
          <Text
            text={data?.path?.[data?.path?.length - 1]}
            size={FontSize.S}
            weight={FontWeight.BOLD}
          />
          <div>{`Report v${data.revision} - ${dateFormatter(
            data.uploadedDate,
            locale,
            shortFormat
          )}`}</div>
        </ContainerWithTooltip>
      );
    };

    let indicatorClassName = "indicator--bar";
    let indicatorWidth = 20;
    if (enableIndicatorPill) {
      indicatorClassName = "indicator--pill";
      indicatorWidth = getColumnWidth({
        rows: data,
        accessor: "indicator",
        headerText: "",
        spacing: 7
      });
    } else if (enableIndicatorIcon) {
      indicatorClassName = "indicator--icon";
      indicatorWidth = 35;
    }

    // Keep the old config as it is to become the default
    const allColumns = [
      {
        Header: "",
        accessor: "indicator",
        className: indicatorClassName,
        disableFilters: true,
        width: indicatorWidth,
        fixedWidth: true,
        Cell: ({ cell }) => {
          let indicator = null;
          const { shape, key } = cell.value;
          if (enableIndicatorIcon) {
            indicator = <ShapeIndicator shape={shape} color={key} />;
          } else {
            indicator = enableIndicatorPill ? (
              <Pill
                label={t(
                  `requests:requests.configured.status.${key.toUpperCase()}.label`
                )}
                status={key}
                fillStyle={PillFillStyle.FILLED}
                fontWeight={PillFontWeight.SEMI_BOLD}
                size={PillSize.SMALL_FILL}
              />
            ) : (
              <StatusBar type={key} />
            );
          }
          return <div className="indicator-container">{indicator}</div>;
        }
      },
      getWorkflowColumn(),
      createColumn({
        Header: t("requests:requests.refLabel"),
        accessor: "refNo",
        className: "ref",
        maxWidth: 50,
        fixedWidth: true,
        disableSortBy: false,
        handleSortChange
      }),
      createColumn({
        Header: t("requests:requests.dateAddedLabel"),
        accessor: "createdAt",
        className: "date-added",
        width: 80,
        disableSortBy: false,
        Cell: ({ cell }) => formatDate(cell.value),
        handleSortChange
      }),
      hideRequiredByField
        ? null
        : createColumn({
            Header: t("requests:requests.dueDateLabel"),
            accessor: "requiredBy",
            className: "date-due",
            width: 90,
            disableSortBy: false,
            Cell: ({ cell }) => formatDate(cell.value),
            sortType: caseInsensitiveEmptyLastSortType,
            handleSortChange
          }),
      createColumn({
        Header: t("requests:requests.sourceLabel"),
        accessor: "source",
        className:
          interactiveReportMenusData?.data?.length > 0 ? "source" : "hidden",
        width: 140,
        disableSortBy: true,
        Cell: ({ cell }) => renderSourceData(cell.value)
      }),
      createColumn({
        Header: t("requests:requests.descriptionLabel"),
        accessor: "description",
        minWidth: 140,
        disableSortBy: false,
        sortType: caseInsensitiveSortType,
        handleSortChange
      }),
      getTagsColumn(),
      getAssignedToColumn(),
      getCreatedByColumn(),
      getTypeColumn(),
      createColumn({
        accessor: "flag",
        maxWidth: 40,
        fixedWidth: true,
        isHidden: disableFlagCol,
        disableSortBy: true
      }),
      createColumn({
        accessor: "entities",
        width: 40,
        fixedWidth: true,
        Cell: ({ cell }) => {
          const relevantEntities = Object.values(cell.value || {});
          return (
            project?.entities?.length > 1 && (
              <EntitiesListIconCell entities={relevantEntities} />
            )
          );
        }
      }),
      createColumnForDropdownMenu({
        accessor: "actionsMenu",
        onClickHandler: dropdownMenuActionHandler,
        className: "visible-on-hover",
        fixedWidth: true,
        width: 45,
        isHidden: false
      })
    ].filter(c => {
      return c;
    });

    if (_.isEmpty(columnConfig)) {
      return allColumns;
    }
    // Add the columns that are not in the all config
    columnConfig.forEach(column => {
      if (hideRequiredByField && column.accessor === "requiredBy") {
        return;
      }
      if (!allColumns.find(c => c.accessor === column.accessor)) {
        allColumns.push({
          ...column,
          Header: t(column.header),
          ...(!column.disableSortBy && {
            onSortedChange: handleSortChange
          })
        });
      }
    });
    // Filter the columns based on the config and leave the already defined fe columns unchanged
    const filteredColumns = allColumns.map(column => {
      const relevantColumnConfig = columnConfig.find(
        c => c.accessor === column.accessor
      );
      const isHidden = (() => {
        if (!relevantColumnConfig) {
          return true;
        }
        return relevantColumnConfig.isHidden ?? column.isHidden;
      })();
      return {
        ...column,
        ...relevantColumnConfig,
        ...(relevantColumnConfig?.header && {
          Header: t(relevantColumnConfig?.header)
        }),
        isHidden
      };
    });

    // Sort the columns based on the be config
    const sortedCollection = _.sortBy(filteredColumns, function (column) {
      const columnIndex = columnConfig.findIndex(
        c => c.accessor === column.accessor
      );
      return columnIndex;
    });

    return sortedCollection;
  }, [
    enableIndicatorPill,
    enableIndicatorIcon,
    createColumn,
    t,
    handleSortChange,
    hideRequiredByField,
    caseInsensitiveEmptyLastSortType,
    interactiveReportMenusData?.data?.length,
    caseInsensitiveSortType,
    disableFlagCol,
    createColumnForDropdownMenu,
    getTagsColumn,
    getAssignedToColumn,
    getCreatedByColumn,
    getWorkflowColumn,
    getTypeColumn,
    dropdownMenuActionHandler,
    columnConfig,
    locale,
    shortFormat,
    data,
    project?.entities?.length
  ]);

  const handleRowClick = useCallback(
    queryId => {
      const query = findItem(queryId);
      if (!query) {
        return;
      }

      if (onClickQuery) {
        return onClickQuery(query);
      }
    },
    [findItem, onClickQuery]
  );
  return actionItemTypes ? (
    <>
      <DataTable
        ref={tableRef}
        className="action-items-table"
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
        fitContentWidth
      ></DataTable>
    </>
  ) : (
    <></>
  );
};

ActionItemsTable.defaultProps = {
  filter: {
    status: null
  }
};

ActionItemsTable.propTypes = {
  queries: PropTypes.array,
  onQueryClick: PropTypes.func,
  filter: PropTypes.shape({
    status: PropTypes.string
  }),
  filterUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onWorkflowFiltersChanged: PropTypes.func,
  onAssignToFilterValueChanged: PropTypes.func,
  requestViewFiltersEnabled: PropTypes.bool,
  hideRequiredByField: PropTypes.bool,
  actionHandlers: PropTypes.any,
  columnConfig: PropTypes.any
};

export default ActionItemsTable;
