import React, { useMemo, useState } from "react";

import {
  DragDropContext,
  OnDragEndResponder,
  OnDragStartResponder
} from "react-beautiful-dnd";

import { SwimlaneItem, Swimlane as SwimlaneType } from "@app/types";

import { Box, Inline } from "@fermions";

import { Swimlane } from "@components/templates/KanbanTemplate/Swimlane/Swimlane";

import "./KanbanTemplate.scss";

export interface KanbanTemplateProps {
  swimlanes: SwimlaneType[];
  renderItem: (item: SwimlaneItem, isDragging?: boolean) => React.JSX.Element;
  onClickItem?: (item: SwimlaneItem) => void;
  onMoveItem: (destinationId: string, item: SwimlaneItem) => void;
  onReorderItem?: (item: SwimlaneItem, index: number) => void;
  allLanesAcceptable?: boolean;
  overridingMessage?: React.JSX.Element | string;
}

export const KanbanTemplate = ({
  swimlanes,
  renderItem,
  onMoveItem,
  onClickItem,
  onReorderItem,
  allLanesAcceptable,
  overridingMessage
}: KanbanTemplateProps) => {
  const swimlanesById: { [id: string]: SwimlaneType } = useMemo(
    () =>
      Object.fromEntries(
        swimlanes.map((swimlane: SwimlaneType) => [swimlane.id, swimlane])
      ),
    [swimlanes]
  );

  const [draggingItem, setDraggingItem] = useState<SwimlaneItem>();

  const onDragStart: OnDragStartResponder = ({ draggableId, source }) => {
    const item = swimlanesById[source.droppableId]?.items?.find(
      item => String(item.id) == draggableId
    );

    setDraggingItem(item);
  };

  const onDragEnd: OnDragEndResponder = ({
    draggableId,
    source,
    destination
  }) => {
    setDraggingItem(undefined);

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceLane = source.droppableId;
    const destinationLane = destination.droppableId;

    const item = swimlanesById[source.droppableId]?.items?.find(
      item => String(item.id) == draggableId
    );

    if (
      !item ||
      !isAllowedToMove(
        item,
        destination.droppableId,
        allLanesAcceptable,
        !!onReorderItem
      )
    ) {
      return;
    }

    if (sourceLane === destinationLane) {
      onReorderItem?.(item, destination.index);
    } else {
      onMoveItem?.(destination.droppableId, item);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Inline width="100" className="kanban-template">
        <Inline width="100" className="kanban-template__lanes">
          {swimlanes.map((swimlane: SwimlaneType) => (
            <Swimlane
              key={swimlane.id}
              swimlane={swimlane}
              renderItem={renderItem}
              onClickItem={onClickItem}
              isDragging={!!draggingItem}
              isValidZone={
                draggingItem &&
                isAllowedToMove(
                  draggingItem,
                  swimlane.id,
                  allLanesAcceptable,
                  !!onReorderItem
                )
              }
              allLanesAcceptable={allLanesAcceptable}
            />
          ))}
        </Inline>
        {overridingMessage && (
          <Box className="kanban-template__overriding-message">
            {overridingMessage}
          </Box>
        )}
      </Inline>
    </DragDropContext>
  );
};

function isAllowedToMove(
  item: SwimlaneItem,
  swimlaneId: string,
  allLanesAcceptable?: boolean,
  onReorderItemDeclared?: boolean
) {
  return (
    allLanesAcceptable ||
    item?.acceptableLanesToMove?.includes(swimlaneId) ||
    // If reorder allowed, allowed to move in current swimlane
    (onReorderItemDeclared && item?.swimlaneId === swimlaneId)
  );
}
