import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useLogout, useUserProfile } from "@shared/hooks";

import Popup from "@shared-components/popup/Popup";

import { routeConstants } from "@app/constants";

import { Button } from "@atoms/Button";

import { MessageBox, MessageBoxType } from "@molecules/MessageBox";

import UserForm from "@components/organisms/UserForm/UserForm";
import ModalTemplate from "@components/templates/ModalTemplate/ModalTemplate";

const Profile = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    userFormConfig,
    requireLogout,
    updateUserProfile,
    error,
    submitted
  } = useUserProfile();
  const { logout } = useLogout();
  const { t } = useTranslation();
  const handleSubmit = updatedUser => {
    //eslint-disable-next-line
    updateUserProfile(updatedUser);
  };

  const doForceLogout = () => {
    logout();
    navigate(routeConstants.logout, {
      state: {}
    });
  };

  const addConfigKey = key =>
    userFormConfig?.find(field => field.key === key) ?? {};

  const formConfig = [
    {
      key: "firstname",
      type: "text",
      required: true
    },
    {
      key: "lastname",
      type: "text",
      required: true
    },
    {
      key: "email",
      type: "text",
      required: true,
      disabled: true
    },
    addConfigKey("jobTitle"),
    {
      key: "role",
      type: "dropdown",
      required: true,
      disabled: true,
      translate: true,
      items: []
    },
    addConfigKey("MOBILE_NUMBER"),
    addConfigKey("BUSINESS_NUMBER"),
    {
      key: "NOTIFICATION",
      type: "radio",
      defaultValue: "digest",
      items: ["digest", "each"],
      path: "properties.notification.email"
    }
  ];
  return (
    <>
      <UserForm
        title={t("common:ui.manageProfile.updateTitle")}
        formConfig={formConfig}
        user={userProfile}
        handleSubmit={handleSubmit}
        submitLabel={t("common:ui.manageProfile.updateSubmit")}
        other={{
          error: error
        }}
        moreContents={
          submitted && (
            <MessageBox
              message={t("common:ui.manageProfile.updateSuccessful")}
              type={MessageBoxType.SUCCESS}
            />
          )
        }
      />
      <Popup visibility={requireLogout} width="50rem">
        <ModalTemplate
          boxClassName="tag-editor"
          title={"Profile update successful"}
          content={
            <>
              <p>Your changes were successful.</p>
              <p>
                As a security precaution, you need to log out and log in again
                for these changes to take effect.
              </p>
            </>
          }
          footer={<Button label="Log out" onClick={doForceLogout} />}
        />
      </Popup>
    </>
  );
};
export default Profile;
