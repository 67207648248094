import {
  AccessLevel,
  AuthUser,
  Permission,
  ResourceName,
  ResourcePermission,
  Role,
  RoleFormData,
  RoleGet,
  RolePost
} from "@app/types";

export const entityRestrictedDefault = true;

export const getEntityRestrictedPermissionId = (
  resourcesPermissions: ResourcePermission[]
) => {
  return resourcesPermissions
    ?.find(resource => resource.section === ResourceName.ENTITY_RESTRICTION)
    ?.resources?.[0]?.permissions?.find(
      permission => permission.accessLevel === AccessLevel.READ
    )?.id;
};

export function constructRoleForBE({
  data,
  existingRole,
  resourcesPermissions = []
}: {
  data: RoleFormData;
  existingRole?: Role;
  resourcesPermissions?: ResourcePermission[];
}): RolePost {
  if (data.type === "HOST") {
    data.resources[ResourceName.ENTITY_RESTRICTION] = [];
  } else {
    const entityRestrictedPermissionId =
      getEntityRestrictedPermissionId(resourcesPermissions);

    if (entityRestrictedPermissionId) {
      if (data.entityRestricted) {
        data.resources[ResourceName.ENTITY_RESTRICTION] = [
          { id: entityRestrictedPermissionId }
        ];
      } else {
        data.resources[ResourceName.ENTITY_RESTRICTION] = [];
      }
    }
  }

  return {
    ...(existingRole ? { id: existingRole.id } : {}),
    name: data.name,
    type: data.type,
    description: data.description,
    permissions: Object.values(data.resources || {}).flat() as Permission[],
    properties: {}
  };
}

export function getIsEntityRestricted({
  role,
  resourcesPermissions = []
}: {
  role?: Partial<RoleGet>;
  resourcesPermissions?: ResourcePermission[];
}): boolean | undefined {
  if (role?.type !== "CLIENT") {
    return;
  }
  const entityRestrictedPermissionId =
    getEntityRestrictedPermissionId(resourcesPermissions);

  if (!role || !entityRestrictedPermissionId) {
    return entityRestrictedDefault;
  }

  return role?.permissions?.includes(entityRestrictedPermissionId);
}

export const hasProjectAccessRelativeToMembership = (
  user: AuthUser,
  isMember: boolean,
  accessLevel: AccessLevel
) =>
  user.checkAccess(
    isMember
      ? ResourceName.PROJECTS_WITH_MEMBERSHIP
      : ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
    accessLevel
  );
