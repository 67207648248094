import React, { MutableRefObject } from "react";

import { IconFillStyle } from "@atoms/Icon";

export enum Positioning {
  BOTTOM = "bottom",
  TOP = "top",
  RIGHT = "right",
  BOTTOM_LEFT = "bottom-left"
}

export enum DropdownCalculationType {
  FIXED = "fixed",
  RELATIVE = "relative",
  ABSOLUTE = "absolute"
}

export enum DropdownTheme {
  LIGHT = "light",
  DARK = "dark"
}

export type DropdownItemId = string | number;

export interface DropdownItemType {
  value?: DropdownItemId | { [key: string]: DropdownItemId };
  id?: DropdownItemId;
  name: string;
  tag?: string;
  action?: string;
  subItems?: DropdownItemType[];
  hasSubItems?: boolean;
  icons?: Array<{
    icon: string;
    state?: "active" | "inactive" | "light";
    label?: string;
    fillStyle?: IconFillStyle;
  }>;
  onClick?: () => void;
  isActive?: boolean;
  isHover?: boolean;
}

export interface DropdownActionType {
  key: string;
  name: string;
  onClick: () => void;
  iconName?: string;
}

export type DropdownListParent = MutableRefObject<
  HTMLDivElement | null | undefined
>;

export function extractDropdownItemId(item: DropdownItemType): DropdownItemId {
  if (!item?.value && !item?.id) {
    return item?.name ?? "";
  }
  if (item.value?.value || item.value?.id || item.value?.name) {
    return item.value?.value ?? item.value?.id ?? item.value?.name;
  }
  return item.value ?? item.id;
}

export const sortWithAll = (a: DropdownItemType, b: DropdownItemType) => {
  if (!a.value) {
    return -1;
  }
  if (!b.value) {
    return 1;
  }
  return defaultSortFn(a, b);
};

const defaultSortFn = (a: DropdownItemType, b: DropdownItemType) =>
  String(a.name ?? a.value ?? a.id ?? "").localeCompare(
    String(b.name ?? b.value ?? b.id ?? ""),
    "en",
    { sensitivity: "base" }
  );

export function sortDropdownItems(
  items: DropdownItemType[],
  sortComparator?: (a: DropdownItemType, b: DropdownItemType) => number
): DropdownItemType[] {
  if (!items?.length || items?.length <= 1) {
    return items;
  }

  return items?.toSorted?.(sortComparator ?? defaultSortFn);
}

export function isSomeSubItemSelected(
  item: DropdownItemType,
  selectedValues: DropdownItemType[] = []
): boolean {
  if (!item.subItems?.length) {
    return selectedValues?.some(
      selectedValue =>
        extractDropdownItemId(selectedValue) === extractDropdownItemId(item)
    );
  }
  return item.subItems.some(
    (subItem: DropdownItemType) =>
      selectedValues.some(
        selectedValue =>
          extractDropdownItemId(selectedValue) ===
          extractDropdownItemId(subItem)
      ) || isSomeSubItemSelected(subItem, selectedValues)
  );
}

export function calculateStyle(
  parentRef?: DropdownListParent | undefined,
  positioning?: Positioning,
  minWidthOverride?: number,
  dropdownListRef?: React.MutableRefObject<HTMLDivElement | null | undefined>,
  calculation?: DropdownCalculationType
): React.CSSProperties {
  const parentElement = parentRef?.current?.getBoundingClientRect?.();
  const minWidth = minWidthOverride ?? Math.max(parentElement?.width ?? 0, 180);
  const dropdownListElement =
    dropdownListRef?.current?.getBoundingClientRect?.();

  if (!parentElement) {
    return {
      minWidth: `${minWidth}px`
    };
  }

  if (calculation === DropdownCalculationType.RELATIVE) {
    const marginTop = parentElement?.height || 24;
    return {
      right: `0px`,
      marginTop: `${marginTop}px`,
      position: DropdownCalculationType.RELATIVE,
      minWidth: `${minWidth}px`
    };
  }

  if (calculation === DropdownCalculationType.ABSOLUTE) {
    const top = parentElement?.height || 0;
    return {
      top: `${top}px`,
      left: 0,
      position: DropdownCalculationType.ABSOLUTE,
      minWidth: `${minWidth}px`
    };
  }

  const style = {
    position: DropdownCalculationType.FIXED,
    width: minWidth,
    top: 0,
    left: 0
  };

  switch (positioning) {
    case Positioning.RIGHT:
      style.top = parentElement.y;
      style.left = parentElement.x + parentElement.width - 1;
      break;
    case Positioning.TOP:
      style.top = parentElement.y - (dropdownListElement?.height || 200);
      style.left = parentElement.x;
      break;
    case Positioning.BOTTOM_LEFT:
      style.top = parentElement.y + parentElement.height;
      style.left = parentElement.x - minWidth + parentElement.width;
      break;
    case Positioning.BOTTOM:
    default:
      style.top = parentElement.y + parentElement.height;
      style.left = parentElement.x;
      break;
  }

  return {
    ...style,
    width: `${style.width}px`,
    top: `${style.top}px`,
    left: `${style.left}px`
  };
}
