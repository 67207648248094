import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";

import Handsontable from "handsontable";
import { HyperFormula } from "hyperformula";
import { isEmpty, isNil } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants/systemConstants.js";
import {
  applyConditionalFormatting,
  webSheetCell,
  shouldIgnoreDateValidation
} from "@shared/helpers/webSheetCells.js";
import * as websheetStyling from "@shared/helpers/websheet/styling.ts";
import websheetUtilities from "@shared/helpers/websheetUtilities.js";
import { useWindowSize } from "@shared/hooks/index.js";

import {
  FunctionValuePlugin,
  FunctionValuePluginTranslations
} from "@app/helpers/FunctionValuePlugin.ts";

import TableContent from "@components/organisms/TableContent/TableContent.jsx";
import WebsheetSheetTabs from "@components/organisms/WebsheetSheetTabs/WebsheetSheetTabs.jsx";

const { columnMultiplier, columnWidthContextMenu } =
  systemConstants.project.document.editExcelDocumentPage;

HyperFormula.registerFunctionPlugin(
  FunctionValuePlugin,
  FunctionValuePluginTranslations
);

Handsontable?.renderers?.registerRenderer(
  "ot.websheetCell",
  (hotInstance, TD, row, col, prop, value, cellProperties) => {
    Handsontable.renderers.getRenderer(cellProperties.type || "text")(
      hotInstance,
      TD,
      row,
      col,
      prop,
      value,
      cellProperties
    );
    cellProperties.border &&
      websheetStyling.formatBorder(cellProperties.border, TD);
    cellProperties.font && websheetStyling.formatFont(cellProperties.font, TD);
    cellProperties.fill && websheetStyling.formatFill(cellProperties.fill, TD);
    websheetUtilities.formatDollarSignWithZero(cellProperties.pattern, TD);
    cellProperties.hyperlink &&
      websheetUtilities.formatHyperLink(cellProperties.hyperlink, TD, value);

    applyConditionalFormatting(row, col, cellProperties, value, TD);
  }
);

const EditableWebsheet = React.forwardRef((props, fwdRef) => {
  const {
    isReadOnly,
    onCurrentSheetNameChanged: parentOnCurrentSheetNameChanged,
    currentSheetName,
    invalidCells,
    updateInvalidCells,
    user,
    data: pData,
    setHasLocalChanges,
    markUserStillActive,
    loading,
    error,
    setErrorMessage,
    hasLoadError,
    onSheetLoading,
    onCellValidationError,
    isHidden
  } = props;

  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const hotTableComponent = useRef(null);
  const sourceMemoization = useRef({});

  const [currentInvalidCell, setCurrentInvalidCell] = useState(null);

  const [settings, setSettings] = useState(null);
  const [filters, setFilters] = useState({});
  const [localData, setLocalData] = useState(null);
  const [updateSelectedSheet, setUpdateSelectedSheet] = useState(true);
  const [isCellValueValid, setIsCellValueValid] = useState(true);
  const rowHeaders = !hasLoadError;
  const colHeaders = !hasLoadError;
  const hfInstance = useRef(null);

  // feed initial localData from pData
  useEffect(() => {
    if (!localData && pData?.length) {
      setLocalData(pData);
    }
  }, [localData, pData]);

  // feed in hyperFormula instance to support cross sheets formulas
  const parsedWebsheetData = useMemo(
    () =>
      localData?.reduce((acc, sheet) => {
        const sheetName = sheet.sheet;
        const data = websheetUtilities.convertCellDataInSheet(sheet).data;
        return {
          ...acc,
          [sheetName]: data
        };
      }, {}),
    [localData]
  );

  useEffect(() => {
    if (!isEmpty(parsedWebsheetData)) {
      hfInstance.current = HyperFormula.buildFromSheets(parsedWebsheetData, {
        licenseKey: "internal-use-in-handsontable"
      });
    }
  }, [parsedWebsheetData]);

  // NB: update fields in currentSheet will also update localData
  const localCurrentSheet = useMemo(
    () => localData?.find(sheet => sheet.sheet === currentSheetName),
    [currentSheetName, localData]
  );
  const sheetNames = useMemo(
    () => websheetUtilities.getVisibleSheetNames(localData),
    [localData]
  );

  // update local data with latest content from pData
  useEffect(() => {
    if (!localCurrentSheet || localCurrentSheet.downloaded) {
      return;
    }
    const remoteCurrentSheet = pData?.find(
      sheet => sheet.sheet === currentSheetName
    );
    if (remoteCurrentSheet.downloaded) {
      setLocalData(prev =>
        prev.map(sheetData =>
          sheetData.sheet === currentSheetName ? remoteCurrentSheet : sheetData
        )
      );
    }
  }, [currentSheetName, localCurrentSheet, pData]);

  const afterChange = useCallback(
    (changes, source) => {
      if (isReadOnly || !changes || source === "loadData") {
        return;
      }
      if (!localCurrentSheet) {
        return;
      }
      const [rowId, colId, , changedValue] = changes[0];
      if (!changedValue) {
        const cellId = `${rowId}-${colId}`;
        updateInvalidCells(cellId, true, currentSheetName);
      }
      changes
        .filter(
          ([row, , prevVal, newVal]) =>
            !localCurrentSheet[row + 1] || prevVal !== newVal
        )
        .forEach(([row, column, , newVal]) => {
          if (!localCurrentSheet.data[row + 1]) {
            for (let i = localCurrentSheet.data.length; i <= row + 1; i++) {
              localCurrentSheet.data[i] = [];
            }
          }
          // save the editing changes to current sheet data
          localCurrentSheet.data[row][column] = newVal;
        });

      markUserStillActive();
      setHasLocalChanges(true);
    },
    [
      isReadOnly,
      localCurrentSheet,
      markUserStillActive,
      setHasLocalChanges,
      updateInvalidCells,
      currentSheetName
    ]
  );

  const afterLoadData = useCallback(() => {
    if (!localCurrentSheet) {
      return;
    }

    if (
      filters[localCurrentSheet.sheet] &&
      !filters[localCurrentSheet.sheet]?.applied
    ) {
      const filterPlugin =
        hotTableComponent.current?.hotInstance?.getPlugin("filters");
      filters[localCurrentSheet.sheet].map(f =>
        filterPlugin?.addCondition(
          f.column,
          f.conditions[0].name,
          f.conditions[0].args,
          f.operation
        )
      );
      filters[localCurrentSheet.sheet].applied = true;
      filterPlugin?.filter();
    }
    onSheetLoading(false);
  }, [localCurrentSheet, filters, onSheetLoading]);

  const afterScrollInteraction = useCallback(() => {
    if (isReadOnly) {
      return;
    }
    markUserStillActive();
  }, [isReadOnly, markUserStillActive]);

  const handleUpdate = useCallback(() => {
    const formulaEvaluator = ({ sheet, col, row }) => {
      return () => {
        const cellValue = hfInstance.current?.getCellValue({ sheet, col, row });
        if (!isNil(cellValue) && typeof cellValue == "object") {
          return { error: cellValue.value, ...cellValue };
        }
        return cellValue;
      };
    };
    const dataCopy = structuredClone(localData);

    Object.values(dataCopy).forEach(sheet => {
      if (sheet.data.converted) {
        sheet.data.converted = false;
        const sheetIdx = hfInstance.current?.getSheetId(sheet.sheet);

        sheet.data.forEach((row, rowIdx) =>
          row.forEach((cell, colIdx) => {
            const result = formulaEvaluator({
              sheet: sheetIdx,
              row: rowIdx,
              col: colIdx
            });

            row[colIdx] = websheetUtilities.convertStringToFormula(
              cell,
              result
            );
          })
        );
      }
    });

    return dataCopy;
  }, [localData]);

  useImperativeHandle(
    fwdRef,
    () => {
      return {
        handleUpdate
      };
    },
    [handleUpdate]
  );

  useEffect(() => {
    if (currentInvalidCell && !isReadOnly) {
      const cellErrorMessage = () => {
        const currentCellMessage = currentInvalidCell.messages;
        if (currentCellMessage) {
          // we're in a infinite loop caused by errors and output is not helpful to user
          return currentCellMessage;
        }

        const currentCellFormat = currentInvalidCell.format;

        if (currentCellFormat?.pattern) {
          return t(
            "requests:requests.websheet.cell.validation.formatRequired",
            {
              format: currentCellFormat?.pattern,
              interpolation: { escapeValue: false }
            }
          );
        }

        return t("requests:requests.websheet.cell.validation.formatRequired", {
          format: currentCellFormat,
          interpolation: { escapeValue: false }
        });
      };

      onCellValidationError({
        message: t("requests:requests.websheet.cell.validation.error.message", {
          validationMessage: cellErrorMessage(),
          interpolation: { escapeValue: false }
        }),
        cell: currentInvalidCell
      });
    }
  }, [
    currentInvalidCell,
    isCellValueValid,
    onCellValidationError,
    isReadOnly,
    t
  ]);

  //This effect should only be used when updating the current sheet
  useEffect(() => {
    if (!currentSheetName) {
      return;
    }

    if (!hfInstance.current?.getNamedExpression("TRUE")) {
      hfInstance.current?.addNamedExpression("TRUE", "=TRUE()");
    }
    if (!hfInstance.current?.getNamedExpression("FALSE")) {
      hfInstance.current?.addNamedExpression("FALSE", "=FALSE()");
    }

    const setAfterColumnResize = (newSize, column) => {
      const pixelToWidth = pixel =>
        pixel &&
        Math.round((pixel - columnWidthContextMenu) / columnMultiplier);
      if (isReadOnly) {
        return;
      }
      const cols = localData.find(d => d.sheet === currentSheetName)?.columns;
      if (cols) {
        cols[column] = { ...cols[column], width: pixelToWidth(newSize) };
      }
    };
    const afterValidate = (isValid, value, row, col) => {
      if (
        !isValid &&
        shouldIgnoreDateValidation({
          cell: hotTableComponent.current?.hotInstance?.getCellMeta?.(row, col),
          value,
          defaultValue: localCurrentSheet.data[row][col]
        })
      ) {
        return;
      }
      const cellId = `${row}-${col}`;
      updateInvalidCells(cellId, isValid, currentSheetName);
      if (!isValid) {
        const invalidCell =
          hotTableComponent.current?.hotInstance?.getCellMeta?.(row, col);
        setCurrentInvalidCell(invalidCell);
      } else {
        setCurrentInvalidCell(null);
      }
    };

    if (filters[localCurrentSheet?.sheet] && !updateSelectedSheet) {
      filters[localCurrentSheet?.sheet].applied = false;
      return;
    }

    let hiddenColumns = {};
    let hiddenRows = {};
    let mergeCells = [];
    let sheetData;
    let formats = [];
    let dataValidations = [];
    let conditionalFormattings = [];

    if (localCurrentSheet?.data) {
      setUpdateSelectedSheet(false);
      if (!localCurrentSheet.data.converted) {
        websheetUtilities.convertCellDataInSheet(localCurrentSheet);
        localCurrentSheet.data.converted = true;
      }

      const sheetDataFormatter = websheetUtilities.getSheetDataFormatter();

      sheetData = localCurrentSheet?.data;
      formats = localCurrentSheet?.formats;
      dataValidations =
        localCurrentSheet?.dataValidations?.formattedValidations;
      hiddenColumns = sheetDataFormatter.getHiddenColumns(
        localCurrentSheet.columns
      );
      hiddenRows = sheetDataFormatter.getHiddenRows(localCurrentSheet.rows);
      mergeCells = sheetDataFormatter.getMergeCells(localCurrentSheet.merges);
      conditionalFormattings = localCurrentSheet?.conditionalFormattings;
    }

    const height = websheetUtilities.getWebsheetMaxHeight({
      websheetElementId: "handsontable-id",
      browserWindowHeight: windowSize.height
    });

    const defaultSettings = websheetUtilities.getDefaultSettings();
    setSettings({
      ...defaultSettings,
      mergeCells,
      formulas: {
        engine: hfInstance.current,
        sheetName: currentSheetName
      },
      data: sheetData,
      cells: (row, col) => {
        if (hasLoadError) {
          return { className: "border-none" };
        }
        return webSheetCell({
          isHostUser: user?.isHostUser,
          formats,
          sheetData,
          isReadOnly,
          currentSheet: localCurrentSheet,
          dataValidations,
          t,
          invalidCells: invalidCells[currentSheetName],
          sourceRef: sourceMemoization.current,
          rowIdx: row,
          colIdx: col,
          rowOffset: 0,
          formatsLookup: localCurrentSheet.formatsLookup,
          dvLookup: localCurrentSheet.dvLookup,
          conditionalFormattings,
          hyperFormulaRef: hfInstance
        });
      },
      readOnly:
        user?.isHostUser || !localCurrentSheet?.sheetProtection?.sheet
          ? isReadOnly
          : true,
      manualColumnResize: websheetUtilities.getColWidths(localCurrentSheet),
      colHeaders,
      rowHeaders: true,
      hiddenColumns,
      hiddenRows,
      invalidCellClassName: "",
      height,
      viewportRowRenderingOffset: 5,
      viewportColumnRenderingOffset: 5,
      afterValidate,
      afterLoadData,
      afterChange,
      afterColumnResize: setAfterColumnResize,
      afterScrollHorizontally: afterScrollInteraction,
      afterScrollVertically: afterScrollInteraction,
      afterFilter: currentSheetFilters => {
        currentSheetFilters.applied = true;
        setFilters({
          ...filters,
          [localCurrentSheet.sheet]: currentSheetFilters
        });
      },
      afterSelection: () => setIsCellValueValid(true)
    });
  }, [
    currentSheetName,
    localData,
    filters,
    isReadOnly,
    t,
    updateSelectedSheet,
    user?.isHostUser,
    markUserStillActive,
    invalidCells,
    hasLoadError,
    localCurrentSheet,
    windowSize,
    afterLoadData,
    afterChange,
    afterScrollInteraction,
    rowHeaders,
    colHeaders,
    updateInvalidCells
  ]);

  const updateSheet = useCallback(
    sheetName => {
      setErrorMessage();
      setUpdateSelectedSheet(true);
      parentOnCurrentSheetNameChanged(sheetName);
      if (filters[sheetName]) {
        filters[sheetName].applied = false;
        setFilters({ ...filters });
      }
    },
    [filters, parentOnCurrentSheetNameChanged, setErrorMessage]
  );

  useEffect(() => {
    if (isNil(currentSheetName)) {
      parentOnCurrentSheetNameChanged(sheetNames[0]);
      setUpdateSelectedSheet(true);
    }
  }, [currentSheetName, parentOnCurrentSheetNameChanged, sheetNames]);
  return (
    <div style={{ visibility: isHidden ? "hidden" : "visible" }}>
      {hfInstance.current && currentSheetName && (
        <>
          <TableContent
            loading={loading}
            error={error}
            settings={settings}
            rowHeaders={rowHeaders}
            hotTableComponent={hotTableComponent}
          />
          <WebsheetSheetTabs
            currentSheetName={currentSheetName}
            sheetNames={sheetNames}
            updateSheet={updateSheet}
          />
        </>
      )}
    </div>
  );
});

EditableWebsheet.displayName = "EditableWebsheet";

EditableWebsheet.defaultProps = {
  isHidden: false
};

EditableWebsheet.propTypes = {
  isReadOnly: PropTypes.bool,
  onCurrentSheetNameChanged: PropTypes.func,
  currentSheetName: PropTypes.string,
  invalidCells: PropTypes.object,
  updateInvalidCells: PropTypes.func,
  user: PropTypes.object,
  data: PropTypes.array,
  setHasLocalChanges: PropTypes.func,
  markUserStillActive: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  setErrorMessage: PropTypes.func,
  hasLoadError: PropTypes.bool,
  onSheetLoading: PropTypes.func,
  onCellValidationError: PropTypes.func,
  isHidden: PropTypes.bool
};

export default EditableWebsheet;
