import _ from "lodash";
import * as yup from "yup";

import { actionTypeConstants } from "@shared/constants/actionTypeConstants.js";
import { systemConstants } from "@shared/constants/systemConstants";

import { menuItemAccess } from "@app/helpers/menuItemAccess";

import { getIndividualFieldPermission } from "./actionItemTypes.ts";

const queryTypeConstants = systemConstants.project.queries.queryTypes;

const defaultIndicators = {
  overdue: {
    key: "overdue",
    label: "Past Due Date",
    colorScheme: "error"
  },
  upcoming: {
    key: "upcoming",
    label: "Upcoming",
    colorScheme: "warning"
  },
  responded: {
    key: "responded",
    label: "Responded",
    colorScheme: "success"
  },
  closed: {
    key: "closed",
    label: "Closed",
    colorScheme: "standard"
  }
};

export const QUERY_TYPE_ICON = {
  CONVERSATION: "forum",
  WEBSHEET: "view_module",
  SMARTFORM: "fact_check"
};

export const QUERY_FILTER_TYPES = {
  MULTI_CHOICE: "multiChoice",
  SINGLE_CHOICE: "singleChoice"
};

export const QUERY_FILTER_UI_CONTROL = {
  PILLS: "pills",
  DROPDOWN: "dropdown"
};

export function getActionItemTypeDisplayName(
  useCustomName,
  queryName,
  i18nText
) {
  return useCustomName ? queryName : i18nText;
}

export function getSmartFormPageHeader(
  description,
  useCustomName,
  queryName,
  i18nText
) {
  return description || (useCustomName ? queryName : i18nText);
}

function formatQueryTypeOption(actionItemType, i18nText) {
  const actionItemConfiguration = {
    name: getActionItemTypeDisplayName(
      actionItemType.configuration?.useCustomName,
      actionItemType.name,
      i18nText("requests:requests.configured.name", {
        context: actionItemType.configuration.key
      })
    ),
    key: actionItemType.configuration.key,
    id: actionItemType.configuration.key,
    value: actionItemType.configuration.key,
    type: actionItemType.configuration.type,
    fields: actionItemType.configuration.fields
  };

  if (actionItemType.configuration?.useCustomName) {
    actionItemConfiguration.useCustomName =
      actionItemType.configuration.useCustomName;
  }

  return actionItemConfiguration;
}

export const queryTypeDropdownOptions = ({
  actionItemTypes,
  t,
  excludeWebsheets,
  includeCopyOption
}) => {
  const dropdownOptions =
    actionItemTypes
      ?.filter(
        i =>
          !excludeWebsheets ||
          i.configuration.type === systemConstants.actionItemTypes.conversation
      )
      .map(a => formatQueryTypeOption(a, t))
      .map(dd => ({
        ...dd
      })) ?? [];
  if (includeCopyOption) {
    const copyOption = {
      name: t("requests:requests.ui.copyRequestsOptionLabel"),
      key: systemConstants.copyActionItemOption.key,
      id: systemConstants.copyActionItemOption.key,
      value: systemConstants.copyActionItemOption.key,
      type: "",
      fields: []
    };
    dropdownOptions.push(copyOption);
  }
  return dropdownOptions;
};

export function getSelectedQueryType(queryType, actionItemTypes) {
  const actionItemType = actionItemTypes?.find(
    act => act.configuration.key == queryType.key
  );
  const selectedQueryType = {
    isQueryList: queryType.key == queryTypeConstants.queryList,
    isConfigType: queryType.key !== queryTypeConstants.queryList,
    isTriggerSendToExternalOnCreate:
      actionItemType?.configuration?.triggerApiOnCreate,
    key: queryType.key,
    actionItemType: actionItemType?.configuration?.type
  };
  return selectedQueryType;
}

export function getActionItemTypeConfiguration(key, actionItemTypes) {
  return actionItemTypes?.find(
    actionItemType => actionItemType.configuration.key === key
  )?.configuration;
}

export function isEditAvailable(query, actionItemTypes) {
  const buttons = getWebsheetButtons(query?.queryType, actionItemTypes);
  const edit = buttons.find(({ action }) => action === "EDIT");
  if (edit) {
    // statuses could be ["STATUS"], "STATUS" or empty
    const statuses = edit.from;
    // TODO: ONE-5149 will fix the root cause in BE, now it is a workaround. Correct code should be if (!statuses || statuses === query.status)
    if (
      !statuses ||
      statuses === query.status ||
      (statuses === systemConstants.project.queries.status.open &&
        query.status === systemConstants.project.queries.status.responded)
    ) {
      return true;
    }
    return (
      Array.isArray(statuses) &&
      statuses.some(status => status === query.status)
    );
  }
}

export function getWebsheetButtons(key, actionItemTypes) {
  const configuration = getActionItemTypeConfiguration(key, actionItemTypes);

  // prefer SMARTFORM_BUTTONS or WEBSHEET_BUTTONS over BUTTONS
  const typeMenu = configuration?.menus?.find(
    menu => menu.name === `${configuration.type}_BUTTONS`
  );
  if (typeMenu) {
    return typeMenu.actions;
  }
  const menu = configuration?.menus?.find(menu => menu.name === "BUTTONS");
  if (menu) {
    return menu.actions;
  }
  return [];
}

export function getQueryTypeDropdownOptionByKey(
  queryTypeKey,
  queryTypes,
  i18nText
) {
  if (!queryTypeKey) {
    return undefined;
  }

  const queryType = queryTypes
    .filter(qt => qt.key === queryTypeKey)
    .map(qt => {
      const name = qt.useCustomName
        ? qt.name
        : i18nText("requests:requests.configured.name", { context: qt.key });
      return {
        name,
        value: qt
      };
    });
  if (queryType && queryType.length > 0) {
    return queryType[0];
  }
  return undefined;
}

export function getQueryActions(queryType, actionItemTypes) {
  if (actionItemTypes) {
    const actionItemType = actionItemTypes.find(
      at => at.configuration.key == queryType
    );
    if (actionItemType) {
      return actionItemType.configuration?.menus?.find(
        item => item?.name == systemConstants.project.queries.menu.actionItem
      )?.actions;
    }
  }
}

export function expectedQueryTypes(actionItemTypes, i18nText) {
  const allQueryTypes = (actionItemTypes ?? []).map(a =>
    formatQueryTypeOption(a, i18nText)
  );
  const allQueryTypeKeys = allQueryTypes.map(qt => qt.key);
  return {
    queryTypes: allQueryTypes,
    allQueryTypeKeys
  };
}

export function getQuestionConfig(smartFormConfig, questionId) {
  return (
    smartFormConfig?.questionList?.find(q => q.questionId === +questionId) ?? {}
  );
}

export function formatQueryType(queryType, allQueryTypes, i18nText) {
  const actionItemType = allQueryTypes.find(type => type.key === queryType);

  return getActionItemTypeDisplayName(
    actionItemType?.useCustomName,
    actionItemType?.name,
    i18nText("requests:requests.configured.name", {
      context: actionItemType?.key
    })
  );
}

export function expectedActionIndicators() {
  return defaultIndicators;
}

export function getActionIndicator(
  { status, requiredBy },
  referenceDate,
  indicatorUiConfig
) {
  const indicators = indicatorUiConfig ?? defaultIndicators;
  const [statusKey] = Object.entries(
    systemConstants.project.queries.status
  ).find(([, value]) => value === status);
  if (isClosed({ status })) {
    return { ...indicators.closed, status: statusKey };
  }
  if (referenceDate && isOverdue({ requiredBy }, referenceDate)) {
    return { ...indicators.overdue, status: statusKey };
  }

  if (referenceDate && !isOverdue({ requiredBy }, referenceDate)) {
    return { ...indicators.upcoming, status: statusKey };
  }

  if (isDraft({ status })) {
    return { ...indicators.draft, status: statusKey };
  }
}

export function isOverdue({ requiredBy }, referenceDate) {
  if (!requiredBy) {
    return false;
  }
  const dueDate = new Date(requiredBy);
  dueDate.setHours(0, 0, 0, 0);
  referenceDate.setHours(0, 0, 0, 0);
  return dueDate < referenceDate;
}

export function isNote(status) {
  return status == systemConstants.project.queries.status.note;
}

export function isClosed({ status }) {
  return status === systemConstants.project.queries.status.closed;
}

export function isError({ status }) {
  return status === systemConstants.project.queries.status.error;
}

export function isDraft({ status }) {
  return status === systemConstants.project.queries.status.draft;
}

export function isActive({ status }) {
  const { open, responded } = systemConstants.project.queries.status;
  return status === open || status === responded;
}

export function validateQuery(query, i18nText, i18nContext) {
  if (query.queryType?.fields) {
    const fileField = query.queryType.fields.find(f => f.type == "file");
    if (!fileField) {
      return {
        success: true
      };
    }
    const fileValidations = fileField?.validations ?? [];
    const countValidation = fileValidations?.find(fv => fv.name == "count");
    const filesCount = query.files ? Object.keys(query.files).length : 0;

    const countErr = fileField.required && filesCount === 0;
    const maxErr = countValidation && filesCount > countValidation.value;

    const requests = countErr
      ? "requests:requests.configured.fields.files.validation.count.errorMessage"
      : "requests:requests.configured.fields.files.validation.maximum.errorMessage";

    if (countErr || maxErr) {
      return {
        success: false,
        error: i18nText(requests, { context: i18nContext })
      };
    }
  }
  return {
    success: true
  };
}

export function getQueryValidationSchema({
  configFields,
  additionalFields,
  i18nText,
  userTypes,
  actionType
}) {
  const userRoles = [userTypes].flat().filter(c => c);
  const relevantRequiredFields = configFields?.filter(cf => {
    const { isEditable, isCreatable } = getIndividualFieldPermission({
      field: cf,
      userRoleType: userRoles
    });
    const isInputable =
      actionType === actionTypeConstants.CREATE ? isCreatable : isEditable;
    return cf.required && isInputable;
  });

  const messageStrings = {
    requiredError: relevantRequiredFields
      ?.map(({ key }) => {
        switch (key) {
          case "assignedTo":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.assignedTo.validation.required.errorMessage"
              )
            };
          case "copiedTo":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.copiedTo.validation.required.errorMessage"
              )
            };
          case "query":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.query.validation.required.errorMessage"
              )
            };
          case "description":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.description.validation.required.errorMessage"
              )
            };
          case "reminderDate":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.reminderDate.validation.required.errorMessage"
              )
            };
          case "requiredBy":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.requiredBy.validation.required.errorMessage"
              )
            };
          case "entities":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.entities.validation.required.errorMessage"
              )
            };
          case "milestone":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.milestone.validation.required.errorMessage"
              )
            };
          case "tags":
            return {
              [key]: i18nText(
                "requests:requests.configured.fields.tags.validation.required.errorMessage"
              )
            };
          case "queryType":
            return {
              [key]: i18nText(
                "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage",
                { context: "queryType" }
              )
            };
          case "overrideMessage":
            return {
              [key]: i18nText(
                "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage",
                { context: "overrideMessage" }
              )
            };
        }
      })
      .reduce((acc, obj) => ({ ...acc, ...obj }), {})
  };

  function getRequiredErrorMessageString(key) {
    return (
      messageStrings.requiredError?.[key] ??
      i18nText(
        "requests:requests.ui.populateRequestForm.validation.fieldRequired.errorMessage"
      )
    );
  }

  // Returns a schema based on the field's configuration
  // allow non-required fields to be nullable and optional
  function getSchema({ base, key, required, multiSelect = false }) {
    const schema = base ?? yup.mixed();
    if (required) {
      if (multiSelect) {
        return schema.min(1).required(getRequiredErrorMessageString(key));
      }
      return schema.required(getRequiredErrorMessageString(key));
    }
    return schema.nullable().optional();
  }

  const isMultiSelect = f =>
    f.type === "copiedTo" ||
    f.type === "tags" ||
    f.type === "entity" ||
    f.multiSelect;

  // Schema validates according to the queryType's configuration details
  const schema = {};
  (configFields || []).forEach(f => {
    let base = null;
    switch (f.type) {
      case "user": {
        base = yup.object();
        break;
      }
      case "text":
      case "textarea": {
        base = yup.string();
        break;
      }
    }

    if (isMultiSelect(f)) {
      base = yup.array();
    }

    const { isEditable } = getIndividualFieldPermission({
      field: f,
      userRoleType: userRoles
    });

    const isFieldRequired = (() => {
      if (actionType === actionTypeConstants.CREATE) {
        return f.required;
      } else {
        return f.required && isEditable;
      }
    })();

    schema[f.key] = getSchema({
      base,
      key: f.key,
      required: isFieldRequired,
      multiSelect: isMultiSelect(f)
    });
  });

  Object.entries(additionalFields ?? {}).forEach(
    ([key, { type, required }]) => {
      let base;
      switch (type) {
        case "string":
          base = yup.string();
          break;
        case "object":
          base = yup.object();
          break;
      }
      schema[key] = getSchema({
        base,
        key,
        required
      });
    }
  );
  return yup.object(schema);
}

export function getActionsForQuery({ item, actionItemTypes, user, t }) {
  if (!actionItemTypes) {
    return [];
  }
  const actions = getQueryActions(item.queryType, actionItemTypes);
  const labelForAction = ({ action, i18nActionKey }) => {
    const actionKey = i18nActionKey ?? action;
    return t(`requests:requests.configured.menu.actions.${actionKey}.label`, {
      context: item.queryType
    });
  };
  let actionItems = [];
  actions
    ?.filter(({ availableTo }) => {
      return menuItemAccess({
        // @ts-expect-error: Temporarily ignoring until the library types are updated
        availableTo,
        user,
        isRequestor: item.isUserOnRequestorTeam,
        queryStatus: item.status
      });
    })
    .forEach(({ action, i18nActionKey }) => {
      const makeMenuItem = () => ({
        name: labelForAction({ action, i18nActionKey }),
        action
      });
      switch (action) {
        case "VIEW":
          actionItems.push(makeMenuItem());
          break;
        case "EDIT":
          if (item.status !== systemConstants.project.queries.status.closed) {
            actionItems.push(makeMenuItem());
          }
          break;
        case "DELETE":
          if (item.status == systemConstants.project.queries.status.draft) {
            actionItems.push(makeMenuItem());
          }
          break;
        case "HISTORY":
        case "REOPEN":
          if (item.status == systemConstants.project.queries.status.closed) {
            actionItems.push(makeMenuItem());
          }
          break;
        case "CLOSE":
          if (
            item.status === systemConstants.project.queries.status.open ||
            item.status === systemConstants.project.queries.status.responded
          ) {
            actionItems.push(makeMenuItem());
          }
          break;
      }
    });
  return actionItems;
}

/**
 * @param {object} options
 * @param {string?} options.workflowStep workflowStep key identifier
 * @param {function} options.t i18n translate function
 * @returns {string} translated workflow step name, key identifier or empty string if not found
 */
export function workflowStepName({ workflowStep, t }) {
  if (!workflowStep) {
    return "";
  }
  return t([
    `requests:requests.workflow.step.${workflowStep}.label`,
    workflowStep
  ]);
}

/**
 * @param {object} options
 * @param {{key?: string, config: {name?: string, i18nActionKey?: string}}} options.workflowAction workflowAction
 * @param {string|undefined} options.actionItemTypeFormat AIT Format (i.e systemConstants.actionItemTypes)
 * @param {string|undefined} options.actionItemTypeKey AIT key identifier
 * @param {function} options.t i18n translate function
 * @returns {string} literal name, translated workflow action name or key identifier
 */
export function workflowActionName({
  workflowAction,
  actionItemTypeFormat,
  actionItemTypeKey,
  t
}) {
  if (workflowAction.config.name) {
    return workflowAction.config.name;
  }

  const actionKey =
    workflowAction?.config?.i18nActionKey ?? workflowAction?.key;
  return t(
    [`requests:requests.configured.menu.actions.${actionKey}.label`, actionKey],
    {
      context:
        actionItemTypeFormat === systemConstants.actionItemTypes.smartForm
          ? systemConstants.actionItemTypes.smartForm
          : actionItemTypeKey
    }
  );
}

/**
 * @param {Array<{key: string}>} filterData - filter data to sanitize.
 * @param {Array<{lastActor: string}>} queries - queries to check.
 * @returns {Array<{key: string}>} sanitized filter data.
 */
export function sanitizeLastActor(filterData, queries) {
  const sanitizedFilterData = structuredClone(filterData);
  if (queries?.find(query => query.lastActor)) {
    _.remove(sanitizedFilterData, fd => fd.key === "requestedBy");
    return sanitizedFilterData;
  }
  _.remove(sanitizedFilterData, fd => fd.key === "lastActor");
  return sanitizedFilterData;
}

export function isAllowedToUpdateProperties({ aitConfig, query, user }) {
  const updaters = [aitConfig?.updaters].flat();
  const userTypes = [
    user?.xRole?.type,
    query?.isUserOnRequestorTeam ? "REQUESTOR" : "",
    query?.isUserOnRequesteeTeam ? "REQUESTEE" : ""
  ];

  return (
    updaters?.some(updater => userTypes.includes(updater)) ||
    updaters?.includes("ALL")
  );
}
