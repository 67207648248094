import React, { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";
import _DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";

import dateFormatter from "@shared/helpers/dateHelper";
import { utilities } from "@shared/helpers/utilities";
import { formatDateOnly, safeUtcDate } from "@shared/helpers/utilities.ts";
import { useAuthUser } from "@shared/hooks/useAuthUser.tsx";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { getFieldPermission } from "@app/helpers/actionItemTypes.ts";
import { workflowStepName } from "@app/helpers/actionItems.js";
import { formatDayOfWeek, isDateBefore } from "@app/helpers/date";

import { Box, Inline, Stack } from "@fermions";

import { Button, ButtonSize, ButtonVariant } from "@atoms/Button";
import { Icon, IconDesignStyle, IconFillStyle, IconSize } from "@atoms/Icon";
import { NameValuePair } from "@atoms/NameValuePair";
import { Pill, PillSize, PillVariant } from "@atoms/Pill";

import Avatar from "@components/atoms/Avatar";
import BoxTemplate from "@components/templates/BoxTemplate";

import AvatarList from "../AvatarList/AvatarList";
import "./RequestDetailsBox.scss";

const nameValuePairNameWidth = undefined;

const RequestDetailsBox = props => {
  const {
    pillConfig,
    requestQuery,
    queryConfig,
    approvalRejectFlag,
    updateQueryField,
    disabled,
    canEditLabels
  } = props;

  const { t } = useTranslation();
  const { user } = useAuthUser();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { locale, options } = useLocaleDate();
  const statusText = t(
    `requests:requests.configured.status.${requestQuery.status.toUpperCase()}.label`,
    { context: requestQuery.queryType }
  );
  const showStatus = pillConfig?.actionItemStatus?.shape === "rectangle";
  const showWorkflowStep = !!requestQuery.workflowStep;
  const isReminderDateUpcoming = !isDateBefore(
    requestQuery.reminderDate,
    new Date().toString()
  );

  const isFieldExist = useCallback(
    fieldKey => {
      return !!queryConfig?.fields?.find(f => f.key === fieldKey);
    },
    [queryConfig]
  );

  const renderTags = () => {
    const sortedTags = utilities.sortBy("name")(
      structuredClone(requestQuery.tags)
    );
    return sortedTags?.length > 0 ? (
      <Stack className="request-details-box__tags" gap="100">
        {sortedTags?.map(tag => {
          return (
            <Pill
              key={tag.id}
              size={PillSize.FILL}
              label={tag.name}
              variant={PillVariant.TRANSPARENT_DARK}
            />
          );
        })}
      </Stack>
    ) : (
      <></>
    );
  };

  const handleEditReminderDate = () => setIsCalendarOpen(!isCalendarOpen);

  const handleResetReminderDate = () =>
    updateQueryField({
      field: "reminderDate",
      value: null
    });

  const handleDateChange = date => {
    const formattedDate = formatDateOnly(date);
    updateQueryField({
      field: "reminderDate",
      value: formattedDate
    });
  };

  const statusPill = useMemo(() => <Pill label={statusText} />, [statusText]);

  const renderAssignedTo = useCallback(() => {
    if (!requestQuery.assignedTo) {
      return <></>;
    }
    const { isVisible } = getFieldPermission({
      fields: queryConfig?.fields,
      key: "assignedTo",
      userRoleType: user.xRole?.type,
      isUserOnRequestorTeam: requestQuery.isUserOnRequestorTeam
    });

    return (
      isFieldExist("assignedTo") &&
      isVisible && (
        <NameValuePair
          name={t(
            "requests:requests.configured.fields.assignedTo.pastTenseLabel",
            {
              context: requestQuery.queryType
            }
          )}
          value={
            <Inline gap="050">
              <Inline gap="100" alignment="left">
                {requestQuery.assignedTo && (
                  <Avatar
                    user={requestQuery.assignedTo}
                    width="2rem"
                    height="2rem"
                    showHover={true}
                  ></Avatar>
                )}
              </Inline>
              {!showStatus && (
                <div className="request-details-box__status">{statusPill}</div>
              )}
            </Inline>
          }
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [
    isFieldExist,
    queryConfig?.fields,
    requestQuery.assignedTo,
    requestQuery.isUserOnRequestorTeam,
    requestQuery.queryType,
    showStatus,
    statusPill,
    t,
    user.xRole?.type
  ]);

  const renderLastActor = useCallback(() => {
    if (!requestQuery.lastActor) {
      return <></>;
    }

    return (
      <NameValuePair
        name={t("requests:requests.configured.fields.lastActor", {
          context: requestQuery.queryType
        })}
        value={
          <Inline gap="100" alignment="left">
            {requestQuery.lastActor && (
              <Avatar
                user={requestQuery.lastActor}
                width="2rem"
                height="2rem"
                className={"last-actor-avatar"}
                showHover={true}
              ></Avatar>
            )}
          </Inline>
        }
        nameWidth={nameValuePairNameWidth}
      />
    );
  }, [requestQuery.lastActor, requestQuery.queryType, t]);

  const renderWorkflowStep = useCallback(() => {
    return (
      showWorkflowStep && (
        <NameValuePair
          name={t("requests:requests.workflow.label")}
          value={
            <Pill
              label={workflowStepName({
                t,
                workflowStep: requestQuery.workflowStep
              })}
            />
          }
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [requestQuery.workflowStep, showWorkflowStep, t]);

  const renderRequiredBy = useCallback(() => {
    if (props.hideRequiredByField || !requestQuery.requiredBy) {
      return <></>;
    }

    const { isVisible } = getFieldPermission({
      fields: queryConfig?.fields,
      key: "requiredBy",
      userRoleType: user.xRole?.type,
      isUserOnRequestorTeam: requestQuery.isUserOnRequestorTeam
    });
    return (
      isFieldExist("requiredBy") &&
      isVisible && (
        <NameValuePair
          name={t("requests:requests.configured.fields.requiredBy.label", {
            context: requestQuery.queryType
          })}
          value={dateFormatter(
            requestQuery?.requiredBy,
            locale,
            options.longFormat
          )}
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [
    props.hideRequiredByField,
    requestQuery.requiredBy,
    requestQuery.isUserOnRequestorTeam,
    requestQuery.queryType,
    queryConfig?.fields,
    user.xRole?.type,
    isFieldExist,
    t,
    locale,
    options.longFormat
  ]);

  const renderCopiedTo = useCallback(() => {
    const hasCopiedToUsers = requestQuery.copiedTo?.length;

    const { isVisible, isEditable } = getFieldPermission({
      fields: queryConfig?.fields,
      key: "copiedTo",
      userRoleType: user.xRole?.type,
      isUserOnRequestorTeam: requestQuery.isUserOnRequestorTeam
    });
    const value = (
      <Inline
        className="request-details-box__property-value-copyTo request-details-box--hover-reveal"
        alignment="left"
        gap="100"
        wrap
        width="100"
      >
        {hasCopiedToUsers ? (
          <div className="request-details-box__user">
            <AvatarList
              size={"small"}
              users={requestQuery.copiedTo}
              numOfUsers={3}
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={`request-details-box__action ${
            hasCopiedToUsers ? "request-details-box--hover-reveal__target" : ""
          }`}
        >
          {!disabled && isEditable && (
            <Button
              iconName="edit"
              variant={ButtonVariant.TEXT}
              size={ButtonSize.MINIMAL}
              onClick={props.onClickEditCopiedTo}
              {...(hasCopiedToUsers
                ? {
                    iconSize: IconSize.S
                  }
                : {
                    label: t("common:ui.forms.edit.label")
                  })}
            />
          )}
        </div>
      </Inline>
    );
    return (
      isFieldExist("copiedTo") &&
      isVisible && (
        <NameValuePair
          name={t(
            "requests:requests.configured.fields.copiedTo.pastTenseLabel",
            {
              context: requestQuery.queryType
            }
          )}
          value={value}
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [
    requestQuery.copiedTo,
    requestQuery.isUserOnRequestorTeam,
    requestQuery.queryType,
    queryConfig?.fields,
    user.xRole?.type,
    disabled,
    props.onClickEditCopiedTo,
    t,
    isFieldExist
  ]);

  const renderReminderDate = useCallback(() => {
    const { isVisible, isEditable } = getFieldPermission({
      fields: queryConfig?.fields,
      key: "reminderDate",
      userRoleType: user.xRole?.type,
      isUserOnRequestorTeam: requestQuery.isUserOnRequestorTeam
    });

    return (
      props.showReminderDate &&
      isVisible && (
        <NameValuePair
          name={t("requests:requests.configured.fields.reminderDate.label", {
            context: requestQuery.queryType
          })}
          value={
            <Inline gap="100">
              <div className="request-details-box__reminder-date">
                {dateFormatter(
                  requestQuery.reminderDate,
                  locale,
                  options?.longFormat
                ) ?? "N/A"}
              </div>
              <Inline
                className="request-details-box__reminder-date--actions"
                gap="100"
                alignment="left"
              >
                {!disabled && isEditable && isReminderDateUpcoming && (
                  <Icon
                    name="edit"
                    fillStyle={IconFillStyle.FILLED}
                    size={IconSize.XS}
                    onClick={handleEditReminderDate}
                  />
                )}
                {!disabled &&
                  isEditable &&
                  isReminderDateUpcoming &&
                  requestQuery.reminderDate && (
                    <Icon
                      name="delete"
                      fillStyle={IconFillStyle.FILLED}
                      designStyle={IconDesignStyle.MATERIAL_ICONS}
                      size={IconSize.XS}
                      onClick={handleResetReminderDate}
                    />
                  )}
              </Inline>
              {/* There is a bug in react-date-picker library, if format="dd-MMMM-yy" then month section will become a <select> element */}
              {/* Solution: hide value in datePicker and use div above to display it */}
              {isCalendarOpen && (
                <Box className="request-details-box__date-picker">
                  <_DatePicker
                    value={safeUtcDate(requestQuery.reminderDate)}
                    onChange={date => handleDateChange(date)}
                    format="dd-MMMM-yy"
                    minDate={new Date()}
                    formatShortWeekday={(_, date) => formatDayOfWeek(date)}
                    isOpen={isCalendarOpen}
                    clearIcon={null}
                    nextLabel={
                      <Icon
                        name="navigate_next"
                        fillStyle={IconFillStyle.FILLED}
                        size={IconSize.XS}
                      />
                    }
                    prevLabel={
                      <Icon
                        name="navigate_before"
                        fillStyle={IconFillStyle.FILLED}
                        size={IconSize.XS}
                      />
                    }
                    dayPlaceholder={null}
                    monthPlaceholder={null}
                    yearPlaceholder={null}
                    calendarIcon={null}
                    onCalendarClose={() => {
                      setIsCalendarOpen(false);
                    }}
                  />
                </Box>
              )}
            </Inline>
          }
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [
    disabled,
    handleDateChange,
    handleEditReminderDate,
    handleResetReminderDate,
    isCalendarOpen,
    isReminderDateUpcoming,
    locale,
    options?.longFormat,
    props.showReminderDate,
    queryConfig?.fields,
    requestQuery.isUserOnRequestorTeam,
    requestQuery.queryType,
    requestQuery.reminderDate,
    t,
    user.xRole?.type
  ]);

  const renderFieldTags = useCallback(() => {
    const { isVisible, isEditable } = getFieldPermission({
      fields: queryConfig?.fields,
      key: "tags",
      userRoleType: user.xRole?.type,
      isUserOnRequestorTeam: requestQuery.isUserOnRequestorTeam
    });
    return (
      isFieldExist("tags") &&
      isVisible && (
        <NameValuePair
          className={"request-details-box__tags"}
          name={t("requests:requests.configured.fields.tags.label")}
          value={
            <Stack gap="100" alignment="left" width="fill">
              {renderTags()}
              {!disabled && canEditLabels && isEditable && (
                <span
                  className={`${
                    requestQuery?.tags?.length
                      ? "request-details-box__edit-labels-btn"
                      : "request-details-box__edit-labels-btn--no-tags"
                  }`}
                >
                  <Button
                    iconName="edit"
                    label={t("common:ui.forms.edit.label")}
                    variant={ButtonVariant.TEXT}
                    onClick={props.onClickEditLabels}
                    {...{
                      size: ButtonSize.MINIMAL
                    }}
                  />
                </span>
              )}
            </Stack>
          }
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [
    canEditLabels,
    disabled,
    isFieldExist,
    props.onClickEditLabels,
    queryConfig?.fields,
    renderTags,
    requestQuery.isUserOnRequestorTeam,
    requestQuery?.tags?.length,
    t,
    user.xRole?.type
  ]);

  const renderStatus = useCallback(() => {
    return (
      showStatus && (
        <NameValuePair
          name={t("requests:requests.statusLabel")}
          value={statusPill}
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [showStatus, statusPill, t]);

  const renderMilestone = useCallback(() => {
    return (
      props.milestoneContent && (
        <NameValuePair
          name={t("requests:requests.configured.fields.milestone.label")}
          value={t(props.milestoneContent)}
          nameWidth={nameValuePairNameWidth}
        />
      )
    );
  }, [props.milestoneContent, t]);
  const renderRefNo = useCallback(() => {
    return (
      <NameValuePair
        name={t("requests:requests.refLabel")}
        value={
          <div className="request-details-box__property-value" role="reference">
            {requestQuery.refNo}
          </div>
        }
        nameWidth={nameValuePairNameWidth}
      />
    );
  }, [requestQuery.refNo, t]);
  return (
    <BoxTemplate boxClassName="request-details-box" title={props.title}>
      <Stack gap="200">
        {approvalRejectFlag}
        {renderRefNo()}
        {renderRequiredBy()}
        {renderStatus()}
        {renderReminderDate()}
        {renderWorkflowStep()}
        {renderAssignedTo()}
        {renderLastActor()}
        {renderCopiedTo()}
        {renderMilestone()}
        {renderFieldTags()}
      </Stack>
    </BoxTemplate>
  );
};

RequestDetailsBox.defaultProps = {};

RequestDetailsBox.propTypes = {
  title: PropTypes.string.isRequired,
  showReminderDate: PropTypes.bool,
  hideRequiredByField: PropTypes.bool,
  pillConfig: PropTypes.object,
  milestoneContent: PropTypes.string,
  updateQueryField: PropTypes.func.isRequired,
  requestQuery: PropTypes.shape({
    requiredBy: PropTypes.string,
    workflowStep: PropTypes.string,
    assignedTo: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.string
      })
    }),
    lastActor: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.shape({
        image: PropTypes.string
      })
    }),
    status: PropTypes.string.isRequired,
    description: PropTypes.string,
    query: PropTypes.string,
    queryType: PropTypes.string,
    refNo: PropTypes.number,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    copiedTo: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        avatar: PropTypes.shape({
          image: PropTypes.string
        })
      })
    ),
    reminderDate: PropTypes.string,
    isUserOnRequestorTeam: PropTypes.bool
  }).isRequired,
  queryConfig: PropTypes.any.isRequired,
  canEditLabels: PropTypes.bool,
  onClickEditLabels: PropTypes.func,
  onClickEditCopiedTo: PropTypes.func,
  approvalRejectFlag: PropTypes.element,
  disabled: PropTypes.bool
};
export default RequestDetailsBox;
