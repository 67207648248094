import React from "react";

import { Navigate } from "react-router-dom";

import { routeConstants } from "@constants";

import { useAuthUser } from "@shared/hooks";

import { useDefaultLandingPageLink } from "@app/hooks";
import { AuthUser, PathPermission, PathPermissionRule } from "@app/types";

type Props = {
  children: JSX.Element;
  roles?: string[];
  permission?: PathPermissionRule;
};

function checkUserAccess(user: AuthUser, permission: PathPermissionRule) {
  const doesUserHavePermission = ({ resource, accessLevel }: PathPermission) =>
    user.checkAccess(resource, accessLevel);

  if (permission.anyOf) {
    return permission.anyOf.some(doesUserHavePermission);
  }
  if (permission.allOf) {
    return permission.allOf.every(doesUserHavePermission);
  }

  return false;
}

function RequireAuth(props: Readonly<Props>) {
  const { children, permission } = props;
  const { user } = useAuthUser();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const isAuthenticated = user.isLoggedIn;
  const isFirstAccess = !localStorage.getItem("firstAccess");
  if (isFirstAccess) {
    localStorage.setItem("firstAccess", "false");
  }

  const isAuthorized = !permission || checkUserAccess(user, permission);
  if (!isAuthenticated) {
    const stateObject = isFirstAccess
      ? {}
      : {
          target: `${document.location.pathname}${document.location.search}${document.location.hash}`,
          isRedirected: true
        };

    return document.location.pathname === routeConstants.notFound ? (
      <Navigate to={routeConstants.login} />
    ) : (
      <Navigate to={routeConstants.login} state={stateObject} />
    );
  }
  if (isAuthenticated && !isAuthorized) {
    return <Navigate to={getDefaultLandingPageLink()} />;
  }
  return children;
}

export default RequireAuth;
