import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Title from "@atoms/Title/Title.jsx";

import Loading from "@components/molecules/Loading";

import "./ModalTemplate.scss";

const ModalTemplate = props => {
  const { boxClassName, title, errorMessage, onClose, footer, isLoading } =
    props;
  const { t } = useTranslation();

  const handleOnClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      props.onClose();
    },
    [props]
  );

  return (
    <div className={`modal-template ${boxClassName}`}>
      <div className="modal-template__header">
        <div className="modal-template__heading">
          <Title className="modal-template__title" title={title} />
        </div>
        {onClose && (
          <div className="modal-template__close" onClick={handleOnClick}>
            <i className="material-icons">close</i>
          </div>
        )}
      </div>
      <div className={`modal-template__content ${boxClassName}__content`}>
        {errorMessage && (
          <span className="modal-template__content__error">
            {t(errorMessage)}
          </span>
        )}
        {isLoading ? <Loading message={t("common:loading")} /> : props.content}
      </div>
      {footer && <div className="modal-template__footer">{footer}</div>}
    </div>
  );
};

ModalTemplate.defaultProps = {};

ModalTemplate.propTypes = {
  boxClassName: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  content: PropTypes.node,
  footer: PropTypes.element,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool
};

export default ModalTemplate;
