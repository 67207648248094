import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { routeConstants } from "@constants/routeConstants";

import {
  constructRoleForBE,
  getIsEntityRestricted
} from "@shared/helpers/roleHelper";
import { useUpdateRoleMutation } from "@shared/services/roleService";

import { useGoBack } from "@app/hooks";
import {
  RTKError,
  ResourcePermission,
  RoleFormData,
  RoleGet,
  RoleMode
} from "@app/types";

import RoleFields from "@components/molecules/RoleFields";
import FormPageTemplate from "@components/templates/FormPageTemplate";

type Props = {
  resourcesPermissions?: ResourcePermission[];
  role?: RoleGet;
  error?: RTKError;
  isLoading?: boolean;
};

const UpdateRoleForm = (props: Props) => {
  const {
    resourcesPermissions,
    role,
    isLoading: isPropLoading,
    error: propError
  } = props;
  const [updateRole, { isSuccess, isLoading, error }] = useUpdateRoleMutation();
  const { t } = useTranslation();
  const goBack = useGoBack();

  useEffect(() => {
    if (isSuccess) {
      goBack();
    }
  }, [isSuccess, goBack]);

  const yupSchema = yup.object({
    name: yup.string().required(t("roles:ui.name.requiredMessage"))
  });

  const handleFormSubmit = (data: RoleFormData) => {
    updateRole(
      constructRoleForBE({ data, existingRole: role, resourcesPermissions })
    );
  };

  const entityRestricted = useMemo(
    () =>
      getIsEntityRestricted({
        role,
        resourcesPermissions
      }),
    [resourcesPermissions, role]
  );

  return (
    <FormPageTemplate
      title={t("roles:ui.updateRole.title", {
        roleName: role?.name
      })}
      breadcrumbs={[
        {
          linkTo: routeConstants.admin.manageUserRoles,
          label: t("admin:ui.manageUserRoles.title")
        }
      ]}
      sticky
      form={{
        contents: (
          <RoleFields
            isLoading={isPropLoading}
            defaultValue={{
              ...role,
              entityRestricted: entityRestricted
            }}
            resourcesPermissions={resourcesPermissions}
            mode={RoleMode.EDIT}
          />
        ),
        yupSchema,
        submitLabel: t("common:ui.forms.save.label"),
        handleSubmit: handleFormSubmit,
        handleCancel: () => goBack(),
        disabled: isLoading || isPropLoading
      }}
      isLoading={isLoading || isPropLoading}
      other={{ error: error ?? propError }}
      renderRawForm
    />
  );
};

UpdateRoleForm.defaultProps = {
  resourcesPermissions: []
};

export default UpdateRoleForm;
