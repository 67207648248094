import React, { createContext, useRef } from "react";

export const WorkflowCanvasContext = createContext({});

export const WorkflowCanvasProvider = props => {
  const storeRef = useRef({});

  if (!storeRef.current) {
    storeRef.current = {};
  }

  return (
    <WorkflowCanvasContext.Provider value={storeRef.current}>
      {props.children}
    </WorkflowCanvasContext.Provider>
  );
};
