import React from "react";

import { useTranslation } from "react-i18next";

import { Placeholder } from "@atoms/Placeholder";

import TodoListCard from "@components/molecules/TodoListCard";
import TodoListFilter from "@components/molecules/TodoListFilter";

import SidePanelContentTemplate from "../SidePanelContentTemplate/SidePanelContentTemplate";
import "./ClientTodoListTemplate.scss";

const ClientTodoListTemplate = props => {
  const {
    queries,
    title,
    filterItems,
    onFiltersSelected,
    enableFilter,
    pillConfig,
    actionItemTypes,
    onClose
  } = props;

  const { t } = useTranslation();
  const renderTodoListCard = query => {
    return (
      <TodoListCard
        key={query.id}
        query={query}
        pillConfig={pillConfig}
        actionItemTypes={actionItemTypes}
      />
    );
  };

  return (
    <SidePanelContentTemplate
      title={title}
      titleStyle={"styled"}
      filters={
        enableFilter && (
          <div className="client-todoList-filter">
            <TodoListFilter
              filterItems={filterItems}
              onFiltersSelected={onFiltersSelected}
            />
          </div>
        )
      }
      content={
        queries?.length > 0 ? (
          queries.map(renderTodoListCard)
        ) : (
          <Placeholder
            content={t("requests:requests.ui.clientTodoList.noToDo")}
          />
        )
      }
      onClose={onClose}
    />
  );
};

export default ClientTodoListTemplate;
