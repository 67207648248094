import React from "react";

import PropTypes from "prop-types";

import Title from "@components/atoms/Title/Title";

import "./SidePanelContentTemplate.scss";

const SidePanelContentTemplate = ({
  title,
  titleStyle,
  subtitle,
  filters,
  content,
  onClose,
  sidePanelContentClassName
}) => {
  return (
    <div className={`side-panel-template ${sidePanelContentClassName}`}>
      {onClose && (
        <div className="side-panel-template__close" onClick={onClose}>
          <i className="material-icons">close</i>
        </div>
      )}
      {titleStyle === "styled" ? (
        <Title title={title} />
      ) : (
        <div className={"side-panel-template__title"}>{title}</div>
      )}
      {subtitle && (
        <div className={"side-panel-template__subtitle"}>{subtitle}</div>
      )}
      {filters && (
        <div className={"side-panel-template__filter"}>{filters}</div>
      )}
      <div className={"side-panel-template__content"}>{content ?? ""}</div>
    </div>
  );
};

SidePanelContentTemplate.defaultProps = {
  titleStyle: "text",
  onClose: null
};

SidePanelContentTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  filters: PropTypes.node,
  content: PropTypes.node,
  titleStyle: PropTypes.oneOf(["text", "styled"]),
  onClose: PropTypes.func,
  sidePanelContentClassName: PropTypes.string
};

export default SidePanelContentTemplate;
