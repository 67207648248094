import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";
import { useAuthUser, useToasts, useUpdateUserStatus } from "@shared/hooks";
import {
  useDeactivateUserMutation,
  useGetHostUsersQuery,
  useUploadBulkHostUsersMutation
} from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";
import { AccessLevel, ResourceName } from "@app/types";

import { Button } from "@atoms/Button";

import { MessageBox } from "@molecules/MessageBox";

import DropdownPagination from "@components/molecules/DropdownPagination";
import UploadUsers from "@components/molecules/UploadUsers";
import SearchTextInput from "@components/organisms/SearchTextInput";
import UsersDataTable from "@components/organisms/UserDataTable";
import PageTemplate from "@components/templates/PageTemplate";

import "./ManageTeamUsers.scss";

const routes = {
  addUser: "/admin/add-user"
};

const ManageTeamUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: hostUsers = [], error } = useGetHostUsersQuery(null);
  const [filter, setFilter] = useState({ string: "", users: [] });
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    countPerPage: systemConstants.pagination.itemCountPerPage,
    pageCount: 1,
    currentPage: "Page 1",
    currentPageIndex: 0,
    pages: []
  });
  const [deactivateUser] = useDeactivateUserMutation();
  const { activateUser } = useUpdateUserStatus();
  const { isKeywordInclude } = utilities;
  const [errorState, setErrorState] = useState("");
  const [
    bulkUpdateHostUsers,
    {
      isSuccess: isUploadSuccess,
      data,
      error: uploadError,
      isLoading: uploading
    }
  ] = useUploadBulkHostUsersMutation();

  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const { user } = useAuthUser();
  const { showBulkUpdateSuccess } = useToasts();

  useEffect(() => {
    if (hostUsers.length) {
      setFilter(prev => ({ ...prev, users: hostUsers }));
    }
  }, [hostUsers]);

  useEffect(() => {
    if (filter.string) {
      utilities.createPagination({
        list: filter.users,
        setPagination
      });
    } else {
      utilities.createPagination({
        list: hostUsers,
        setPagination
      });
    }
  }, [filter.string, filter.users, hostUsers]);

  useEffect(() => {
    if (uploading) {
      setIsUploadDisabled(true);
      return;
    }
    if (isUploadSuccess) {
      setIsUploadDisabled(false);
      const { created, updated } = data;
      showBulkUpdateSuccess({
        created,
        updated
      });
      return;
    }
    if (uploadError) {
      setIsUploadDisabled(false);
      if (uploadError?.data?.key === "Invalid Data") {
        const errors = uploadError?.data?.message ?? [];
        const errorMessages = errors.map(
          error =>
            `${t("common:ui.label.error.bulkUser.row.message")} ${error.row}, ${t(error.message)}`
        );
        setErrorState(
          `${t("common:ui.label.error.bulkUser.error")}\n ${errorMessages.join("\n")}`
        );
      } else {
        setErrorState(uploadError.data?.message);
      }
    }
  }, [isUploadSuccess, uploadError, data, showBulkUpdateSuccess, uploading, t]);

  useEffect(() => {
    if (pagination.pages.length) {
      setUsers(pagination.pages[pagination.currentPageIndex].data);
    } else {
      setUsers([]);
    }
  }, [pagination]);

  const handleAddUser = () => {
    if (!user.checkAccess(ResourceName.HOST_USERS, AccessLevel.CREATE)) {
      return;
    }
    navigate(routes.addUser);
  };

  const handleUpdateUser = hostUser => {
    if (!user.checkAccess(ResourceName.HOST_USERS, AccessLevel.UPDATE)) {
      return;
    }
    navigate(`/admin/users/${hostUser.id}/edit`);
  };

  const handleFilterChange = searchInput => {
    if (!searchInput) {
      setFilter({ ...filter, string: "", users: hostUsers });
    } else {
      const filteredUsers = hostUsers.filter(
        user =>
          isKeywordInclude(user.name, searchInput) ||
          (user.email && isKeywordInclude(user.email, searchInput))
      );
      setFilter({
        ...filter,
        string: searchInput,
        users: filteredUsers
      });
    }
  };

  const headerActions = () => {
    const infoMessage = (() => {
      if (!user.checkAccess(ResourceName.CLIENT_USERS, AccessLevel.READ)) {
        return <></>;
      }
      return (
        <MessageBox
          message={t("common:ui.manageUsers.manageClientUsersInfo")}
        />
      );
    })();

    if (!user.checkAccess(ResourceName.HOST_USERS, AccessLevel.CREATE)) {
      return infoMessage;
    }

    return (
      <>
        {infoMessage}
        <Button
          label={t("common:ui.manageUsers.addLabel", { context: "HOST" })}
          iconName="add"
          onClick={handleAddUser}
        />
      </>
    );
  };

  const primaryContent = () => {
    if (!users.length) {
      return <></>;
    }
    return (
      <>
        <div className="team-user__table">
          <UsersDataTable
            users={users}
            onUpdateUser={handleUpdateUser}
            onActivateUser={activateUser}
            onDeactivateUser={deactivateUser}
            canUpdate={user.checkAccess(
              ResourceName.HOST_USERS,
              AccessLevel.UPDATE
            )}
            canDelete={user.checkAccess(
              ResourceName.HOST_USERS,
              AccessLevel.DELETE
            )}
            type="HOST"
          />
        </div>
        <DropdownPagination
          pagination={pagination}
          setPagination={setPagination}
        />
      </>
    );
  };

  const secondaryContent = () => {
    return (
      <UploadUsers
        bulkUpdateUsers={bulkUpdateHostUsers}
        handleErrorState={setErrorState}
        user={user}
        isUploading={uploading}
        isUploadDisabled={isUploadDisabled}
        downloadUrl={"users/download"}
      />
    );
  };

  return (
    <PageTemplate
      header={{
        title: t("common:ui.manageUsers.title", { context: "HOST" }),
        actions: headerActions(),
        alwaysShowBreadcrumbs: true
      }}
      body={{
        header: (
          <SearchTextInput
            label={t("common:ui.manageUsers.searchLabel")}
            handleChange={handleFilterChange}
          />
        ),
        primary: primaryContent(),
        secondary: secondaryContent()
      }}
      other={{
        error: getErrorMessage(error, t) ?? errorState,
        whiteSpaceStyle: "pre-line"
      }}
    />
  );
};

export default ManageTeamUsers;
