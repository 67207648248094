import React, { LegacyRef, forwardRef, useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";

import "../Fermions.scss";
import {
  FermionProps,
  getClassArray,
  getFermionClasses,
  splitProps
} from "../fermionsConfig";

interface StackProps extends FermionProps {}

export const Stack = forwardRef(
  (props: StackProps, ref?: LegacyRef<HTMLDivElement> | undefined) => {
    const { className, children } = props;
    const { divProps, classProps } = splitProps(props);

    const classArray = useMemo(() => getClassArray(className), [className]);

    return (
      <div
        className={classNames([
          ...classArray,
          "fermion",
          "stack",
          ...getFermionClasses(classProps)
        ])}
        {...divProps}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
