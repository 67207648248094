import React, { useEffect, useMemo } from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AITFormOptions,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import Icon from "@atoms/Icon/Icon.tsx";

import BoxTemplate from "@templates/BoxTemplate/BoxTemplate.jsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

import { ActionNode } from "./WorkflowCanvas/ActionNode.tsx";
import { StepNode } from "./WorkflowCanvas/StepNode.tsx";
import WorkflowCanvas from "./WorkflowCanvas/WorkflowCanvas.tsx";
import { WorkflowCanvasProvider } from "./WorkflowCanvas/WorkflowCanvasContext.tsx";

interface ActionItemTypeFormWorkflowProps {
  actionItemType: ActionItemType;
  formOptions: AITFormOptions;
  crudFormMode: CrudFormMode;
}

const ActionItemTypeFormWorkflow = (props: ActionItemTypeFormWorkflowProps) => {
  const { actionItemType, crudFormMode = CrudFormMode.READ } = props;
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const workflow = useMemo(() => {
    return actionItemType?.configuration?.workflow;
  }, [actionItemType?.configuration?.workflow]);

  useEffect(() => {
    if (workflow) {
      setValue("workflow", workflow);
    }
  }, [setValue, workflow]);

  if (!workflow) {
    return <></>;
  }

  const Legend = () => {
    return (
      <Stack alignment="top-right" gap="150">
        <Inline
          gap="050"
          alignment="center"
          className="ait-form-workflow__legend-title"
        >
          <Icon name="info" size="s" />
        </Inline>
        <Inline
          gap="100"
          alignment="center"
          className="ait-form-workflow__legend-key"
        >
          <StepNode
            node={{
              id: "1",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.step.label");
              }
            }}
            mode="legend"
          />
          <ActionNode
            node={{
              id: "2",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.actionWithRules.label");
              }
            }}
            mode="legend"
          />
          <ActionNode
            node={{
              id: "3",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.actionWithoutRules.label");
              }
            }}
            mode="legend"
            withRules={false}
          />
        </Inline>
      </Stack>
    );
  };

  return (
    <BoxTemplate
      title={t("admin:ui.requestTypes.workflow.title")}
      subtext={t("admin:ui.requestTypes.workflow.subtitle")}
      action={<Legend></Legend>}
    >
      <Stack
        gap="300"
        alignment="left"
        padding="200"
        className="ait-form-workflow__container"
      >
        <WorkflowCanvasProvider>
          <WorkflowCanvas
            workflow={workflow}
            crudFormMode={crudFormMode}
            actionItemType={actionItemType}
            showStart={true}
          />
          {/*[CrudFormMode.UPDATE].includes(crudFormMode) ? (
            <Button
              label={t("admin:ui.requestTypes.workflow.configure")}
              iconName="chevron_right"
              iconOnRight={true}
            />
          ) : (
            <></>
          )*/}
        </WorkflowCanvasProvider>
      </Stack>
    </BoxTemplate>
  );
};

export default ActionItemTypeFormWorkflow;
