import { TFunction } from "i18next";
import { pick } from "lodash";
import * as yup from "yup";

import { formatDateOnly } from "@shared/helpers/utilities.ts";

import {
  AITFormOptions,
  AITFormatFormConfig,
  AITFormatFormConfigRule,
  ActionItemType,
  ActionItemTypeUserOption
} from "@app/types/ActionItemType.ts";

export const displayName = (actionItemType: ActionItemType, t: TFunction) => {
  if (actionItemType?.configuration?.useCustomName) {
    return actionItemType?.name;
  }

  return t("requests:requests.configured.name", {
    context: actionItemType?.configuration?.key
  });
};

export const normaliseAvailableTo = (
  availableTo: Array<ActionItemTypeUserOption | undefined>
) => {
  return availableTo?.flat()?.filter(c => c) as string[];
};

export const permissionFormValuesToPayload = (permissionsFormValues: {
  [actionKey: string]: { [availableTo: string]: boolean };
}) => {
  const permissions: {
    [actionKey: string]: {
      key: string;
      availableTo: string[];
    };
  } = {};

  for (const actionKey in permissionsFormValues) {
    permissions[actionKey] ??= {
      key: actionKey,
      availableTo: []
    };
    for (const availableToOption in permissionsFormValues[actionKey]) {
      if (permissionsFormValues[actionKey][availableToOption]) {
        permissions[actionKey].availableTo.push(availableToOption);
      }
    }
  }

  // If ALL remove everything else
  for (const actionKey in permissions) {
    if (permissions[actionKey].availableTo.includes("ALL")) {
      permissions[actionKey].availableTo = ["ALL"];
    }
  }

  return Object.values(permissions);
};

interface FieldRuleFormValues {
  defaultValue?: unknown;
  length?: number;
  count?: number;
  extension?: string[];
}
interface FieldFormValues {
  active?: boolean;
  required?: boolean;
  type: string;
  rules?: FieldRuleFormValues;
  activeRules?: string[];
  order: number;
  availableTo?: { value?: string };
  creatableBy?: { value?: string };
  updatableBy: { value: string }[];
}

const parseRuleOptions = (
  activeRuleSet: Set<string>,
  rule?: FieldRuleFormValues
) => {
  const activeRules = Object.entries(rule ?? {})
    .filter(([key]) => !["defaultValue"].includes(key))
    .filter(([key]) => activeRuleSet.has(key));
  const rules = [...activeRules];
  if (!rules?.length) {
    return undefined;
  }
  return rules.map(([key, value]) => {
    switch (key) {
      case "extension": {
        const extensions = value as { value: string }[];
        return {
          key,
          value: extensions.map(({ value: ext }) => ext)
        };
      }
      default: {
        return {
          key,
          value
        };
      }
    }
  });
};

export const fieldFormValuesToPayload = (
  fieldFormValues: {
    [key: string]: FieldFormValues;
  },
  validFieldKeys?: string[]
) => {
  // default value is dependant on the field type
  const getDefaultValue = (
    fieldType: string,
    defaultValue: unknown,
    activeRules: Set<string>
  ) => {
    if (!defaultValue || !activeRules.has("defaultValue")) {
      return undefined;
    }
    if (fieldType === "milestone") {
      const defaultMilestone = defaultValue as { value: string };
      return defaultMilestone.value;
    }
    if (fieldType === "date") {
      const defaultDate = defaultValue as string;
      return formatDateOnly(defaultDate);
    }

    return defaultValue;
  };
  const convertGroupValue = (value: string) => {
    if (!value) {
      return null;
    }
    if (value === "ALL") {
      return "ALL";
    } else {
      return [value].flat();
    }
  };
  const fields = Object.entries(fieldFormValues)
    .filter(([, value]) => value?.active)
    .filter(([key]) => !validFieldKeys || validFieldKeys.includes(key))
    .toSorted(([, a], [, b]) => a.order - b.order)
    .map(([key, fieldFormValue]) => {
      const activeRules = new Set(fieldFormValue.activeRules ?? []);
      const payload = {
        key,
        required: fieldFormValue.required,
        rules: parseRuleOptions(activeRules, fieldFormValue.rules),
        availableTo: convertGroupValue(fieldFormValue?.availableTo?.value),
        creatableBy: convertGroupValue(fieldFormValue?.creatableBy?.value),
        updatableBy: fieldFormValue?.updatableBy?.map(({ value }) => value),
        defaultValue: getDefaultValue(
          fieldFormValue.type,
          fieldFormValue.rules?.defaultValue,
          activeRules
        )
      };
      return payload;
    });
  return fields;
};

const requiredMessageForLabel = (keyForLabel: string, t: TFunction) =>
  t("common:ui.forms.required.message", {
    label: t(keyForLabel)
  });

const fieldValidation = (fieldConfig: AITFormatFormConfig, t: TFunction) => {
  const ruleValidation = (
    rule: AITFormatFormConfigRule,
    activeRules: string[]
  ) => {
    const label = t(`admin:ui.requestTypes.fieldRule.${rule.key}.label`);

    if (rule.type === "number") {
      let v = yup
        .number()
        .transform((value, originalValue) => {
          if (originalValue === "") {
            return undefined;
          }
          return value;
        })
        .integer(t("common:ui.forms.validation.number.message", { label }))
        .nullable()
        .optional()
        .typeError(t("common:ui.forms.validation.number.message", { label }));
      if (rule.min !== undefined) {
        const message = t("common:ui.forms.validation.min.message", {
          min: rule.min,
          label
        });
        v = v.min(rule.min, message);
      }
      if (rule.max !== undefined) {
        const message = t("common:ui.forms.validation.max.message", {
          max: rule.max,
          label
        });
        v = v.max(rule.max, message);
      }
      return v;
    }
    if (rule.type === "milestone") {
      return yup.object().nullable();
    }
    if (rule.type === "fileExtension") {
      return yup
        .array()
        .of(yup.object({ value: yup.string() }))
        .nullable();
    }
    if (rule.type === "string" && rule.key === "defaultValue") {
      const v = yup
        .string()
        .nullable()
        .test({
          name: "maxLength",
          message: t(
            "admin:ui.requestTypes.fieldRule.defaultValue.validation.maxLength.message",
            {
              maxLengthLabel: t(`admin:ui.requestTypes.fieldRule.length.label`),
              label
            }
          ),
          test: function (v) {
            if (!activeRules.includes("length")) {
              return true;
            }
            const maybeMaxLength = this.parent?.length;
            if (maybeMaxLength > 0) {
              return v.length <= maybeMaxLength;
            }
            return true;
          }
        });
      return v;
    }
    return yup.string().nullable();
  };

  return yup.object({
    active: yup.boolean(),
    activeRules: yup.array().of(yup.string()),
    availableTo: yup.object().when(["active"], ([isActive], schema) => {
      if (!isActive) {
        return schema;
      }
      return schema.required(
        requiredMessageForLabel(t(`admin:ui.requestTypes.availableTo.label`), t)
      );
    }),
    updatableBy: yup.array(),
    rules: yup
      .object()
      .when(["active", "activeRules"], ([isActive, activeRules], schema) => {
        if (!isActive) {
          return schema;
        }

        const ruleSchemas: yup.ObjectShape = {};
        fieldConfig.rules.forEach(rule => {
          ruleSchemas[rule.key] = ruleValidation(rule, activeRules);
        });

        return schema.shape({
          ...pick(ruleSchemas, activeRules)
        });
      })
  });
};

const fieldsSchema = (formConfig: AITFormatFormConfig[], t: TFunction) => {
  const fieldSchemas: yup.ObjectShape = {};

  formConfig.forEach(fieldConfig => {
    fieldSchemas[fieldConfig.key] = fieldValidation(fieldConfig, t);
  });

  return fieldSchemas;
};

export const createAITFormSchema = (
  formOptions: AITFormOptions,
  t: TFunction
) => {
  return yup
    .object({
      format: yup
        .object()
        .required(
          requiredMessageForLabel("admin:ui.requestTypes.format.label", t)
        ),
      name: yup
        .string()
        .required(
          requiredMessageForLabel("admin:ui.requestTypes.name.label", t)
        ),
      description: yup.string().nullable().optional(),
      engagementType: yup
        .object()
        .required(
          requiredMessageForLabel(
            "admin:ui.requestTypes.engagementTypes.label",
            t
          )
        ),
      projectYear: yup.object(),
      createFrom: yup
        .array()
        .of(yup.object())
        .min(1)
        .required(
          requiredMessageForLabel("admin:ui.requestTypes.createFrom.label", t)
        ),
      fields: yup
        .object()
        .required()
        .when("format", ([format], schema) => {
          const formsConfig = formOptions?.formats.find(
            f => f.type === format?.value
          )?.formsConfig;
          if (!formsConfig) {
            return schema;
          }
          return schema.shape(fieldsSchema(formsConfig, t));
        }),
      permissions: yup.object().required()
    })
    .required();
};
