import React, { useMemo } from "react";

import PropTypes from "prop-types";

import { useTooltip } from "@shared/hooks/useTooltip";

import { Context, PageTitleAccentType } from "@ions-interface";
import ions from "@ions";

import { classNames } from "@app/helpers/componentHelpers";

import { Inline, Stack } from "@fermions";

import OTTooltip from "@components/atoms/OTTooltip";

import "./Title.scss";

export const Title = props => {
  const {
    title,
    subtitle,
    actions,
    icon,
    subtitlePosition,
    subtitleActionIcon,
    invert,
    hasTitleTooltip,
    context = Context.LIGHT,
    className
  } = props;
  const {
    showTooltip: showSubtitleTooltip,
    elementProps: subtitleElementProps
  } = useTooltip();
  const { showTooltip: showTitleTooltip, elementProps: titleElementProps } =
    useTooltip();

  const renderTitleAndSubtitle = () => {
    if (subtitle && subtitlePosition === "newline") {
      return (
        <div className="ot-title__wrapper">
          <div className="ot-title__title__wrapper">
            {title && (
              <div className="ot-title__title__wrapper">
                <h1
                  className={classNames([
                    "ot-title__title",
                    `context-${context}`
                  ])}
                  {...titleElementProps}
                >
                  {title}
                  {hasTitleTooltip && (
                    <OTTooltip visible={showTitleTooltip} content={title} />
                  )}
                </h1>
              </div>
            )}
            {icon && <h1 className="ot-title__icon">{icon}</h1>}
          </div>
          {subtitle && (
            <h3 className="ot-title__subtitle__wrapper">
              <span className="ot-title__subtitle" {...subtitleElementProps}>
                {subtitle}
              </span>
              {subtitleActionIcon}
              <OTTooltip visible={showSubtitleTooltip} content={subtitle} />
            </h3>
          )}
        </div>
      );
    }

    return (
      <>
        {title && (
          <div className="ot-title__title__wrapper">
            <h1
              className={classNames([
                "ot-title__title",
                invert ? "invert" : "",
                `context-${context}`
              ])}
              {...titleElementProps}
            >
              {title}
              {hasTitleTooltip && (
                <OTTooltip visible={showTitleTooltip} content={title} />
              )}
            </h1>
          </div>
        )}
        {icon && <h1 className="ot-title__icon">{icon}</h1>}
        {title && subtitle && <div className="ot-title__divider">{"|"}</div>}
        {subtitle && (
          <h1
            className={classNames(["ot-title__subtitle", `context-${context}`])}
          >
            {subtitle}
          </h1>
        )}
      </>
    );
  };

  const MaybeDecorativeHorizontalBar = useMemo(() => {
    if (
      ions.components?.atoms?.page_title?.heading?.accent_type ===
        PageTitleAccentType.LINE &&
      (title || subtitle)
    ) {
      return (
        <div className="ot-title__horizontal-bar">
          <span className="bar"></span>
        </div>
      );
    }
    return <span className="ot-title__custom-blank" />;
  }, [subtitle, title]);

  return (
    <Inline gap="200" style={{ justifyContent: "space-between" }}>
      <Stack
        className={classNames([
          className ?? "",
          "ot-title",
          `ot-title--subtitle-${subtitlePosition}`,
          `ot-title--context-${context}`
        ])}
      >
        <Inline gap="200">{renderTitleAndSubtitle()}</Inline>
        {MaybeDecorativeHorizontalBar}
      </Stack>
      {actions && <div className={`ot-title__actions`}>{actions}</div>}
    </Inline>
  );
};

Title.defaultProps = {
  subtitlePosition: "newline",
  hasTitleTooltip: false
};

Title.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  actions: PropTypes.node,
  icon: PropTypes.node,
  subtitlePosition: PropTypes.oneOf(["inline", "newline"]),
  invert: PropTypes.bool,
  subtitleActionIcon: PropTypes.node,
  hasTitleTooltip: PropTypes.bool,
  context: PropTypes.oneOf(["light", "dark"]),
  className: PropTypes.string
};

export default Title;
