export const routeConstants = {
  login: "/login",
  logout: "/logout",
  notFound: "/not-found",

  hostLandingPage: "/manage-client-engagements",
  clientUserLandingPage: "/project-summary",

  dashboard: "/",
  addDashboardArticle: "/dashboard/add-article",
  editDashboardArticle: "/dashboard/edit-article",
  manageClients: "/admin/manage-clients",
  addClient: "/admin/add-client",
  updateClient: "/update-client",

  clients: "/clients",
  clientDashboard: "/client-dashboard",

  createProject: "/create-project",

  manageClientEntities: "/manage-client-entities",
  manageClientTags: "/manage-client-tags",
  manageClientUsers: "/manage-client-users",
  addClientUser: "/add-client-user",
  addClientTag: "/add-client-tag",
  updateClientTag: "/update-client-tag",
  updateClientUser: "/update-client-user",
  addBulkUsers: "/add-bulk-users",
  dataRepository: "/data-repository",
  permanentFiles: "/permanent-files",
  finalPackage: "/final-package",
  uploadInteractiveReport: "/upload-interactive-report",
  aiReporting: "/ai-reporting",

  manageUsers: "/admin/manage-users",
  updateHostUser: "/update-team-user",

  manageClientEngagements: "/manage-client-engagements",
  addClientEngagement: "/add-client-engagement",
  updateClientEngagement: "/update-client-engagement",

  manageClientEngagementProjects: "/manage-client-engagement-projects",
  addClientEngagementProject: "/add-client-engagement-project",
  updateClientEngagementProject: "/update-client-engagement-project",
  manageClientDocuments: "/manage-client-documents",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword",
  signup: "/signup",
  accountNotFound: "/accountNotFound",
  engagements: "/engagements",
  engagement: {
    dashboard: "/engagement",
    users: "/engagement-users",
    addClientEngagement: "/add-client-engagement",
    updateClientEngagementProject: "/update-client-engagement-project"
  },
  projects: "/projects",
  project: {
    dashboard: "/project",
    summary: "/project-summary",
    risks: "/project/risks",
    documents: "/project/documents",
    addOrUpdateProjectTag: "/project/tags/add-or-update",
    queries: "/project/queries",
    addOrUpdateQuery: "/project/queries/add-or-update",
    ocrRequests: "/project/optical-character-recognition-requests",
    createOrUpdateOCRRequest:
      "/project/optical-character-recognition-requests/create-or-update",
    ocrRequestViewDetails:
      "/project/optical-character-recognition-requests/view-details"
  },
  request: {
    requests: "/requests",
    addOrUpdateQuery: "/project/queries/add-or-update",
    queryDetails: "/requests/request-details"
  },
  admin: {
    addUser: "/add-user",
    manageClientTags: "/manage-client-tags",
    manageUserRoles: "/admin/manage-user-roles",
    manageRequestTypes: "/admin/manage-request-types",
    addRequestType: "/admin/create-request-type",
    addRole: "/admin/create-role",
    addClientTag: "/add-client-tag",
    updateClientTag: "/update-client-tag",
    manageClientEntities: "/manage-client-entities",
    addBulkUsers: "/add-bulk-users",
    addClient: "/add-client",
    addTag: "/add-tag",
    updateClient: "/update-client",
    manageAudits: "/manage-audits",
    globalTags: "/global-tags",
    manageClientUsers: "/admin/clients/{{clientId}}/users"
  },
  profile: "/pages/host/profile",
  manageAudits: "/manage-audits",
  editDocument: "/documents/edit",
  dataExtraction: "/data-extraction",
  manageNews: "/manage-news",
  insightsBoard: "/insights-board"
};
