import React, { useCallback, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import {
  useAuthUser,
  useGetEngagementTypesQuery,
  useUrlHash
} from "@shared/hooks";
import {
  useAddProjectMutation,
  useGetProjectManagersQuery
} from "@shared/services/projectService";
import {
  useGetAllProjectTemplateQuery,
  useGetProjectTemplateByIdQuery
} from "@shared/services/projectTemplateService";

import {
  getAddProjectFormFieldsSchema,
  getProjectObjectFromProjectFormData,
  getProjectTemplateDataFromProjectFormData
} from "@app/helpers/projectForm";
import { useGoBack } from "@app/hooks";
import { useProjectFormConfig } from "@app/hooks/useProjectFormConfig";

import { Stack } from "@fermions";

import { Button, ButtonVariant } from "@atoms/Button";

import ProjectForm from "@components/molecules/ProjectForm/ProjectForm";
import ProjectTemplatePanel from "@components/organisms/ProjectTemplatePanel";
import FormPageTemplate from "@components/templates/FormPageTemplate";

import "./AddClientProjectForm.scss";

const AddClientProjectForm = ({ isLoading }) => {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { urlHashDetail, updateUrlHash } = useUrlHash();
  const [selectedTemplateId, setSelectedTemplateId] = useState("blank");
  const [defaultValue, setDefaultValue] = useState({});

  const { data: allTemplates } = useGetAllProjectTemplateQuery();
  const { data: selectedProjectTemplate } = useGetProjectTemplateByIdQuery(
    { projectTemplateId: selectedTemplateId },
    {
      skip: selectedTemplateId === "blank",
      refetchOnMountOrArgChange: true
    }
  );

  const {
    entitiesEnabled,
    projectManagerEnabled,
    projectYearEnabled,
    startDateLabel,
    endDateLabel,
    projectQueriesEnabled
  } = useProjectFormConfig({
    engagementTypeConfig: defaultValue.engagementType?.configuration,
    t,
    selectedProjectTemplate
  });

  const { data: projectManagers } = useGetProjectManagersQuery(
    { hostId: user?.hostId },
    { skip: !projectManagerEnabled || !user?.hostId }
  );
  const goBack = useGoBack();

  const [
    addProject,
    { data: addedProject, error, isError, isLoading: isSubmitting, isSuccess }
  ] = useAddProjectMutation();
  const { data: engagementTypes } = useGetEngagementTypesQuery();

  const yupSchema = yup.object({
    engagementType: yup
      .object()
      .shape({ value: yup.object().shape({ id: yup.number().required() }) })
      .required(
        t("common:ui.forms.required.message", {
          label: t("stringFormat.propercase", {
            key: "common:ui.engagementType.displayName"
          })
        })
      ),
    client: yup.object().required(
      t("common:ui.forms.required.message", {
        label: t("stringFormat.propercase", {
          key: "common:ui.engagementType.displayName"
        })
      })
    ),
    ...getAddProjectFormFieldsSchema(t, projectQueriesEnabled)
  });

  const handleEngagementTypeChange = useCallback(engagementType => {
    setDefaultValue(prev => ({
      ...prev,
      engagementType
    }));
  }, []);

  useEffect(() => {
    const urlHashTemplateId = urlHashDetail.get("templateId");
    if (urlHashTemplateId) {
      setSelectedTemplateId(
        urlHashTemplateId === "blank" ? "blank" : Number(urlHashTemplateId)
      );
    }
  }, [urlHashDetail]);

  useEffect(() => {
    if (!allTemplates || !engagementTypes) {
      return {};
    }
    const selectedTemplate = allTemplates.find(
      t => t.id === selectedTemplateId
    );

    if (!selectedTemplate) {
      setDefaultValue({});
      return;
    }
    const engagementType = engagementTypes.find(
      et => et.id === selectedTemplate.engagementType.id
    );

    setDefaultValue({
      engagementType,
      duration: selectedTemplate.duration
    });
  }, [selectedTemplateId, allTemplates, engagementTypes]);

  const handleTemplateClick = useCallback(
    templateId => {
      setSelectedTemplateId(templateId);

      const urlTemplateId = urlHashDetail.get("templateId");
      if (urlTemplateId && urlTemplateId != templateId) {
        updateUrlHash("templateId", "");
      }
    },
    [updateUrlHash, urlHashDetail]
  );

  const handleFormSubmit = useCallback(
    (data, event) => {
      const status = event?.nativeEvent?.submitter?.value;
      data.saveAsDraft = status === systemConstants.project.status.draft;

      const options = {
        templateId: selectedTemplateId ?? null
      };

      const project = {
        clientId: data.client.value.id,
        engagementTypeId: data.engagementType.value.id,
        ...getProjectObjectFromProjectFormData(
          data,
          {
            entitiesEnabled,
            projectManagerEnabled,
            projectYearEnabled
          },
          t
        ),
        ...getProjectTemplateDataFromProjectFormData(data, {
          projectQueriesEnabled
        })
      };

      addProject({ project, options });
    },
    [
      addProject,
      entitiesEnabled,
      projectManagerEnabled,
      projectQueriesEnabled,
      projectYearEnabled,
      selectedTemplateId,
      t
    ]
  );

  const handleCancel = useCallback(() => {
    goBack();
  }, [goBack]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/projects/${addedProject?.id}`);
    }
  }, [addedProject, isSuccess, navigate]);

  const formContents = useMemo(
    () => (
      <Stack gap="300" width="fill">
        {allTemplates && (
          <ProjectTemplatePanel
            projectTemplates={allTemplates}
            selectedTemplateId={selectedTemplateId}
            onClickTemplate={handleTemplateClick}
          />
        )}
        <ProjectForm
          enableEntities={entitiesEnabled}
          enableProjectManager={projectManagerEnabled}
          enableProjectYear={projectYearEnabled}
          startDateLabel={startDateLabel}
          endDateLabel={endDateLabel}
          project={defaultValue}
          isTemplateSelected={selectedTemplateId !== "blank"}
          projectTemplate={selectedProjectTemplate}
          enableProjectQueries={
            projectQueriesEnabled && selectedTemplateId !== "blank"
          }
          projectManagers={projectManagers}
          handleEngagementTypeChange={handleEngagementTypeChange}
          isError={isError}
          error={error}
          title={t("common:ui.project.title_formTitle")}
          subText={t("common:ui.project.title_formSubText")}
        />
      </Stack>
    ),
    [
      allTemplates,
      defaultValue,
      endDateLabel,
      entitiesEnabled,
      error,
      handleEngagementTypeChange,
      handleTemplateClick,
      isError,
      projectManagerEnabled,
      projectManagers,
      projectQueriesEnabled,
      projectYearEnabled,
      selectedProjectTemplate,
      selectedTemplateId,
      startDateLabel,
      t
    ]
  );

  const renderPageActions = isValid => {
    return (
      <>
        <Button
          label={t("common:ui.forms.cancel.label")}
          variant={ButtonVariant.TEXT}
          onClick={handleCancel}
        />
        <Button
          label={t("common:ui.projects.saveAsDraft.buttonLabel")}
          variant={ButtonVariant.SECONDARY}
          type="submit"
          disabled={!isValid}
          value={systemConstants.project.status.draft}
        />
        <Button
          label={t("common:ui.projects.publish.buttonLabel")}
          disabled={!isValid}
          type="submit"
        />
      </>
    );
  };

  return (
    <FormPageTemplate
      title={t("common:ui.project.title_create")}
      sticky
      form={{
        contents: formContents,
        yupSchema,
        handleSubmit: handleFormSubmit,
        handleCancel,
        disabled: isSubmitting || isSuccess,
        renderPageActions
      }}
      isLoading={isLoading}
      renderRawForm={true}
    />
  );
};

AddClientProjectForm.propTypes = {
  isLoading: PropTypes.bool
};

export default AddClientProjectForm;
