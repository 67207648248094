import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { classNames } from "@app/helpers/componentHelpers.js";
import {
  ActionItemType,
  ActionItemTypeWorkflow,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import { Button, ButtonSize, ButtonVariant } from "@atoms/Button/Button.tsx";
import { IconSize } from "@atoms/Icon/index.ts";
import Text, { FontWeight } from "@atoms/Text/Text.tsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

import { ActionNode } from "./ActionNode.tsx";
import { StepNode } from "./StepNode.tsx";
import "./WorkflowCanvas.scss";
import { configToCanvas, generateEdgePath } from "./workflowCanvas.ts";

const Divider = () => {
  return (
    <div className="workflow-canvas__divider">
      <svg className="divider__vertical-line" preserveAspectRatio="none">
        <line x1="0" y1="0" x2="0" y2="100%" />
      </svg>
    </div>
  );
};

const StartNode = () => {
  return (
    <Inline
      alignment="center"
      className="workflow-canvas__node workflow-canvas__node--start"
      data-nodeid="_start_" // this has a special nodeId to avoid potential conflicts with other nodes
    >
      <Text truncate={true} weight={FontWeight.SEMI_BOLD} text="Start" />
    </Inline>
  );
};

const AddActionButton = () => {
  return (
    <Button
      label="Add Action"
      variant={ButtonVariant.TEXT}
      size={ButtonSize.SMALL}
      iconSize={IconSize.L}
      iconName="add"
    />
  );
};

interface WorkflowCanvasProps {
  workflow: ActionItemTypeWorkflow;
  crudFormMode: CrudFormMode;
  actionItemType?: ActionItemType;
  showStart?: boolean;
}

const WorkflowCanvas = (props: WorkflowCanvasProps) => {
  const { workflow, crudFormMode, actionItemType, showStart = false } = props;
  const { t } = useTranslation();
  // NB: these will eventually moved to the context
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [hoveredLaneId, setHoveredLaneId] = useState(null);

  const graph = useMemo(() => {
    const { workflowSteps, workflowActions, workflowEdges } = configToCanvas({
      config: workflow,
      actionItemType,
      t
    });

    const nodeIdsInHoveredLane = new Set();
    workflowSteps.forEach(step => {
      step.isHovered = hoveredNodeId === step.id;

      if (hoveredLaneId === step.id) {
        nodeIdsInHoveredLane.add(step.id);
      }
    });
    workflowActions.forEach(action => {
      action.isHovered = hoveredNodeId === action.id;
      if (hoveredLaneId === action.sourceWorkflowId) {
        nodeIdsInHoveredLane.add(action.id);
      }
    });
    workflowEdges.forEach(edge => {
      const isHidden = !nodeIdsInHoveredLane.has(edge.sourceId);
      edge.isAnimated = hoveredNodeId === edge.sourceId;
      edge.zIndex = edge.isAnimated ? 1 : 0;
      edge.isHidden = isHidden ?? false;
    });

    return {
      workflowSteps,
      workflowActions,
      workflowEdges: workflowEdges
        .filter(edge => !edge.isHidden)
        .toSorted((a, b) => (a.zIndex ?? 0) - (b.zIndex ?? 0))
    };
  }, [actionItemType, hoveredLaneId, hoveredNodeId, t, workflow]);

  useLayoutEffect(() => {
    graph.workflowEdges.forEach(edge => {
      const workflowCanvas = document.querySelector(".workflow-canvas");
      if (!workflowCanvas) {
        return;
      }
      const sourceNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__nodes .workflow-canvas__node[data-nodeid="${edge.sourceId}"]`
      );
      const targetNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__nodes .workflow-canvas__node[data-nodeid="${edge.targetId}"]`
      );
      const edgeNode = workflowCanvas.querySelector(
        `.workflow-canvas__layer__edges .workflow-canvas__edge[data-edgeid="${edge.id}"]`
      );
      if (!edgeNode) {
        return;
      }

      const svgPathPoints = generateEdgePath(
        sourceNode,
        targetNode,
        workflowCanvas
      );
      if (svgPathPoints) {
        edgeNode.setAttribute("points", svgPathPoints);
      }
    });
  }, [graph.workflowEdges]);

  const handleMouseEnterLane = useCallback((_e, workflowStep) => {
    setHoveredLaneId(workflowStep.id);
  }, []);
  const handleMouseLeaveLane = useCallback(() => {
    setHoveredLaneId(null);
  }, []);
  const handleMouseEnterNode = useCallback((_e, node) => {
    setHoveredNodeId(node.id);
  }, []);
  const handleMouseLeaveNode = useCallback(() => {
    setHoveredNodeId(null);
  }, []);

  return (
    <div className="workflow-canvas">
      <div className="workflow-canvas__layer__nodes">
        <Inline gap="200">
          {/* NB: disabled for now since order of steps are currently non-deterministic */}
          {showStart ? (
            <Stack
              gap="100"
              className="workflow-canvas__lane"
              alignment="top-center"
            >
              <StartNode />
            </Stack>
          ) : (
            <></>
          )}
          {graph.workflowSteps.map((step, idx) => {
            return (
              <React.Fragment key={step.id}>
                {showStart || idx != 0 ? <Divider /> : <></>}
                <Stack
                  gap="100"
                  className={classNames([
                    "workflow-canvas__lane",
                    hoveredLaneId === step.id
                      ? "workflow-canvas__lane--hover"
                      : ""
                  ])}
                  alignment="top-center"
                  onMouseEnter={(e: Event) => handleMouseEnterLane(e, step)}
                  onMouseLeave={() => handleMouseLeaveLane()}
                >
                  <StepNode
                    node={step}
                    onMouseEnterNode={handleMouseEnterNode}
                    onMouseLeaveNode={handleMouseLeaveNode}
                  />
                  <Stack
                    gap="100"
                    alignment="top-center"
                    className="workflow-canvas__lane_actions"
                  >
                    {step.actions?.map(action => {
                      return (
                        <ActionNode
                          key={action.id}
                          node={action}
                          onMouseEnterNode={handleMouseEnterNode}
                          onMouseLeaveNode={handleMouseLeaveNode}
                        />
                      );
                    })}
                  </Stack>
                  {[CrudFormMode.CREATE, CrudFormMode.UPDATE].includes(
                    crudFormMode
                  ) && <AddActionButton />}
                </Stack>
              </React.Fragment>
            );
          })}
        </Inline>
      </div>
      <svg className="workflow-canvas__layer__edges">
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            orient="auto-start-reverse"
            markerUnits="userSpaceOnUse"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
        </defs>
        {graph.workflowEdges.map(edge => {
          return (
            <polyline
              key={edge.id}
              className={classNames([
                "workflow-canvas__edge",
                edge.isAnimated ? "animated" : ""
              ])}
              data-edgeid={edge.id}
              strokeLinecap="round"
              markerEnd="url(#arrow)"
            />
          );
        })}
      </svg>
    </div>
  );
};

export default WorkflowCanvas;
