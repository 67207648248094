import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { systemConstants } from "@shared/constants/systemConstants.js";
import { useGetClientProjectsForMenuQuery } from "@shared/hooks";

import ions from "@ions";
import { Context } from "@ions-interface";

import { menuConstants, routeConstants } from "@app/constants";

import { Icon } from "@atoms/Icon";

import { BorderStyle, DropdownInput } from "@molecules/inputs";

import NavHeader from "@components/atoms/NavHeader";
import NavSectionDivider from "@components/atoms/NavSectionDivider";
import SecondaryNavItem from "@components/molecules/SecondaryNavItem";

import "./SecondaryNavTemplate.scss";

const formatProjectItems = projects => {
  return (
    projects?.map(project => ({
      name: project.name,
      value: project.id,
      tag: project.year?.toString(),
      pills: [project.engagementTypeName, project.year]
    })) ?? []
  );
};

const menuItemTypes = menuConstants.menuItemTypes;
const sideNavContext = menuConstants.sideNavContext;
const SecondaryNavTemplate = props => {
  const {
    width,
    onClickArrow,
    sideNavItems,
    onClickNavigate,
    currentSideNavContext,
    project,
    client,
    isExpanded,
    getMenuItemExpanded,
    user
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: projectsForMenu } = useGetClientProjectsForMenuQuery(
    client?.id,
    {
      skip: !user.isHostUser || !client?.id
    }
  );

  const tooltipContent = useMemo(() => {
    const results = [];

    if (user.isHostUser && client?.name) {
      results.push(
        `${t("stringFormat.propercase", { key: "common:clients.displayName" })}: ${client?.name}`
      );
    }

    if (project?.name) {
      results.push(
        `${t("stringFormat.propercase", {
          key: "common:projects.displayName_one"
        })}: ${project?.name}`
      );
    }

    if (project?.engagement?.engagementTypeName) {
      results.push(
        `${t("stringFormat.propercase", { key: "common:type" })}: ${project?.engagement?.engagementTypeName}`
      );
    }

    if (project?.year) {
      results.push(
        `${t("stringFormat.propercase", { key: "common:ui.projects.year.label" })}: ${project?.year}`
      );
    }

    return results.join("\n");
  }, [
    client?.name,
    project?.engagement?.engagementTypeName,
    project?.name,
    project?.year,
    t,
    user.isHostUser
  ]);

  const projectName = useMemo(() => {
    if (user.isHostUser && !isExpanded) {
      return <></>;
    }

    if (user.isHostUser) {
      const currentCompletedProject =
        project?.status === systemConstants.engagement.status.completed
          ? {
              id: project?.id,
              name: project?.name,
              status: project?.status,
              year: project?.year,
              engagementTypeName: project?.engagement.engagementTypeName
            }
          : null;

      const dropDownMenuItems = [
        currentCompletedProject ? { ...currentCompletedProject } : [],
        projectsForMenu ?? []
      ].flat();
      const items = projectsForMenu
        ? formatProjectItems(dropDownMenuItems)
        : [];

      return (
        <>
          <DropdownInput
            value={
              currentSideNavContext === sideNavContext.PROJECT
                ? { name: project?.name || "", value: project?.id }
                : undefined
            }
            items={items}
            onChange={val =>
              navigate(`${routeConstants.projects}/${val.value}`)
            }
            searchPlaceholder={
              project?.name ? t("common:ui.forms.searchProjects.label") : ""
            }
            placeholder={t("common:ui.forms.project.placeholder")}
            borderStyle={BorderStyle.NONE_WITH_PADDING}
            allowUndefined={currentSideNavContext === sideNavContext.CLIENT}
            sortComparator={() => 0}
            extraInputProps={{
              title: project?.name
            }}
            context={ions.context?.default?.secondaryNav ?? Context.LIGHT}
          />
          <NavSectionDivider key="nav-divider-search-projects" />
        </>
      );
    }
    return (
      <>
        <NavHeader
          text={project?.name}
          tooltipContent={tooltipContent}
          isExpanded={isExpanded}
          pills={[project?.engagement?.engagementTypeName, project?.year]}
        />
        <NavSectionDivider key="nav-divider-header" />
      </>
    );
  }, [
    user.isHostUser,
    isExpanded,
    project?.name,
    project?.status,
    project?.year,
    project?.engagement?.engagementTypeName,
    project?.id,
    tooltipContent,
    projectsForMenu,
    currentSideNavContext,
    t,
    navigate
  ]);

  const clientName = useMemo(() => {
    if (!user?.isHostUser) return <></>;

    return (
      <NavHeader
        text={client?.name}
        isExpanded={isExpanded}
        image={client?.logo?.data}
        tooltipContent={tooltipContent}
      />
    );
  }, [
    client?.logo?.data,
    client?.name,
    isExpanded,
    tooltipContent,
    user?.isHostUser
  ]);

  const sideNavHeader = useMemo(() => {
    switch (currentSideNavContext) {
      case sideNavContext.ADMIN:
        return (
          <div className="secondary-nav__top__header">
            <NavHeader
              text={t("admin:ui.navigation.admin.label")}
              isExpanded={isExpanded}
            />
          </div>
        );
      case sideNavContext.PROJECT:
      case sideNavContext.CLIENT:
        return (
          <>
            <div className="secondary-nav__top__header">{clientName}</div>
            <NavSectionDivider key="nav-divider-client-header" />
          </>
        );
      default:
        return <></>;
    }
  }, [clientName, currentSideNavContext, isExpanded, t]);

  const renderTop = item => {
    switch (item.type) {
      case menuItemTypes.NAV_SECTION_TITLE:
        return (
          <NavSectionDivider
            key={item.name ?? "nav-divider-top"}
            isExpanded={isExpanded}
            item={item}
          />
        );
      case menuItemTypes.NAV_SECTION_END:
        return <NavSectionDivider key="nav-divider-top-end" />;
      default:
        return (
          <SecondaryNavItem
            key={item.name}
            isExpanded={isExpanded}
            isMenuItemExpanded={getMenuItemExpanded(item.id)}
            item={item}
            onClickNavigate={onClickNavigate}
          />
        );
    }
  };

  const renderMiddle = item => {
    return (
      <div key={item.name} className="secondary-nav__middle">
        <NavSectionDivider key="nav-divider-middle" />
        <SecondaryNavItem
          isExpanded={isExpanded}
          item={item}
          onClickNavigate={onClickNavigate}
        />
      </div>
    );
  };

  const renderBottom = item => {
    return (
      <React.Fragment key={item.name}>
        <NavSectionDivider key="nav-divider-bottom" />
        <SecondaryNavItem
          key={item.name}
          isExpanded={isExpanded}
          item={item}
          onClickNavigate={onClickNavigate}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="secondary-nav" style={{ width }}>
      <div className="secondary-nav__top" style={{ width }}>
        {sideNavHeader}
        {(currentSideNavContext === sideNavContext.PROJECT ||
          currentSideNavContext === sideNavContext.CLIENT) &&
          projectName}
        <div
          className={`secondary-nav__items-container ${
            !isExpanded ? "secondary-nav__items-container-scrollbar" : ""
          }`}
        >
          {sideNavItems["top"]?.map(renderTop)}
        </div>
        {sideNavItems["middle"]?.map(renderMiddle)}
        <div className="secondary-nav__button">
          <Icon
            name="keyboard_arrow_right"
            onClick={onClickArrow}
            className={`secondary-nav-arrow material-icons secondary-nav-arrow__icon ${
              isExpanded
                ? "secondary-nav-arrow--left"
                : "secondary-nav-arrow--right"
            }`}
          />
        </div>
      </div>
      <div className={"secondary-nav__bot"}>
        {sideNavItems["bottom"]?.map(renderBottom)}
      </div>
    </div>
  );
};

SecondaryNavTemplate.propTypes = {
  width: PropTypes.string,
  onClickArrow: PropTypes.func,
  sideNavItems: PropTypes.object,
  onClickNavigate: PropTypes.func,
  currentSideNavContext: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    year: PropTypes.string,
    client: PropTypes.shape({
      name: PropTypes.string
    }),
    engagement: PropTypes.shape({
      engagementTypeName: PropTypes.string
    })
  }),
  client: PropTypes.shape({
    logo: PropTypes.object,
    name: PropTypes.string
  }),
  isExpanded: PropTypes.bool,
  getMenuItemExpanded: PropTypes.func,
  user: PropTypes.shape({
    isHostUser: PropTypes.bool,
    name: PropTypes.string
  })
};

export default SecondaryNavTemplate;
