import React, { useCallback, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { formatDateOnly } from "@shared/helpers/utilities.ts";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import { smartFormHelper, smartFormResponseType } from "@app/helpers/smartForm";

import { Button } from "@atoms/Button";
import { IconSize } from "@atoms/Icon";

import { MultiSelectInput } from "@molecules/inputs";

import DatePicker from "@components/atoms/DatePicker";
import TextAreaInput from "@components/atoms/TextAreaInput/TextAreaInput";

import SmartFormRadioButtons from "../SmartFormRadioButtons";
import SmartFormSingleChoice from "../SmartFormSingleChoice";
import "./SmartFormModal.scss";

const invalidValues = [undefined, null, ""];

const SmartFormEntitiesFooter = props => {
  const {
    entities,
    addToFormData,
    t,
    responseType,
    entitiesError,
    inputError,
    options,
    isLineBreaksAllowed,
    maxLength,
    setInputError,
    min,
    max
  } = props;

  const [selectedEntities, setSelectedEntities] = useState([]);
  const [value, setValue] = useState("");
  const [resetInputControl, setResetInputControl] = useState(false);
  const windowSize = useWindowSize();
  const observerCreated = useRef(false); //prevent duplicate observer
  const datePickerRelocated = useRef(false); //relocate once

  const relocateDatePicker = element => {
    const datePickerElement = document.getElementById(
      "smart-form-modal__input"
    );
    if (!datePickerElement) {
      return;
    }
    element.style.removeProperty("inset");
    const rect = datePickerElement?.getBoundingClientRect();
    const bottom = Math.floor(windowSize.height - rect.y);
    element.style.inset = `auto auto ${bottom}px ${Math.floor(rect.left)}px`;
  };

  //use observer to detect the datePicker has been rendered
  const isDatePickerEnabled = mutations => {
    const calendarElement = mutations.find(
      m =>
        m.target.className ===
        "react-date-picker__calendar react-date-picker__calendar--open"
    );
    if (calendarElement) {
      const element = document.getElementsByClassName(
        "react-date-picker__calendar--open"
      )[0];
      if (!datePickerRelocated.current) {
        datePickerRelocated.current = true;
        relocateDatePicker(element);
      }
    }
  };

  const observer = new MutationObserver(isDatePickerEnabled);

  const handleCloseCalendar = () => {
    observer.disconnect();
    datePickerRelocated.current = false;
    observerCreated.current = false;
  };

  const handleOpenCalendar = () => {
    //to relocate calendar position, "react-date-picker__calendar--open" element is needed.
    //click calendar icon will directly create the element
    //click input bar will create the element after re-render
    const calendarElement = document.getElementsByClassName(
      "react-date-picker__calendar--open"
    )[0];
    if (calendarElement) {
      relocateDatePicker(calendarElement);
    } else {
      // create observer for element under the targetNode
      const targetNode = document.getElementById("smart-form-modal__input");
      const config = {
        attributes: true,
        childList: true,
        subtree: true
      };
      if (!observerCreated.current) {
        observerCreated.current = true;
        observer.observe(targetNode, config);
      }
    }
  };

  const onSelectedEntitiesChanged = entities => {
    setSelectedEntities(entities);
  };

  const handleSend = useCallback(() => {
    addToFormData({
      value,
      entities: selectedEntities
    });
    const isNumberValueValid = smartFormHelper.isNumberInRange({
      value,
      min,
      max
    });

    if (!isNumberValueValid) {
      setResetInputControl(!isNumberValueValid);
    } else {
      setResetInputControl(true);
    }
    setSelectedEntities([]);
  }, [addToFormData, selectedEntities, value, min, max]);

  const handleOnChange = (type, updated) => {
    switch (type) {
      case smartFormResponseType.NUMBER:
        setValue(
          smartFormHelper.getValidTypingSmartFormNumber(updated.target.value)
        );
        setInputError("");
        break;
      case smartFormResponseType.TEXT:
        const newValue = isLineBreaksAllowed
          ? updated.target.value
          : smartFormHelper.removeLineBreaks(updated.target.value);
        setValue(newValue);
        break;
      default:
        setValue(updated);
    }
  };

  useEffect(() => {
    if (!entitiesError) {
      setValue("");
    }
    return () => {
      setResetInputControl(false);
    };
  }, [entitiesError, resetInputControl]);
  const isSendButtonDisabled = () => {
    if (entities.length === 0 || invalidValues.includes(value)) {
      return true;
    }
    if (responseType === smartFormResponseType.NUMBER) {
      return !smartFormHelper.isValidSmartFormNumber(value);
    }
  };

  const renderInput = () => {
    switch (responseType) {
      case smartFormResponseType.NUMBER:
      case smartFormResponseType.TEXT:
        return (
          <TextAreaInput
            placeholder={smartFormHelper.getPlaceholder({
              responseType,
              min,
              max,
              t
            })}
            value={value}
            maxLength={maxLength}
            onChange={event => handleOnChange(responseType, event)}
            disabled={entities.length === 0}
            error={inputError}
          />
        );
      case smartFormResponseType.BOOLEAN:
        const booleanOptions = [
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.yes.label"
            )}`,
            value: true
          },
          {
            name: `${t(
              "requests:requests.ui.smartForm.responseType.boolean.no.label"
            )}`,
            value: false
          }
        ];
        return (
          <SmartFormRadioButtons
            options={booleanOptions}
            onChange={(_, value) =>
              handleOnChange(smartFormResponseType.BOOLEAN, value)
            }
            allowUnselect={false}
            value={value}
            questionId={-1}
            disabled={entities.length === 0}
          />
        );
      case smartFormResponseType.DATE:
        return (
          <DatePicker
            id={"calendar"}
            value={value ? formatDateOnly(value) : null}
            minDate={new Date("1000-01-01")}
            maxDate={new Date("9999-12-31")}
            onChange={value =>
              handleOnChange(smartFormResponseType.DATE, formatDateOnly(value))
            }
            disabled={entities.length === 0}
            onCalendarOpen={handleOpenCalendar}
            onCalendarClosed={handleCloseCalendar}
          />
        );
      case smartFormResponseType.SINGLE_CHOICE:
        return (
          <SmartFormSingleChoice
            options={options}
            value={value}
            handleOnChange={value => {
              handleOnChange(smartFormResponseType.SINGLE_CHOICE, value);
            }}
            disabled={entities.length === 0}
            questionId={-1}
            entities={entities}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="smart-form-modal--entities">
      <div className="smart-form-modal--entities-multiselect">
        <span className="smart-form-modal--entities-multiselect__relevant-entities">
          {t(
            "requests:requests.ui.smartForm.modal.answerPerEntity.multiSelect.relevantEntities"
          )}
        </span>
        <MultiSelectInput
          placeholder={t(
            "requests:requests.ui.smartForm.modal.multiselect.label"
          )}
          items={entities}
          onChange={onSelectedEntitiesChanged}
          disabled={entities.length === 0}
          selectedValues={selectedEntities}
          error={entitiesError}
        />
      </div>
      <div className="smart-form-modal__answer-per-entities">
        <div
          className="smart-form-modal__answer-per-entities-value"
          id="smart-form-modal__input"
        >
          {renderInput()}
        </div>
        <Button
          iconName="send"
          onClick={handleSend}
          iconSize={IconSize.S}
          className="smart-form-modal__answer-per-entities-button"
          disabled={isSendButtonDisabled()}
        />
      </div>
    </div>
  );
};

SmartFormEntitiesFooter.defaultProps = {
  entities: [],
  isLineBreaksAllowed: true
};

SmartFormEntitiesFooter.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any
    })
  ),
  addToFormData: PropTypes.func,
  error: PropTypes.string,
  isLineBreaksAllowed: PropTypes.bool,
  maxLength: PropTypes.number
};

export default withTranslation()(SmartFormEntitiesFooter);
