import React, { useContext, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useStaticAssets } from "@shared/hooks";

import UIConfigContext from "@app/helpers/UIConfigContext";

import Nav from "@components/atoms/Nav/Nav";
import NavSectionDivider from "@components/atoms/NavSectionDivider";
import HeaderMenu from "@components/molecules/HeaderMenu";
import LanguageToggle from "@components/molecules/LanguageToggle";
import LogoNav from "@components/molecules/LogoNav";

import "./TopNavigationTemplate.scss";

const TopNavigationTemplate = props => {
  const {
    searchBar,
    landingPageLink,
    showGroupLabel,
    groupLabels,
    menuItems,
    user,
    showSideBar,
    adminNavItems,
    isolationMode,
    navItems,
    subNavLeftPosition,
    setSubNavActive,
    handleNavigate,
    currentPath,
    navigationStyle,
    handleSelectFilter
  } = props;
  const { t } = useTranslation();
  const uiConfig = useContext(UIConfigContext);

  const [hostLanguages, setHostLanguages] = useState(null);
  const authentication = useSelector(_state => _state.authentication);
  const manageProfile = useSelector(_state => _state.manageProfile);
  const [logoLink, setLogoLink] = useState(landingPageLink);
  const { getUrl, setFavIcon } = useStaticAssets();

  const appName = useMemo(() => t("common:ui.headingName"), [t]);

  useEffect(() => {
    if (authentication.host) {
      setHostLanguages(authentication.host?.host?.properties?.i18n);
      document.title = authentication.host.name;
      setFavIcon();
    }
  }, [authentication.host, setFavIcon]);

  useEffect(() => {
    setLogoLink(landingPageLink || "");
  }, [landingPageLink]);

  const renderGroupLabels = useMemo(
    () => (
      <div className="top-nav__groupLabel">
        {showGroupLabel ? <>{groupLabels}</> : ""}
      </div>
    ),
    [showGroupLabel, groupLabels]
  );

  return (
    <nav className="top-nav main-page__header heading">
      <div className="top-nav__bar ">
        <div className="top-nav__bar__left">
          <div className="top-nav__logo">
            <LogoNav logo={getUrl("headerLogo.png")} link={logoLink} />
            {appName.length > 1 && (
              <div
                className={`top-nav__name top-nav__name--${uiConfig?.theme?.designSystem}`}
              >
                {appName}
              </div>
            )}
          </div>
          {!isolationMode && (
            <div className="top-nav__nav__items">
              <Nav
                navItems={navItems}
                subNavLeftPosition={subNavLeftPosition}
                setSubNavActive={setSubNavActive}
                handleNavigate={handleNavigate}
                currentPath={currentPath}
                navigationStyle={navigationStyle}
                handleSelectFilter={handleSelectFilter}
              />
            </div>
          )}
          {!uiConfig?.sideNavigation?.enabled && renderGroupLabels}
        </div>
        {!isolationMode && (
          <div className="top-nav__bar__right">
            {searchBar || (hostLanguages && <NavSectionDivider nav="top" />)}
            {searchBar && (
              <div className="top-nav__search-bar">{searchBar}</div>
            )}
            {hostLanguages && (
              <LanguageToggle
                languages={hostLanguages?.languages}
                languagePreferences={
                  manageProfile?.user?.properties?.languagePreferences
                    ?.UILanguage
                }
                defaultLng={hostLanguages?.default}
              />
            )}

            {showSideBar && adminNavItems?.length > 0 && (
              <>
                <NavSectionDivider nav="top" />
                <HeaderMenu
                  isActive={location.pathname.includes("/admin")}
                  menuItems={adminNavItems}
                  icon={"admin_panel_settings"}
                />
              </>
            )}
            {menuItems && <HeaderMenu menuItems={menuItems} user={user} />}
          </div>
        )}
      </div>
      {props.children}
    </nav>
  );
};

TopNavigationTemplate.defaultProps = {
  searchBar: null
};

TopNavigationTemplate.propTypes = {
  searchBar: PropTypes.element,
  landingPageLink: PropTypes.string,
  showGroupLabel: PropTypes.bool,
  groupLabels: PropTypes.element,
  handleSelectFilter: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  navItems: PropTypes.arrayOf(PropTypes.object),
  adminNavItems: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  isolationMode: PropTypes.bool,
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleNavigate: PropTypes.func,
  currentPath: PropTypes.string,
  showSideBar: PropTypes.bool,
  navigationStyle: PropTypes.string
};

export default TopNavigationTemplate;
