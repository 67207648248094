import React, { useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { returnStringIfTrue } from "@app/helpers/componentHelpers";
import { useMouseLeaveEnter } from "@app/hooks/useMouseLeaveEnter.tsx";

import { Box, Stack } from "@fermions";

import { Icon, IconFillStyle } from "@atoms/Icon";

import Avatar from "@components/atoms/Avatar";

import { DropdownTheme, Positioning } from "../DropdownList/DropdownHelper";
import { DropdownList } from "../DropdownList/DropdownList";
import "./HeaderMenu.scss";

const HeaderMenu = ({
  menuItems,
  user,
  icon,
  value,
  isNeverActive,
  isActive
}) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const navigate = useNavigate();
  const headerMenuContainerRef = useRef();
  const headerMenuRef = useRef();

  const iconContent = useMemo(() => {
    if (user?.name) {
      return <Avatar user={user} size="small" />;
    } else if (icon) {
      return <Icon name={icon} />;
    }
    return <></>;
  }, [icon, user]);

  const items = useMemo(
    () =>
      menuItems.map(item => ({
        ...item,
        value: item.value ?? item.path,
        icons: [
          { icon: item.icon, state: "light", fillStyle: IconFillStyle.FILLED }
        ]
      })),
    [menuItems]
  );

  const activeItem = useMemo(
    () => items.find(item => location.pathname === item.path),
    [items]
  );

  useOnClickOutside(headerMenuContainerRef, () => setIsShowMenu(false));
  const { handleOnMouseEnter, handleOnMouseLeave } = useMouseLeaveEnter({
    skip: !isShowMenu,
    cb: () => setIsShowMenu(false)
  });

  return (
    <Stack
      className="header-menu"
      onClick={() => setIsShowMenu(current => !current)}
      onMouseLeave={handleOnMouseLeave()}
      onMouseEnter={handleOnMouseEnter()}
      ref={headerMenuContainerRef}
    >
      <Box
        className={[
          `header-menu__icon`,
          returnStringIfTrue(isShowMenu, "header-menu__icon--show-menu"),
          returnStringIfTrue(
            !isNeverActive && (isActive || !!value || !!activeItem),
            "active"
          )
        ]}
        data-testid="test-hambuger-menu"
        ref={headerMenuRef}
        alignment="center"
      >
        {iconContent}
      </Box>
      <DropdownList
        isOpen={isShowMenu}
        parentRef={headerMenuRef}
        items={items}
        showSearch={false}
        theme={DropdownTheme.DARK}
        onClickItem={item => {
          item.onClick?.();
          if (item.path) {
            navigate(item.path);
          }
        }}
        minWidthOverride={250}
        positioning={Positioning.BOTTOM_LEFT}
        selectedValues={[activeItem ?? value]}
        sortComparator={() => 0}
      />
    </Stack>
  );
};

HeaderMenu.propTypes = {
  menuItems: PropTypes.array,
  user: PropTypes.shape({
    isHostUser: PropTypes.bool,
    name: PropTypes.string
  }),
  icon: PropTypes.string,
  value: PropTypes.any,
  isNeverActive: PropTypes.bool
};

export default HeaderMenu;
