import React, { LegacyRef, forwardRef, useMemo } from "react";

import { classNames } from "@app/helpers/componentHelpers";

import "../Fermions.scss";
import {
  FermionProps,
  getClassArray,
  getFermionClasses,
  splitProps
} from "../fermionsConfig";

interface InlineProps extends FermionProps {}

export const Inline = forwardRef(
  (props: InlineProps, ref?: LegacyRef<HTMLDivElement> | undefined) => {
    const { className, children } = props;

    const { divProps, classProps } = useMemo(() => splitProps(props), [props]);

    const classArray = useMemo(() => getClassArray(className), [className]);

    return (
      <div
        className={classNames([
          ...classArray,
          "fermion",
          "inline",
          ...getFermionClasses(classProps)
        ])}
        {...divProps}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
