import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import dateFormatter from "@shared/helpers/dateHelper";
import { useLocaleDate } from "@shared/hooks/useLocaleDate";

import { useDataTable } from "@app/hooks";
import { useInsightsBoardStore } from "@app/stores/useInsightsBoardStore.ts";

import DataTable from "@components/molecules/DataTable";

import "./InsightsCardTable.scss";
import InsightsCardTableCell from "./InsightsCardTableCell";

const safeCol = (row, colId) => row.values[colId].content ?? "";

const customStringSort = (rowA, rowB, columnId) => {
  return safeCol(rowA, columnId).localeCompare(safeCol(rowB, columnId), {
    sensitivity: "base"
  });
};

const customNumberSort = (rowA, rowB, columnId) => {
  return safeCol(rowA, columnId) - safeCol(rowB, columnId);
};

const customDateSort = (rowA, rowB, columnId) => {
  return (
    new Date(safeCol(rowA, columnId)).getTime() -
    new Date(safeCol(rowB, columnId)).getTime()
  );
};

const sortingTypes = {
  number: customNumberSort,
  string: customStringSort,
  date: customDateSort
};

function InsightsCardTable(props) {
  const { t } = useTranslation();
  const { createColumn, createColumnForMultiselectFilter } = useDataTable();
  const { locale, options } = useLocaleDate();
  const { insightsCardId, data, columns, displayLevel } = props;
  const tableRef = useRef();
  const selectedDrilldown = useInsightsBoardStore(state => state.drilldown);
  const setDrilldown = useInsightsBoardStore(state => state.setDrilldown);
  const clearDrilldown = useInsightsBoardStore(state => state.clearDrilldown);
  const currentEngagementTypeChanged = useInsightsBoardStore(
    state => state.currentEngagementTypeChanged
  );
  const setCurrentEngagementTypeChanged = useInsightsBoardStore(
    state => state.setCurrentEngagementTypeChanged
  );

  const handleSortChange = useCallback(colValue => {
    tableRef?.current?.setSortBy?.(colValue);
  }, []);

  const handleFilterChange = useCallback(
    key => e => {
      const newVal = e.map(u => u.value);
      tableRef.current?.setFilter(key, newVal);
    },
    []
  );

  const evaluateParam = useCallback(
    (param, rowId) => {
      const src = param?.source;
      if (src?.type === "column" && src.key && param.name) {
        const value = {};
        value[param.name] = data[rowId]?.[src.key]?.value;
        return value;
      }
      return null;
    },
    [data]
  );

  const handleDrilldownClicked = useCallback(
    ({ rowId, columnId, drilldown }) => {
      if (
        insightsCardId &&
        insightsCardId === selectedDrilldown?.insightsCardId &&
        selectedDrilldown?.rowId === rowId &&
        selectedDrilldown?.columnId === columnId
      ) {
        clearDrilldown();
      } else {
        const params = drilldown?.params
          ?.map(param => evaluateParam(param, rowId))
          .reduce((acc, param) => {
            return { ...acc, ...param };
          }, {});
        setDrilldown({
          insightsCardId,
          rowId,
          columnId,
          data: { ...drilldown, params }
        });
      }
    },
    [
      clearDrilldown,
      evaluateParam,
      insightsCardId,
      selectedDrilldown?.columnId,
      selectedDrilldown?.insightsCardId,
      selectedDrilldown?.rowId,
      setDrilldown
    ]
  );

  //Custom filter and sort since default expects string values for each cell, whereas we store objects
  const customFilter = (rows, colIds, filterValue) => {
    return rows.filter(row => {
      return colIds.some(id => {
        const rowValue = row.values[id].content;
        if (rowValue === undefined) {
          return false;
        }

        //If selected all/none, all items should show
        if (filterValue.length === 0) {
          return true;
        }
        return filterValue.some(val => rowValue?.includes(val));
      });
    });
  };

  const tableColumns = useMemo(() => {
    const insightOptions = systemConstants.insight.options;
    if (columns?.length > 0) {
      return columns.map(col => {
        if (col.option === insightOptions.multiFilter) {
          return createColumnForMultiselectFilter({
            accessor: col.key,
            Cell: InsightsCardTableCell,
            filterLabel: t(col.name),
            allOptionLabel: t("common:allOption"),
            onChangeHandler: handleFilterChange(col.key),
            accessValue: val => val.content,
            filter: customFilter,
            minWidth: col.width ?? 200
          });
        }

        return createColumn({
          Header: t(col.name),
          accessor: col.key,
          isHidden: col.hidden,
          Cell: InsightsCardTableCell,
          disableSortBy: col.option !== insightOptions.sort,
          handleSortChange,
          sortType: sortingTypes[col.type] ?? customStringSort,
          minWidth: col.width ?? 100
        });
      });
    }
    return [];
  }, [
    columns,
    createColumn,
    createColumnForMultiselectFilter,
    handleFilterChange,
    handleSortChange,
    t
  ]);

  const isMergeCell = useCallback(
    ({ rows, rowIndex, columnKey, displayLevel }) => {
      if (rowIndex === 0 || !displayLevel) return false;
      const prevRow = rows[rowIndex - 1];
      const currentRow = rows[rowIndex];
      const prevRowValue = prevRow?.[columnKey]?.value;
      const currentRowValue = currentRow?.[columnKey]?.value;
      return prevRowValue === currentRowValue;
    },
    []
  );

  const tableRows = useMemo(() => {
    const displayLevels = {};
    if (displayLevel > 1 && columns?.length > 0) {
      for (let i = 0; i < displayLevel - 1; i++) {
        if (i >= columns.length) break;
        if (columns[i]?.key) {
          displayLevels[columns[i].key] = i + 1;
        }
      }
    }

    return data.map((row, index) => {
      let rowHasMergedCell = false;
      return Object.keys(row).reduce((acc, key) => {
        const currColumn = columns?.find(c => c.key === key);
        const shouldTranslate = currColumn?.translate || row[key].translate;
        const isDate = columns.find(c => c.key === key)?.type === "date";
        const drilldown = currColumn?.drilldown;
        let content = shouldTranslate
          ? t(row[key].value)
          : (row[key].value ?? "");
        const hideCell = isMergeCell({
          rows: data,
          rowIndex: index,
          columnKey: key,
          displayLevel: displayLevels[key]
        });
        if (isDate) {
          content = dateFormatter(content, locale, options.shortFormat);
        }

        rowHasMergedCell = rowHasMergedCell || hideCell;
        const isHeaderCell = displayLevel > 1 && !rowHasMergedCell;
        const rowValue = {
          content,
          key,
          action: currColumn?.action,
          insightsCardId,
          drilldown,
          drilldownClickHandler: handleDrilldownClicked,
          isHeaderCell,
          isMergeCell: hideCell
        };

        return {
          ...acc,
          [key]: rowValue
        };
      }, {});
    });
  }, [
    columns,
    data,
    displayLevel,
    handleDrilldownClicked,
    insightsCardId,
    isMergeCell,
    locale,
    options.shortFormat,
    t
  ]);

  const rowClassNames = useCallback(
    rowId => {
      const classNames = [];
      if (
        selectedDrilldown?.insightsCardId === insightsCardId &&
        selectedDrilldown?.rowId === rowId
      ) {
        classNames.push("ot-insights-card-table__row--selected");
        classNames.push("active");
      }
      return classNames;
    },
    [
      insightsCardId,
      selectedDrilldown?.insightsCardId,
      selectedDrilldown?.rowId
    ]
  );

  // Prevent rows and filter from being reset on drilldown panel open/close
  const tableRowsRef = useRef(tableRows);

  useEffect(() => {
    if (
      (currentEngagementTypeChanged &&
        !isEqual(tableRowsRef?.current, tableRows)) ||
      !tableRef?.current
    ) {
      tableRowsRef.current = tableRows;
      setCurrentEngagementTypeChanged(false);
    }
  }, [
    currentEngagementTypeChanged,
    setCurrentEngagementTypeChanged,
    tableRows
  ]);

  return (
    <div className="ot-insights-card-table">
      <DataTable
        ref={tableRef}
        columns={tableColumns}
        data={tableRowsRef?.current}
        rowClassNames={rowClassNames}
        className="ot-insights-card-table__table"
      />
    </div>
  );
}

InsightsCardTable.propTypes = {
  insightsCardId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  displayLevel: PropTypes.number
};

export default InsightsCardTable;
