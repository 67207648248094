import React, { useMemo } from "react";

import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin
} from "@microsoft/applicationinsights-react-js";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { store } from "@shared/helpers/store";

import ExternalLinkWithObject from "@shared-components/pages/externalLinkWithObject/ExternalLinkWithObject";
import ForgotPassword from "@shared-components/pages/forgotPassword/ForgotPassword";
import ResetPassword from "@shared-components/pages/resetPassword/ResetPassword";
import SignUpPage from "@shared-components/pages/signUpPage/SignUpPage";

import ThemedStylesheet from "@app/components/organisms/ThemedStylesheet";
import { routeConstants } from "@app/constants";
import AccountNotFound from "@app/pages/AccountNotFound";
import AddClientUser from "@app/pages/AddClientUser";
import AddHostUser from "@app/pages/AddHostUser";
import AddOrUpdateArticle from "@app/pages/AddOrUpdateArticle";
import AddOrUpdateClientTag from "@app/pages/AddOrUpdateClientTag";
import CreateProject from "@app/pages/CreateProject";
import DataExtraction from "@app/pages/DataExtraction";
import EditDocument from "@app/pages/EditDocument";
import FinalPackage from "@app/pages/FinalPackage";
import InteractiveReport from "@app/pages/InteractiveReport";
import LandingPage from "@app/pages/LandingPage";
import LoginPage from "@app/pages/LoginPage";
import LogoutPage from "@app/pages/LogoutPage";
import ManageActionItems from "@app/pages/ManageActionItems";
import ManageAudits from "@app/pages/ManageAudits";
import ManageClientEngagements from "@app/pages/ManageClientEngagements";
import ManageClientUsers from "@app/pages/ManageClientUsers";
import ManageClients from "@app/pages/ManageClients";
import ManageDataRepository from "@app/pages/ManageDataRepository";
import ManageEngagementDashboard from "@app/pages/ManageEngagementDashboard";
import ManageInsightsBoard from "@app/pages/ManageInsightsBoard";
import ManageNews from "@app/pages/ManageNews";
import ManageRequestTypes from "@app/pages/ManageRequestTypes";
import ManageTeamUsers from "@app/pages/ManageTeamUsers";
import NotFound from "@app/pages/NotFound";
import PermanentFiles from "@app/pages/PermanentFiles";
import Profile from "@app/pages/Profile";
import ProjectRisks from "@app/pages/ProjectRisks";
import ProjectSummary from "@app/pages/ProjectSummary";
import ProjectsDashboard from "@app/pages/ProjectsDashboard";
import RequestDetails from "@app/pages/RequestDetails";
import SmartForm from "@app/pages/SmartFormPage";
import UpdateClientUser from "@app/pages/UpdateClientUser";
import UpdateHostUser from "@app/pages/UpdateHostUser";
import UploadInteractiveReport from "@app/pages/UploadInteractiveReport";
import { AccessLevel, Path, ResourceName } from "@app/types";

import RequireAuth from "@components/organisms/RequireAuth";
import UIConfigProvider from "@components/organisms/UIConfigProvider";

import { AddClient } from "@pages/AddClient";
import { UpdateClient } from "@pages/UpdateClient";
import CreateActionItemType from "@pages/ViewActionItemType/CreateActionItemType.tsx";
import UpdateActionItemType from "@pages/ViewActionItemType/UpdateActionItemType.tsx";
import ViewActionItemType from "@pages/ViewActionItemType/ViewActionItemType.tsx";

import "./App.scss";
import { AppInsights } from "./AppInsights";
import AddUserRole from "./pages/AddUserRole";
import AiReporting from "./pages/AiReporting";
import CopyClientEngagementProject from "./pages/CopyClientEngagementProject";
import InsightsBoard from "./pages/InsightsBoard";
import ManageUserRoles from "./pages/ManageUserRoles";
import SomethingWentWrong from "./pages/SomethingWentWrong";
import UpdateProject from "./pages/UpdateProject";
import UpdateUserRole from "./pages/UpdateUserRole";
import ViewUserRole from "./pages/ViewUserRole";

const pathFragments = {
  engagement: "/engagements/:engagementId",
  project: "/projects/:projectId",
  client: "/clients/:clientId",
  user: "/users/:userId",
  query: "/queries/:queryId",
  websheet: "/websheets/:queryId",
  document: "/documents/:documentId",
  smartForm: "/smartforms/:queryId",
  smartFormWebsheet: "/websheets/:websheetId",
  page: "/pages/:pageId",
  insightsBoard: `${routeConstants.insightsBoard}/:insightsBoardId`,
  roles: "/roles/:roleId",
  uploadInteractiveReport: "/upload-interactive-report",
  aiReport: "/ai-reporting",
  risks: "/risks",
  admin: "/admin",
  actionItemTypes: "/request-types"
};

const authenticatedPaths: Path[] = [
  {
    path: routeConstants.clientDashboard,
    Page: ProjectsDashboard
  },
  {
    path: routeConstants.manageAudits,
    permission: {
      allOf: [
        { resource: ResourceName.AUDIT_REPORT, accessLevel: AccessLevel.READ }
      ]
    },
    Page: ManageAudits
  },
  {
    path: routeConstants.dataExtraction,
    permission: {
      allOf: [
        {
          resource: ResourceName.DATA_EXTRACTION,
          accessLevel: AccessLevel.READ
        }
      ]
    },
    Page: DataExtraction
  },
  {
    path: routeConstants.createProject,
    permission: {
      anyOf: [
        {
          resource: ResourceName.PROJECTS_WITH_MEMBERSHIP,
          accessLevel: AccessLevel.CREATE
        },
        {
          resource: ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
          accessLevel: AccessLevel.CREATE
        }
      ]
    },
    Page: CreateProject
  },
  {
    path: routeConstants.editDashboardArticle,
    Page: AddOrUpdateArticle
  },
  {
    path: routeConstants.addDashboardArticle,
    Page: AddOrUpdateArticle
  },
  {
    path: routeConstants.manageClientEngagements,
    Page: ManageClientEngagements
  },
  {
    path: routeConstants.manageNews,
    permission: {
      allOf: [
        {
          resource: ResourceName.MANAGE_NEWS_AND_IMAGE,
          accessLevel: AccessLevel.READ
        }
      ]
    },
    Page: ManageNews
  },
  {
    path: routeConstants.admin.manageUserRoles,
    Page: ManageUserRoles,
    permission: {
      allOf: [{ resource: ResourceName.ROLES, accessLevel: AccessLevel.READ }]
    }
  },
  {
    path: routeConstants.admin.manageRequestTypes,
    Page: ManageRequestTypes,
    permission: {
      allOf: [
        { resource: ResourceName.REQUEST_TYPES, accessLevel: AccessLevel.READ }
      ]
    }
  },
  {
    path: `${pathFragments.admin}${pathFragments.actionItemTypes}/:actionItemTypeId`,
    permission: {
      allOf: [
        { resource: ResourceName.REQUEST_TYPES, accessLevel: AccessLevel.READ }
      ]
    },
    Page: ViewActionItemType
  },
  {
    path: `${pathFragments.admin}${pathFragments.actionItemTypes}/:actionItemTypeId/edit`,
    permission: {
      allOf: [
        {
          resource: ResourceName.REQUEST_TYPES,
          accessLevel: AccessLevel.UPDATE
        }
      ]
    },
    Page: UpdateActionItemType
  },
  {
    path: routeConstants.admin.addRequestType,
    permission: {
      allOf: [
        {
          resource: ResourceName.REQUEST_TYPES,
          accessLevel: AccessLevel.CREATE
        }
      ]
    },
    Page: CreateActionItemType
  },
  {
    path: routeConstants.addClientTag,
    Page: AddOrUpdateClientTag
  },
  {
    path: routeConstants.updateClientTag,
    Page: AddOrUpdateClientTag
  },
  { path: routeConstants.project.summary, Page: ProjectSummary },
  { path: routeConstants.profile, Page: Profile },
  {
    path: routeConstants.engagement.dashboard,
    Page: ManageEngagementDashboard
  },
  { path: routeConstants.request.requests, Page: ManageActionItems },
  { path: routeConstants.request.queryDetails, Page: RequestDetails },
  { path: routeConstants.dataRepository, Page: ManageDataRepository },
  { path: routeConstants.permanentFiles, Page: PermanentFiles },
  { path: routeConstants.dashboard, Page: LandingPage },
  { path: routeConstants.editDocument, Page: EditDocument },
  {
    path: routeConstants.insightsBoard,
    permission: {
      allOf: [
        { resource: ResourceName.INSIGHTS, accessLevel: AccessLevel.READ }
      ]
    },
    Page: ManageInsightsBoard
  },
  // deep links
  {
    path: pathFragments.client,
    Page: ManageClientEngagements
  },
  {
    path: routeConstants.manageClients,
    permission: {
      allOf: [{ resource: ResourceName.CLIENTS, accessLevel: AccessLevel.READ }]
    },
    Page: ManageClients
  },
  {
    path: routeConstants.manageUsers,
    permission: {
      allOf: [
        { resource: ResourceName.HOST_USERS, accessLevel: AccessLevel.READ }
      ]
    },
    Page: ManageTeamUsers
  },
  {
    path: pathFragments.engagement,
    Page: ManageEngagementDashboard
  },
  { path: pathFragments.project, Page: ProjectSummary },
  {
    path: `${pathFragments.project}${routeConstants.dataRepository}`,
    Page: ManageDataRepository
  },
  {
    path: `${pathFragments.project}${routeConstants.finalPackage}`,
    Page: FinalPackage
  },
  { path: `${pathFragments.project}/queries`, Page: ManageActionItems },
  {
    path: `${pathFragments.project}${pathFragments.websheet}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.document}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.query}`,
    Page: RequestDetails
  },
  {
    path: `${pathFragments.project}${pathFragments.page}`,
    Page: InteractiveReport
  },
  {
    path: `${pathFragments.project}${pathFragments.uploadInteractiveReport}`,
    Page: UploadInteractiveReport
  },
  {
    path: `${pathFragments.project}${pathFragments.aiReport}`,
    Page: AiReporting
  },
  {
    path: `${pathFragments.project}${pathFragments.smartForm}`,
    Page: SmartForm
  },
  {
    path: `${pathFragments.project}${pathFragments.smartForm}${pathFragments.smartFormWebsheet}`,
    Page: EditDocument
  },
  {
    path: `${pathFragments.project}${pathFragments.risks}`,
    Page: ProjectRisks
  },
  {
    path: `${pathFragments.client}${routeConstants.permanentFiles}`,
    permission: {
      allOf: [
        {
          resource: ResourceName.PERMANENT_FILES,
          accessLevel: AccessLevel.READ
        }
      ]
    },
    Page: PermanentFiles
  },
  {
    path: routeConstants.addClient,
    permission: {
      allOf: [
        { resource: ResourceName.CLIENTS, accessLevel: AccessLevel.CREATE }
      ]
    },
    Page: AddClient
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/edit`,
    permission: {
      allOf: [
        { resource: ResourceName.CLIENTS, accessLevel: AccessLevel.UPDATE }
      ]
    },
    Page: UpdateClient
  },
  {
    path: `${pathFragments.admin}/add-user`,
    Page: AddHostUser,
    permission: {
      allOf: [
        { resource: ResourceName.HOST_USERS, accessLevel: AccessLevel.CREATE }
      ]
    }
  },
  {
    path: `${pathFragments.admin}${pathFragments.user}/edit`,
    permission: {
      allOf: [
        { resource: ResourceName.HOST_USERS, accessLevel: AccessLevel.UPDATE }
      ]
    },
    Page: UpdateHostUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/users`,
    permission: {
      allOf: [
        { resource: ResourceName.CLIENT_USERS, accessLevel: AccessLevel.READ }
      ]
    },
    Page: ManageClientUsers
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}/add-user`,
    permission: {
      allOf: [
        { resource: ResourceName.CLIENT_USERS, accessLevel: AccessLevel.CREATE }
      ]
    },
    Page: AddClientUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.client}${pathFragments.user}/edit`,
    permission: {
      allOf: [
        { resource: ResourceName.CLIENT_USERS, accessLevel: AccessLevel.UPDATE }
      ]
    },
    Page: UpdateClientUser
  },
  {
    path: `${pathFragments.admin}${pathFragments.roles}/edit`,
    permission: {
      allOf: [{ resource: ResourceName.ROLES, accessLevel: AccessLevel.UPDATE }]
    },
    Page: UpdateUserRole
  },
  {
    path: `${pathFragments.admin}/create-role`,
    permission: {
      allOf: [{ resource: ResourceName.ROLES, accessLevel: AccessLevel.CREATE }]
    },
    Page: AddUserRole
  },
  {
    path: `${pathFragments.admin}${pathFragments.roles}`,
    permission: {
      allOf: [{ resource: ResourceName.ROLES, accessLevel: AccessLevel.READ }]
    },
    Page: ViewUserRole
  },
  {
    path: `${pathFragments.project}/edit`,
    permission: {
      anyOf: [
        {
          resource: ResourceName.PROJECTS_WITH_MEMBERSHIP,
          accessLevel: AccessLevel.UPDATE
        },
        {
          resource: ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
          accessLevel: AccessLevel.UPDATE
        }
      ]
    },
    Page: UpdateProject
  },
  {
    path: `${pathFragments.project}/copy`,
    permission: {
      anyOf: [
        {
          resource: ResourceName.PROJECTS_WITH_MEMBERSHIP,
          accessLevel: AccessLevel.CREATE
        },
        {
          resource: ResourceName.PROJECTS_WITHOUT_MEMBERSHIP,
          accessLevel: AccessLevel.CREATE
        }
      ]
    },
    Page: CopyClientEngagementProject
  },
  {
    path: pathFragments.insightsBoard,
    permission: {
      allOf: [
        { resource: ResourceName.INSIGHTS, accessLevel: AccessLevel.READ }
      ]
    },
    Page: InsightsBoard
  },
  {
    path: "*",
    Page: NotFound
  }
];

function authenticatedRoute({ path, Page, permission }: Path, key: number) {
  return (
    <Route
      key={key}
      path={path}
      element={
        <RequireAuth permission={permission}>
          <Page />
        </RequireAuth>
      }
    />
  );
}

function App() {
  const reactPlugin = useMemo(() => new ReactPlugin(), []);
  const errorMsg = () => <SomethingWentWrong />;

  AppInsights({ reactPlugin }).catch(() => {});

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppInsightsErrorBoundary appInsights={reactPlugin} onError={errorMsg}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <UIConfigProvider>
              <ThemedStylesheet />
              <div data-test="test-app" className="oneteam-app">
                <div className="oneteam-app-body">
                  <Routes>
                    {authenticatedPaths.map(authenticatedRoute)}
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/logout" element={<LogoutPage />} />
                    <Route
                      path="/accountNotFound"
                      element={<AccountNotFound />}
                    />
                    <Route
                      path="/forgotPassword"
                      element={<ForgotPassword />}
                    />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                    <Route
                      path="/external"
                      element={<ExternalLinkWithObject />}
                    />
                    <Route path="*" element={<LoginPage />} />
                  </Routes>
                </div>
                <ToastContainer
                  position="bottom-right"
                  hideProgressBar={true}
                  transition={Slide}
                  limit={5}
                />
              </div>
            </UIConfigProvider>
          </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
