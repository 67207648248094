import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { utilities } from "@shared/helpers";

import { parseEntities, validateEntities } from "@app/helpers/entity";

export const getAddProjectFormFieldsSchema = (t, projectQueriesEnabled) => {
  yup.addMethod(yup.string, "validParseEntities", function () {
    return this.test(`test-entities-lists`, "", function (value) {
      const validation = validateEntities(parseEntities(value, t), t);
      const { path, createError } = this;
      if (validation?.error) {
        return createError({ path, message: validation.error.message });
      }
      return true;
    });
  });

  return {
    projectManagerEnabled: yup.bool(),
    entitiesEnabled: yup.bool(),
    projectYearEnabled: yup.bool(),
    projectName: yup
      .string()
      .trim()
      .required(t("common:ui.projects.name.error")),
    projectManager: yup.object().when("projectManagerEnabled", {
      is: projectManagerEnabled => projectManagerEnabled,
      then: () =>
        yup.object().required(t("common:ui.projects.projectManager.error"))
    }),
    year: yup.object().when("projectYearEnabled", {
      is: projectYearEnabled => projectYearEnabled,
      then: () =>
        yup.object().shape({
          name: yup.string().required(
            t("common:ui.forms.required.message", {
              label: t("common:ui.projects.year.label")
            })
          ),
          value: yup.string().required().min(4).max(4).matches(/^\d+$/)
        })
    }),
    startDate: yup.date().required(t("common:ui.projects.startDate.error")),
    plannedCompletionDate: yup
      .date()
      .required(t("common:ui.projects.endDate.error.defined")),
    entities: yup.string().when("entitiesEnabled", {
      is: entitiesEnabled => entitiesEnabled,
      then: schema => schema.validParseEntities().required()
    }),
    ...(projectQueriesEnabled
      ? {
          assignedTo: yup
            .object()
            .shape({
              id: yup.string().required(
                t("common:ui.forms.required.message", {
                  label: t("common:ui.projects.assignedTo.label")
                })
              ),
              name: yup.string()
            })
            .required()
        }
      : {})
  };
};

export const getEditProjectFormFieldsSchema = t => {
  const schema = getAddProjectFormFieldsSchema(t);
  delete schema.startDate;
  delete schema.plannedCompletionDate;
  return schema;
};

export const getProjectObjectFromProjectFormData = (data, config, t) => {
  const {
    projectManagerEnabled,
    entitiesEnabled,
    projectYearEnabled,
    isUpdate
  } = config;
  const project = {
    name: data.projectName.trim()
  };
  if (!isUpdate) {
    project.startDate = utilities.endOfDay(data.startDate);
    project.plannedCompletionDate = utilities.endOfDay(
      data.plannedCompletionDate
    );
  }
  if (projectYearEnabled) {
    project.year = data.year.value;
  }

  if (entitiesEnabled) {
    project.entities = parseEntities(data.entities, t);
  }
  if (projectManagerEnabled) {
    project.projectManagerId = data.projectManager.value.id;
  }
  if (data.publish) {
    project.status = systemConstants.project.status.open;
  }
  if (data.saveAsDraft) {
    project.status = systemConstants.project.status.draft;
  }
  return project;
};

export const getProjectTemplateDataFromProjectFormData = (data, config) => {
  const { projectQueriesEnabled } = config;
  if (!projectQueriesEnabled) {
    return {};
  }
  return {
    projectTemplateData: {
      queries: {
        assignedTo: { id: data.assignedTo.id },
        copiedTo: data.copiedTo?.map(user => ({
          id: user.id
        }))
      }
    }
  };
};
