import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { systemConstants } from "@shared/constants";
import {
  insightsServiceUtil,
  useAuthUser,
  useFeatures,
  useGetInsightsBoardQuery,
  useGetInsightsQuery,
  useToasts,
  useUpdateInsightsBoardMutation
} from "@shared/hooks";
import usePubSub from "@shared/hooks/usePubSub";

import InsightsBoardTemplate from "@components/templates/InsightsBoardTemplate";

import { useGetInsightsBoardsCards } from "./useGetInsightsBoardsCards";

function InsightsBoard() {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const { isLoading: isFeaturesLoading, isEnabled } = useFeatures();
  const [selectedEngagementType, setSelectedEngagementType] = useState(null);
  const [localInsightsBoard, setLocalInsightsBoard] = useState(null);

  const { showInsightsBoardEditedToast } = useToasts();

  const { insightsBoardId } = useParams();
  const {
    data: insightsBoard,
    error: insightBoardError,
    refetch: refetchInsightsBoard
  } = useGetInsightsBoardQuery(insightsBoardId, { skip: !insightsBoardId });
  const {
    data: insightsData,
    isLoading: isInsightsLoading,
    error: insightsError
  } = useGetInsightsQuery(
    { insightsBoardId, engagementTypeId: selectedEngagementType?.id },
    {
      skip: !insightsBoardId || !selectedEngagementType?.id,
      refetchOnMountOrArgChange: true
    }
  );
  const [updateInsightsBoard] = useUpdateInsightsBoardMutation();
  const navigate = useNavigate();
  const { getBoardInsightsCards } = useGetInsightsBoardsCards();
  const didMount = useRef(false);
  const refreshInsightsBoard = usePubSub();
  const dispatch = useDispatch();
  const { insights } = systemConstants.features;

  useEffect(() => {
    if (!isFeaturesLoading && !isEnabled(insights)) {
      navigate("/not-found");
    }
  }, [insights, isEnabled, isFeaturesLoading, navigate]);

  useEffect(() => {
    if (insightBoardError) {
      navigate("/not-found");
    }
  }, [insightBoardError, insightsBoardId, navigate]);

  useEffect(() => {
    setLocalInsightsBoard(structuredClone(insightsBoard));
  }, [insightsBoard]);

  useEffect(() => {
    if (!didMount.current) {
      refreshInsightsBoard.subscribe(
        systemConstants.insight.events.REFRESH_INSIGHTS_BOARD
      );
      didMount.current = true;
    }
  }, [refreshInsightsBoard]);

  useEffect(() => {
    if (
      refreshInsightsBoard.value &&
      refreshInsightsBoard.value.userId != user.id
    ) {
      showInsightsBoardEditedToast();
    }
  }, [refreshInsightsBoard.value, showInsightsBoardEditedToast, user.id]);

  const onEngagementTypeSelected = useCallback(engagementType => {
    setSelectedEngagementType({
      id: engagementType?.id,
      name: engagementType?.name
    });
  }, []);

  const insightsCards = useMemo(() => {
    return getBoardInsightsCards({
      insightsData
    });
  }, [getBoardInsightsCards, insightsData]);

  const translateInsightsBoard = insightsBoard => {
    if (insightsBoard?.translate) {
      return t(insightsBoard.name);
    }
    return insightsBoard?.name;
  };

  const getNewInsightsBoard = transformResult => {
    refetchInsightsBoard()
      .unwrap()
      .then(result =>
        setLocalInsightsBoard(transformResult?.(result) ?? result)
      );

    //this ensures insights board table page is synced up and not using a cached value
    dispatch(insightsServiceUtil.invalidateTags(["InsightsBoard"]));
  };

  const handleSubmit = async () => {
    try {
      await updateInsightsBoard(localInsightsBoard).unwrap();
      getNewInsightsBoard();
    } catch (error) {
      return error.data?.key ?? error.data?.message;
    }
    return "";
  };

  const handleUpdateLocal = data => {
    setLocalInsightsBoard(prev => ({
      ...prev,
      ...data
    }));
  };

  const handleSwitchToEditMode = () => {
    const transformResult = value => {
      const translatedName = translateInsightsBoard(value);
      return {
        ...value,
        name: translatedName,
        translate: false
      };
    };
    getNewInsightsBoard(transformResult);
  };

  const handleCancelEditMode = () => {
    getNewInsightsBoard();
  };

  return (
    <InsightsBoardTemplate
      insightsBoardId={insightsBoardId}
      subtitleText={translateInsightsBoard(localInsightsBoard)}
      insightsCards={insightsCards}
      onEngagementTypeSelected={onEngagementTypeSelected}
      loading={isInsightsLoading}
      error={insightsError}
      handleSubmit={handleSubmit}
      handleUpdate={handleUpdateLocal}
      handleSwitchToEditMode={handleSwitchToEditMode}
      handleCancelEditMode={handleCancelEditMode}
    />
  );
}

export default InsightsBoard;
