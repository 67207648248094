import React, { useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { systemConstants } from "@shared/constants";
import { useRequestPageNavigator } from "@shared/hooks/useRequestPageNavigator";

import { Button, ButtonSize, ButtonVariant } from "@atoms/Button";
import { IconSize } from "@atoms/Icon";

import { ToastNotification, ToastType } from "@molecules/ToastNotification";

export function useToasts() {
  const { t } = useTranslation();
  const { navigateToRequestPage } = useRequestPageNavigator();
  const toastId = useRef(null);

  const showActionItemCreatedToast = useCallback(
    ({ project, request, actionItemType }) => {
      if (!request?.id || !project?.id) {
        return;
      }

      const notify = (title, description) =>
        (toastId.current = toast(
          <ToastNotification
            type={ToastType.SUCCESS}
            description={description}
            actions={
              <Button
                label={t(
                  "requests:requests.ui.toastMessages.viewRequestActionLabel"
                )}
                variant={ButtonVariant.TEXT}
                size={ButtonSize.MINIMAL}
                onClick={openRequest}
              />
            }
          />
        ));

      const isDraftProject =
        project?.status === systemConstants.project.status.draft;
      const title = isDraftProject
        ? t("requests:requests.ui.toastMessages.draftRequestCreatedTitle")
        : t("requests:requests.ui.toastMessages.requestCreatedTitle");
      const description = isDraftProject
        ? t("requests:requests.ui.toastMessages.draftRequestCreatedDescription")
        : undefined;
      const dismiss = () => toast.dismiss(toastId.current);
      const openRequest = () => {
        navigateToRequestPage(request.id, project.id, actionItemType, {
          websheetOpensNewWindow: true
        });
        dismiss();
      };
      notify(title, description);
    },
    [navigateToRequestPage, t]
  );

  const showSendRequestToExternalToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t("requests:requests.ui.toastMessages.requestSubmitted")}
        description={t(
          "requests:requests.ui.toastMessages.sendRequestToExternal"
        )}
      />
    );
  }, [t]);

  const showActionItemsCopiedToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t("requests:requests.ui.toastMessages.copyRequestsCreatedTitle")}
      />
    );
  }, [t]);

  const showProjectPublishedToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t("requests:requests.ui.toastMessages.projectPublishedTitle")}
      />
    );
  }, [t]);

  const showProjectPublishFailedToast = useCallback(
    error => {
      toast(
        <ToastNotification
          type={ToastType.ERROR}
          title={t(
            "requests:requests.ui.toastMessages.projectPublishedFailedTitle"
          )}
          description={error}
        />
      );
    },
    [t]
  );

  const showQueryDeleteToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t("requests:requests.ui.toastMessages.query.delete.title")}
      />
    );
  }, [t]);

  const showProjectDeleteToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t("common:ui.project.toastMessages.deleteTitle")}
      />
    );
  }, [t]);

  const showQueryDeleteFailedToast = useCallback(
    error => {
      toast(
        <ToastNotification
          type={ToastType.ERROR}
          title={t(
            "requests:requests.ui.toastMessages.query.delete.failed.title"
          )}
          description={error}
        />
      );
    },
    [t]
  );

  const showProjectDeleteFailedToast = useCallback(
    error => {
      toast(
        <ToastNotification
          type={ToastType.ERROR}
          title={t("common:ui.project.toastMessages.deleteFailedTitle")}
          description={error}
        />
      );
    },
    [t]
  );

  const showApplyNewCleanToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t(
          "common:ui.websheet.actions.cleaningWizard.notification.applyNew"
        )}
      />
    );
  }, [t]);

  const showInsightsBoardEditedToast = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.WARNING}
        title={t(
          "common:insights.board.page.toastMessages.modifiedByAnotherUser"
        )}
      />
    );
  }, [t]);

  const showSaveCleanToast = useCallback(
    ({ fileName, templateName }) => {
      toast(
        <ToastNotification
          type={ToastType.SUCCESS}
          title={t(
            "common:ui.websheet.actions.cleaningWizard.notification.saveTemplate",
            {
              fileName,
              templateName
            }
          )}
        />
      );
    },
    [t]
  );

  const showSuccess = useCallback(
    ({ key, description, actions }) => {
      toast(
        <ToastNotification
          type={ToastType.SUCCESS}
          title={t(key)}
          description={description}
          actions={actions}
        />
      );
    },
    [t]
  );

  const showError = useCallback(
    ({ key }) => {
      toast(<ToastNotification type={ToastType.ERROR} title={t(key)} />);
    },
    [t]
  );

  const showSendToHostSuccess = useCallback(() => {
    toast(
      <ToastNotification
        type={ToastType.SUCCESS}
        title={t(
          "requests:requests.ui.toastMessages.sendRequest.success.title"
        )}
      />
    );
  }, [t]);

  const showSendToHostErrorToast = useCallback(
    ({ host }) => {
      toast(
        <ToastNotification
          type={ToastType.ERROR}
          title={t(
            "requests:requests.ui.toastMessages.sendRequest.error.title"
          )}
          description={t(
            "requests:requests.ui.toastMessages.sendRequest.error.description",
            { host }
          )}
          actions={
            <Button
              label={t("requests:requests.ui.toastActions.refresh")}
              variant={ButtonVariant.TEXT}
              size={ButtonSize.MINIMAL}
              iconName="refresh"
              iconSize={IconSize.S}
              onClick={() => {
                window.location.reload();
              }}
            />
          }
        />
      );
    },
    [t]
  );

  const showBulkUpdateSuccess = useCallback(
    ({ created, updated }) => {
      toast(
        <ToastNotification
          type={ToastType.SUCCESS}
          title={t("requests:requests.ui.bulkUpsert.success.title.label")}
          description={t(
            "requests:requests.ui.bulkUpsert.success.description.label",
            { created, updated }
          )}
        />
      );
    },
    [t]
  );

  const showQueryUpdateErrorToast = useCallback(
    (error, action) => {
      toast(
        <ToastNotification
          type={ToastType.ERROR}
          title={t("requests:requests.ui.toastMessages.moveQuery.error.title")}
          description={error}
          actions={action}
        />,
        {
          autoClose: false
        }
      );
    },
    [t]
  );

  return {
    showActionItemCreatedToast,
    showActionItemsCopiedToast,
    showSendRequestToExternalToast,
    showProjectPublishedToast,
    showProjectPublishFailedToast,
    showProjectDeleteToast,
    showProjectDeleteFailedToast,
    showInsightsBoardEditedToast,
    showApplyNewCleanToast,
    showSaveCleanToast,
    showSuccess,
    showError,
    showSendToHostSuccess,
    showSendToHostErrorToast,
    showQueryDeleteToast,
    showQueryDeleteFailedToast,
    showQueryUpdateErrorToast,
    showBulkUpdateSuccess
  };
}
