import React, { useMemo } from "react";

import { useFormContext } from "react-hook-form";

import {
  AITFormOptions,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import { Stack } from "@components/fermions/index.tsx";

import "./ActionItemTypeForm.scss";
import ActionItemTypeFormFields from "./ActionItemTypeFormFields.tsx";
import ActionItemTypeFormKeyInformation from "./ActionItemTypeFormKeyInformation.tsx";
import ActionItemTypeFormPermissions from "./ActionItemTypeFormPermissions.tsx";
import ActionItemTypeFormWorkflow from "./ActionItemTypeFormWorkflow.tsx";

interface Props {
  actionItemType: ActionItemType;
  formOptions: AITFormOptions;
  crudFormMode: CrudFormMode;
}

const ActionItemTypeForm = (props: Props) => {
  const {
    actionItemType,
    formOptions,
    crudFormMode = CrudFormMode.READ
  } = props;
  const { watch } = useFormContext();
  const formatField = watch("format");

  const aitFormatConfig = useMemo(() => {
    const format = formOptions.formats.find(
      ({ type }) => type === formatField?.value
    );
    return format;
  }, [formOptions.formats, formatField]);

  return (
    <Stack gap="300" width="100">
      {/* {isError && <ErrorBox message={getErrorMessage(error, t)} />} */}
      <ActionItemTypeFormKeyInformation
        actionItemType={actionItemType}
        formOptions={formOptions}
        formatConfig={aitFormatConfig}
        crudFormMode={crudFormMode}
      />
      <ActionItemTypeFormFields
        actionItemType={actionItemType}
        formOptions={formOptions}
        formatConfig={aitFormatConfig}
        crudFormMode={crudFormMode}
      />
      <ActionItemTypeFormPermissions
        actionItemType={actionItemType}
        formatConfig={aitFormatConfig}
        crudFormMode={crudFormMode}
      />
      <ActionItemTypeFormWorkflow
        actionItemType={actionItemType}
        formOptions={formOptions}
        crudFormMode={crudFormMode}
      />
    </Stack>
  );
};

export default ActionItemTypeForm;
