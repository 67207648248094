import React, { useCallback, useEffect, useMemo, useState } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { systemConstants } from "@shared/constants";
import { formattedCodes } from "@shared/helpers";

import { validateForm } from "@app/helpers/userForm";
import { useGoBack } from "@app/hooks";

import { Inline, Stack } from "@fermions";

import Form from "@components/atoms/Form";
import UserProfileImage from "@components/organisms/UserProfileImage";
import FormPageTemplate from "@components/templates/FormPageTemplate";

import "./UserForm.scss";

const UserForm = props => {
  const {
    title,
    breadcrumbs,
    formConfig,
    user,
    submitLabel,
    loading,
    other,
    moreContents
  } = props;

  const { t } = useTranslation();
  const goBack = useGoBack();
  const [avatar, setAvatar] = useState(user?.avatar);
  const handleProfileImageSetOrRemoveClick = image => {
    setAvatar(image ? { image } : null);
  };

  const transformDefault = useCallback(
    (field, value) => {
      if (!value) {
        return null;
      }
      if (field.translate) {
        return { defaultValue: { ...value, name: t(value.name) } };
      }
      return { defaultValue: { ...value, name: value.name } };
    },
    [t]
  );

  useEffect(() => {
    if (user?.avatar) {
      setAvatar(user.avatar);
    }
  }, [user?.avatar]);

  const yupSchema = useMemo(() => {
    const schema = {
      properties: yup.object().shape({
        notification: yup.object().shape({
          email: yup
            .string()
            .required("common:user.configured.fields.notification.errorMessage")
        })
      })
    };

    (formConfig || []).forEach(field => {
      if (field.type === "custom") {
        field.fields.forEach(f => {
          validateForm({ field: f, schema, i18nText: t });
        });
      } else {
        validateForm({ field, schema, i18nText: t });
      }
    });
    return yup.object(schema);
  }, [formConfig, t]);

  const renderForm = useCallback(
    field => {
      const label =
        field.key && t(`common:user.configured.fields.${field.key}.label`);
      switch (field?.type) {
        case "text":
        case "number":
          return (
            <Form.TextField
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              defaultValue={get(user, field.key) ?? ""}
            />
          );
        case "code":
          const value =
            get(user, field.key) ?? systemConstants.defaultCountryCode;
          return (
            <Form.Dropdown
              defaultValue={{
                id: 0,
                name: value,
                code: value
              }}
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              disabled={field.disabled ?? false}
              items={formattedCodes.filter(
                codeObject => codeObject.code === value
              )}
              transformSelected={value => value.code}
            />
          );
        case "dropdown":
          const defaultValue = get(user, field.key);
          return (
            <Form.Dropdown
              key={field.key}
              name={field.key}
              label={label}
              required={field.required}
              {...(transformDefault(field, defaultValue) ?? {})}
              disabled={field.disabled ?? false}
              items={field.items}
            />
          );
        case "radio":
          const items = field.items.map(item => ({
            value: item,
            label: t(`common:user.configured.fields.${field.key}.${item}.label`)
          }));
          return (
            <Form.RadioGroup
              defaultValue={get(user, field.path, field.defaultValue)}
              name={field.path ?? field.key}
              label={
                field.key &&
                t(`common:user.configured.fields.${field.key}.label`)
              }
              required={false}
              disabled={false}
              items={items}
            />
          );
        case "multiselect":
          return (
            <Form.Multiselect
              label={label}
              key={field.key}
              name={field.key}
              required={field.required}
              disabled={field.disabled ?? false}
              items={field.items ?? []}
              defaultValue={field.defaultValue}
              placeholder={field.placeholder}
            />
          );
        default:
          return <></>;
      }
    },
    [t, transformDefault, user]
  );

  const handleSubmit = profile => {
    const userProfile = structuredClone(profile);
    if (user?.id) {
      userProfile.id = user.id;
    }
    if (avatar !== false) {
      userProfile.avatar = avatar;
    }
    userProfile.properties = {
      ...(user?.properties ?? {}),
      ...userProfile.properties
    };
    const countryCodes = ["businessCountryCode", "mobileCountryCode"];
    countryCodes.forEach(codeKey => {
      const value = userProfile[codeKey];
      if (value) {
        userProfile[codeKey] = value.code;
      }
    });
    userProfile.roleId = userProfile.role?.id;
    props.handleSubmit(userProfile);
  };

  const [userProfileModal, setUserProfileModal] = useState(<></>);
  const contents = useMemo(
    () => (
      <Stack gap="300" className={"user-form-contents"}>
        {moreContents ?? <></>}
        <Inline gap="300">
          <UserProfileImage
            avatar={avatar}
            profileImageSetClick={handleProfileImageSetOrRemoveClick}
            name={user?.name ?? ""}
            setUserProfileModal={setUserProfileModal}
          />
          <Stack gap="300" width="100">
            {formConfig.map(field => {
              if (field.type === "custom") {
                return (
                  <React.Fragment key={field.key}>
                    {field.showLabel && (
                      <div className="user-form__label">
                        {field.key &&
                          t(`common:user.configured.fields.${field.key}.label`)}
                      </div>
                    )}
                    <div className="user-form__inline" key={field.key}>
                      {field.fields?.map(renderForm)}
                    </div>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={field.key}>
                  {renderForm(field)}
                </React.Fragment>
              );
            })}
          </Stack>
        </Inline>
      </Stack>
    ),
    [avatar, formConfig, moreContents, renderForm, t, user?.name]
  );

  return (
    <>
      <FormPageTemplate
        title={title}
        breadcrumbs={breadcrumbs}
        alwaysShowBreadcrumbs
        sticky
        form={{
          key: user?.id,
          handleSubmit: handleSubmit,
          submitLabel: submitLabel,
          yupSchema: yupSchema,
          contents,
          handleCancel: goBack
        }}
        isLoading={loading}
        other={other}
      />
      {userProfileModal}
    </>
  );
};

UserForm.propTypes = {
  formConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      type: PropTypes.string
    })
  ),
  handleSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  user: PropTypes.object
};

export default UserForm;
