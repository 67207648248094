import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { menuItems } from "@shared/helpers/menuItems";
import {
  useActionItemFilters,
  useAuthUser,
  useClients,
  useCurrentProject,
  useDidMount,
  useFeatures,
  useGetClientByIdQuery,
  useGetClientFromParams,
  useGetClientsForMenuQuery,
  useGetMyProjects,
  useLogout,
  useShareStatus
} from "@shared/hooks";
import useAccessControl from "@shared/hooks/useAccessControl";
import { useGetClientProjectsForMenuQuery } from "@shared/services/clientProjectsService";

import { routeConstants } from "@app/constants";
import UIConfigContext from "@app/helpers/UIConfigContext";
import { useAdminNavItems, useDefaultLandingPageLink } from "@app/hooks";

import Shape from "@components/atoms/Shape";
import GroupLabels from "@components/molecules/GroupLabels";
import TopNavigationTemplate from "@components/templates/TopNavigationTemplate";

const { getTopMenuItems, getProfileMenuItems } = menuItems;
const Heading = props => {
  const { subNavLeftPosition, setSubNavActive, handleNavigate, isolationMode } =
    props;
  const { isEnabled } = useFeatures();
  const { t } = useTranslation();
  const location = useLocation();
  const { user, userProfile } = useAuthUser({ withProfile: true });
  const { clients, fetchClients } = useClients({ enableAll: true });

  const { data: clientsForMenu } = useGetClientsForMenuQuery(null, {
    skip: !user.isHostUser
  });
  const { data: projectsForMenu } = useGetClientProjectsForMenuQuery(
    user.clientId,
    { skip: user.isHostUser }
  );
  const { logout } = useLogout();
  const { myProjects, fetchMyProjects } = useGetMyProjects();
  const [landingPageLink, setLandingPageLink] = useState("");
  const { currentProject } = useCurrentProject();
  const { adminNavItems } = useAdminNavItems();
  const uiConfig = useContext(UIConfigContext);
  const { filterByPermissionFn } = useAccessControl();

  const { client, isLoading: isClientLoading } = useGetClientFromParams();
  const { getShareStatus, setShareStatusProject, subscribeProjectShareStatus } =
    useShareStatus();
  const { getDefaultLandingPageLink } = useDefaultLandingPageLink();
  const { didMountRef, startLoading } = useDidMount();

  useGetClientByIdQuery(currentProject?.client?.id, {
    skip: !currentProject?.client
  });

  const { onChangeProject } = useActionItemFilters();

  const navigate = useNavigate();

  useEffect(() => {
    onChangeProject(currentProject?.id);
  }, [currentProject, onChangeProject]);

  useEffect(() => {
    if (
      location.pathname !== routeConstants.clientUserLandingPage &&
      user.isClientUser
    ) {
      fetchMyProjects({ withDetails: false });
    }
  }, [fetchMyProjects, location, user]);

  useEffect(() => {
    if (user.isClientUser) {
      getShareStatus();
    }
  }, [user, getShareStatus]);

  useEffect(() => {
    if (currentProject) {
      setShareStatusProject(currentProject);
    }
  }, [currentProject, setShareStatusProject]);

  const showGroupLabel = useMemo(() => {
    if (user?.isHostUser) {
      return !isClientLoading ? clients?.length > 0 : false;
    }
    if (uiConfig?.sideNavigation?.enabled) {
      return myProjects?.length > 0;
    }
    return false;
  }, [
    clients?.length,
    isClientLoading,
    myProjects?.length,
    uiConfig?.sideNavigation?.enabled,
    user
  ]);

  useEffect(() => {
    if (didMountRef.current) {
      subscribeProjectShareStatus();
    }
  }, [didMountRef, startLoading, user, subscribeProjectShareStatus]);

  useEffect(() => {
    if (user && !clients.length && didMountRef.current) {
      fetchClients(user);
      startLoading(true);
    }
  }, [fetchClients, didMountRef, user, startLoading, clients?.length]);

  useEffect(() => {
    if (user) {
      setLandingPageLink(getDefaultLandingPageLink(user));
    }
  }, [user, getDefaultLandingPageLink]);

  const handleSelectClientFilter = useCallback(
    () => client => {
      if (client.id !== "ALL CLIENTS") {
        navigate(`${routeConstants.clients}/${client.id}`);
      } else {
        navigate(routeConstants.hostLandingPage);
      }
    },
    [navigate]
  );

  const handleSelectProjectFilter = useCallback(
    project => {
      onChangeProject();
      handleNavigate(`/projects/${project.id}`);
    },
    [handleNavigate, onChangeProject]
  );

  const getProjectsGroupLabel = useCallback(() => {
    if (
      !uiConfig?.sideNavigation?.enabled ||
      !uiConfig?.multipleProjectSelection?.enabled
    ) {
      return <></>;
    }
    const groupLabel =
      currentProject &&
      window.location.pathname !== routeConstants.clientDashboard
        ? currentProject.name
        : t("common:ui.header.dropdown.defaultProjectDropdownName");

    return (
      <GroupLabels
        groupLabel={groupLabel}
        dropdownItems={myProjects}
        handleSelectFilter={handleSelectProjectFilter}
        styleVariant={uiConfig?.groupLabelDropdown?.styleVariant}
        position={"right"}
        icon={<Shape size="tiny" color="text" />}
        navigationStyle={uiConfig?.theme?.navigationStyle}
      />
    );
  }, [uiConfig, currentProject, t, myProjects, handleSelectProjectFilter]);

  const renderGroupLabels = useCallback(() => {
    if (!showGroupLabel) {
      return <></>;
    }
    const groupLabel = client
      ? client?.name
      : t("common:ui.header.dropdown.defaultClientDropdownName");

    if (user.isHostUser) {
      return getProjectsGroupLabel();
    }

    return (
      <GroupLabels
        groupLabel={groupLabel}
        dropdownItems={clients}
        handleSelectFilter={handleSelectClientFilter()}
        styleVariant={uiConfig?.groupLabelDropdown?.styleVariant}
        icon={<i className="material-icons">apartment</i>}
        disabled={false}
        position={uiConfig?.sideNavigation?.enabled ? "right" : "left"}
        navigationStyle={uiConfig?.theme?.navigationStyle}
      />
    );
  }, [
    showGroupLabel,
    client,
    t,
    user,
    clients,
    handleSelectClientFilter,
    uiConfig,
    getProjectsGroupLabel
  ]);

  const navItems = useMemo(() => {
    return getTopMenuItems({
      user,
      projectsForMenu,
      clientsForMenu,
      i18nText: t
    })
      .filter(filterByPermissionFn)
      .filter(
        ({ enabledFeatures }) =>
          !enabledFeatures || enabledFeatures.map(isEnabled).every(Boolean)
      );
  }, [
    user,
    projectsForMenu,
    clientsForMenu,
    t,
    filterByPermissionFn,
    isEnabled
  ]);

  return (
    <TopNavigationTemplate
      landingPageLink={landingPageLink}
      searchBar={null}
      showGroupLabel={showGroupLabel}
      groupLabels={renderGroupLabels()}
      handleSelectFilter={handleSelectProjectFilter}
      menuItems={getProfileMenuItems({ handleLogout: logout, i18nText: t })}
      navItems={navItems}
      adminNavItems={adminNavItems}
      user={userProfile}
      showSideBar={uiConfig?.sideNavigation?.enabled}
      isolationMode={isolationMode}
      subNavLeftPosition={subNavLeftPosition}
      setSubNavActive={setSubNavActive}
      handleNavigate={handleNavigate}
      currentPath={location.pathname}
      navigationStyle={uiConfig?.theme?.navigationStyle}
    />
  );
};

Heading.defaultProps = {
  isolationMode: false
};

Heading.propTypes = {
  subNavLeftPosition: PropTypes.string,
  setSubNavActive: PropTypes.func,
  handleNavigate: PropTypes.func,
  isolationMode: PropTypes.bool,
  sideNavigation: PropTypes.object
};

export default Heading;
