import { useCallback } from "react";

import { useUpdateUserMutation } from "@shared/services";

const status = {
  active: "ACTIVE",
  inactive: "INACTIVE"
};

export function useUpdateUserStatus() {
  const [updateUser] = useUpdateUserMutation();

  const activateUser = useCallback(
    ({ id: userId }) => {
      const userUpdate = { id: userId, status: status.active };
      updateUser({ user: userUpdate });
    },
    [updateUser]
  );

  const deactivateUser = useCallback(
    ({ id: userId }) => {
      const userUpdate = { id: userId, status: status.inactive };
      updateUser({ user: userUpdate });
    },
    [updateUser]
  );

  return {
    activateUser,
    deactivateUser
  };
}
