import React, { useCallback, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ErrorBox from "@shared/components/errorBox/ErrorBox";
import { useAuthUser } from "@shared/hooks";
import { useGetClientUsersQuery, useGetHostUsersQuery } from "@shared/services";

import { getErrorMessage } from "@app/helpers/error";
import { AccessLevel, ResourceName } from "@app/types";

import { Stack } from "@fermions";

import { InputContainer } from "@templates/InputContainer";

import Form from "@components/atoms/Form";
import SelectProjectYearField from "@components/molecules/SelectProjectYearField";
import UploadProjectEntitiesFormField from "@components/molecules/UploadProjectEntitiesFormField";
import SelectClientDropdown from "@components/organisms/SelectClientDropdown";
import SelectEngagementTypeDropdown from "@components/organisms/SelectEngagementTypeDropdown";
import BoxTemplate from "@components/templates/BoxTemplate";

import { ProjectFormQueries } from "./ProjectFormQueries";

function ProjectForm(props) {
  const { t } = useTranslation();
  const {
    enableEntities,
    enableProjectManager,
    enableProjectYear,
    enableCopyEngagementUsers,
    isTemplateSelected,
    projectTemplate,
    enableProjectQueries,
    startDateLabel,
    endDateLabel,
    projectManagers,
    project,
    showUpdateFieldsOnly,
    handleEngagementTypeChange,
    isError,
    error,
    title,
    subText
  } = props;
  const { watch, reset, setValue } = useFormContext();

  const { user } = useAuthUser();
  const watchStartDate = watch("startDate");
  const watchEndDate = watch("plannedCompletionDate");
  const watchClient = watch("client");
  const watchEngagementType = watch("engagementType");

  const hasReadAccess = resourceName =>
    user.checkAccess(resourceName, AccessLevel.READ);

  const { data: hostUsers = [] } = useGetHostUsersQuery(null, {
    skip: !enableProjectQueries || !hasReadAccess(ResourceName.HOST_USERS)
  });
  const { data: clientUsers = [] } = useGetClientUsersQuery(
    watchClient?.value?.id,
    {
      skip:
        !watchClient?.value?.id ||
        !enableProjectQueries ||
        !hasReadAccess(ResourceName.CLIENT_USERS)
    }
  );

  useEffect(() => {
    if (
      watchClient?.value?.id &&
      isTemplateSelected &&
      projectTemplate?.queries?.length
    ) {
      setValue("assignedTo", null);
      setValue("copiedTo", null);
    }
  }, [
    isTemplateSelected,
    projectTemplate?.queries?.length,
    setValue,
    watchClient?.value?.id
  ]);

  const getProjectEndDate = useCallback(
    projectDuration => {
      const projectEndDate = new Date(watchStartDate);
      if (projectDuration) {
        projectEndDate.setDate(projectEndDate.getDate() + projectDuration);
      }
      return projectEndDate;
    },

    [watchStartDate]
  );

  const defaultProjectManager = useMemo(
    () =>
      project?.projectManager
        ? {
            name: project?.projectManager?.name,
            value: project?.projectManager
          }
        : null,
    [project]
  );

  const projectManagerItems = useMemo(
    () =>
      projectManagers
        ?.map(projectManager => ({
          name: projectManager?.name,
          value: projectManager
        }))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)) ?? [],
    [projectManagers]
  );

  const projectEndDate = useMemo(() => {
    if (isTemplateSelected && watchStartDate) {
      return getProjectEndDate(project?.duration);
    }
    if (!project.plannedCompletionDate) {
      return null;
    }
    return new Date(project?.plannedCompletionDate);
  }, [
    getProjectEndDate,
    isTemplateSelected,
    project?.duration,
    project.plannedCompletionDate,
    watchStartDate
  ]);

  useEffect(() => {
    if (!watchStartDate && isTemplateSelected) {
      setValue("plannedCompletionDate", null);
    }
  }, [setValue, watchStartDate, isTemplateSelected]);

  //Reset form fields when config changes (this clears validation checks applied by schema validation linked to previous config)
  useEffect(() => {
    reset(fieldValues => ({ ...fieldValues }), { keepValues: true });
  }, [
    enableEntities,
    enableProjectManager,
    enableCopyEngagementUsers,
    enableProjectYear,
    enableProjectQueries,
    watchEngagementType,
    reset
  ]);

  return (
    <Stack gap="300" width="100">
      {isError && <ErrorBox message={getErrorMessage(error, t)} />}
      {/* Key information */}
      <BoxTemplate title={title} subtext={subText}>
        <Stack gap="300">
          {/* Used for adding information to formState, thats relevant for validation */}
          <Form.HiddenInput
            key="entitiesEnabled"
            name="entitiesEnabled"
            value={enableEntities}
          />

          <InputContainer>
            <SelectClientDropdown
              disabled={showUpdateFieldsOnly}
              name="client"
              isFormField
              required
              defaultValue={
                project?.client && {
                  name: project?.client?.name,
                  value: project?.client
                }
              }
            />

            <Form.TextField
              name="projectName"
              label={t("common:ui.projects.name.label")}
              required
              defaultValue={project?.name}
            />
            <SelectEngagementTypeDropdown
              onChange={handleEngagementTypeChange}
              isFormField
              required
              hideIfOne
              disablePreselectDefault={true}
              disabled={showUpdateFieldsOnly || isTemplateSelected}
              defaultValue={project?.engagementType?.id}
            />
            {enableProjectYear && (
              <SelectProjectYearField
                name="year"
                label={t("common:ui.projects.year.label")}
                required
                defaultValue={
                  project?.year ?? new Date().getFullYear().toString()
                }
              />
            )}

            {enableProjectManager && (
              <Form.Dropdown
                name="projectManager"
                label={t("common:ui.projects.manager")}
                items={projectManagerItems}
                defaultValue={defaultProjectManager}
                required
              />
            )}
            <Form.DateField
              name="startDate"
              label={startDateLabel}
              maxDate={watchEndDate}
              required
              onlyShowDescriptionOnOverflow
              disabled={showUpdateFieldsOnly}
              defaultValue={
                showUpdateFieldsOnly ? new Date(project?.startDate) : null
              }
              labelDescription={startDateLabel}
            />
            <Form.DateField
              name="plannedCompletionDate"
              label={endDateLabel}
              required
              onlyShowDescriptionOnOverflow
              minDate={watchStartDate}
              disabled={showUpdateFieldsOnly || isTemplateSelected}
              defaultValue={projectEndDate}
              labelDescription={endDateLabel}
            />
          </InputContainer>

          {enableEntities && (
            <UploadProjectEntitiesFormField
              name="entities"
              defaultValue={project?.entities}
              required
              disabled={showUpdateFieldsOnly}
            />
          )}

          {enableCopyEngagementUsers && (
            <Form.Checkbox
              name="copyEngagementUsers"
              label={t("common:ui.projects.copyUsers")}
              defaultValue={true}
            />
          )}
        </Stack>
      </BoxTemplate>
      {enableProjectQueries && (
        <ProjectFormQueries
          projectTemplate={projectTemplate}
          clientUsers={clientUsers}
          hostUsers={hostUsers}
        />
      )}
    </Stack>
  );
}

ProjectForm.defaultProps = {
  enableEntities: false,
  enableProjectManager: false,
  enableProjectYear: false,
  showUpdateFieldsOnly: false,
  enableProjectQueries: false
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  enableEntities: PropTypes.bool,
  enableProjectManager: PropTypes.bool,
  enableProjectYear: PropTypes.bool,
  enableCopyEngagementUsers: PropTypes.bool,
  isTemplateSelected: PropTypes.bool,
  projectTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string,
    milestones: PropTypes.any,
    queries: PropTypes.any
  }),
  enableProjectQueries: PropTypes.bool,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  projectManagers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  showUpdateFieldsOnly: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.object,
  handleEngagementTypeChange: PropTypes.func,
  title: PropTypes.string,
  subText: PropTypes.string
};

export default ProjectForm;
