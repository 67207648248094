import React, { useMemo } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { systemConstants } from "@shared/constants";
import { useAuthUser } from "@shared/hooks/useAuthUser";

import { workflowActionName } from "@app/helpers/actionItems.js";
import { menuItemAccess } from "@app/helpers/menuItemAccess";

import { Box, Inline } from "@fermions";

import { Button } from "@atoms/Button";
import { IconFillStyle } from "@atoms/Icon";

import { ButtonMenu } from "@components/molecules/ButtonMenu/ButtonMenu";
import { Positioning } from "@components/molecules/DropdownList/DropdownHelper";
import FileLink from "@components/organisms/FileLink";

import "./QueryActions.scss";

export const buttonActionConstants = {
  reassign: "REASSIGN",
  closeRequest: "CLOSE_REQUEST",
  addResponse: "ADD_RESPONSE",
  reopen: "REOPEN",
  closePage: "CLOSE_PAGE",
  download: "DOWNLOAD",
  sendToHost: "SEND_TO_HOST",
  transition: "TRANSITION",
  workflow: "WORKFLOW",
  approve: "APPROVE",
  reject: "REJECT",
  review: "REVIEW"
};

const STATUS = systemConstants.project.queries.status;
const FLAG = systemConstants.project.queries.flags;

const isOpened = s => s === STATUS.responded || s === STATUS.open;

const isDraft = s => s === STATUS.draft;

const isResponded = s => s === STATUS.responded;

const isClosed = s => s === STATUS.closed;

const getActionButtons = ({
  props,
  user,
  menuConfig,
  extraButtonConfig = [],
  i18nText: t
}) => {
  const { queryConfig } = props;

  const actions = [];
  const buttons = menuConfig.find(menu => menu.name === "BUTTONS");

  if (!buttons) {
    return actions;
  }

  const buttonLabelForAction = action => {
    const actionKey = action.i18nActionKey ?? action.action;
    return (
      t(`requests:requests.configured.menu.actions.${actionKey}.label`, {
        context:
          queryConfig.type === systemConstants.actionItemTypes.smartForm
            ? systemConstants.actionItemTypes.smartForm
            : queryConfig.key
      }) ?? action.name
    );
  };

  extraButtonConfig.forEach(b => {
    switch (b.action) {
      case buttonActionConstants.closePage:
        actions.push({
          variant: "text-primary",
          icon: "keyboard_backspace",
          name: t("requests:requests.ui.requestActions.actions.closePageLabel"),
          value: buttonActionConstants.closePage,
          handler: props.requestHandlers[buttonActionConstants.closePage]
        });
    }
  });

  buttons.actions
    .filter(a => {
      const hasActionHandler =
        a.action in props.requestHandlers ||
        a.action === buttonActionConstants.transition;
      return (
        hasActionHandler &&
        menuItemAccess({
          // @ts-expect-error: Temporarily ignoring until the library types are updated
          availableTo: a.availableTo,
          user,
          isRequestor: props.isUserRequestor,
          from: a.from,
          queryStatus: props.queryStatus
        })
      );
    })
    .forEach(a => {
      switch (a.action) {
        case buttonActionConstants.addResponse: {
          if (!isClosed(props.queryStatus)) {
            actions.push({
              variant: "primary",
              icon: "add",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.addResponse,
              handler: props.requestHandlers[buttonActionConstants.addResponse]
            });
          }
          break;
        }
        case buttonActionConstants.closeRequest: {
          if (isOpened(props.queryStatus) && !isDraft(props.queryStatus)) {
            actions.push({
              variant: "secondary",
              icon: "task_alt",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.closeRequest,
              handler: props.requestHandlers[buttonActionConstants.closeRequest]
            });
          }
          break;
        }
        case buttonActionConstants.workflow: {
          actions.push({
            type: buttonActionConstants.workflow,
            handler: props.requestHandlers[buttonActionConstants.workflow]
          });
          break;
        }
        case buttonActionConstants.reassign: {
          if (isOpened(props.queryStatus) || isDraft(props.queryStatus)) {
            actions.push({
              variant: "secondary",
              icon: "swap_horiz",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.reassign,
              handler: props.requestHandlers[buttonActionConstants.reassign]
            });
          }
          break;
        }
        case buttonActionConstants.reopen: {
          if (isClosed(props.queryStatus)) {
            actions.push({
              variant: "primary",
              icon: "",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.reopen,
              handler: props.requestHandlers[buttonActionConstants.reopen]
            });
          }
          break;
        }
        case buttonActionConstants.download: {
          actions.push({
            variant: "FileLink",
            name: buttonLabelForAction(a),
            value: buttonActionConstants.download,
            handler: props.requestHandlers[buttonActionConstants.download]
          });
          break;
        }
        case buttonActionConstants.sendToHost: {
          if (
            isOpened(props.queryStatus) &&
            !isResponded(props.queryStatus) &&
            !user.isHostUser
          ) {
            actions.push({
              variant: "primary",
              icon: "",
              name: buttonLabelForAction(a),

              value: buttonActionConstants.sendToHost,
              handler: props.requestHandlers[buttonActionConstants.sendToHost]
            });
          }
          break;
        }
        case buttonActionConstants.approve:
          if (isOpened(props.queryStatus) || isResponded(props.queryStatus)) {
            actions.push({
              variant: "success",
              icon: "check_circle",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.approve,
              handler: props.requestHandlers[buttonActionConstants.approve]
            });
          }
          break;
        case buttonActionConstants.reject:
          if (isOpened(props.queryStatus) || isResponded(props.queryStatus)) {
            actions.push({
              variant: "error",
              icon: "cancel",
              name: buttonLabelForAction(a),
              value: buttonActionConstants.reject,
              handler: props.requestHandlers[buttonActionConstants.reject]
            });
          }
          break;
        case buttonActionConstants.review: {
          if (isOpened(props.queryStatus) || isResponded(props.queryStatus)) {
            actions.push({
              variant: "secondary",
              icon: "",
              name: buttonLabelForAction(a.transition),
              value: buttonActionConstants.review,
              handler: props.requestHandlers[buttonActionConstants.review]
            });
          }
          break;
        }
      }
    });
  return actions;
};

/**
 * @param {{
 *  workflowAction: { config: { i18nActionKey?: string, name?: string }, key?: string},
 *  queryConfig: {type: string, key: string},
 *  t: function
 * }} param0
 */
const getButtonLabelForWorkflowAction = ({
  workflowAction,
  queryConfig,
  t
}) => {
  return workflowActionName({
    workflowAction,
    actionItemTypeFormat: queryConfig.type,
    actionItemTypeKey: queryConfig.key,
    t
  });
};

const QueryActions = props => {
  const { user } = useAuthUser();
  const { t } = useTranslation();

  const { nextActions, fromStep } = props.workFlowSteps ?? {};
  const disableWorkflowAction = props.disableWorkflowAction ?? false;

  const actions = useMemo(() => {
    switch (props.queryType) {
      case systemConstants.project.queries.queryTypes.todoRequest:
      case systemConstants.project.queries.queryTypes.workFlowRequest:
      case systemConstants.project.queries.queryTypes.queryRequest:
        return getActionButtons({
          props,
          user,
          menuConfig: props.queryConfig.menus ?? [],
          i18nText: t
        });
      default:
        if (
          [
            systemConstants.actionItemTypes.conversation,
            systemConstants.actionItemTypes.smartForm
          ].includes(props.queryConfig.type)
        ) {
          return getActionButtons({
            props,
            user,
            menuConfig: props.queryConfig.menus ?? [],
            extraButtonConfig: props.queryConfig.buttons ?? [],
            i18nText: t
          });
        } else {
          return [];
        }
    }
  }, [props, t, user]);

  const [nonReviewItems, reviewItems] = useMemo(
    () =>
      _.partition(
        actions,
        item =>
          ![
            buttonActionConstants.review,
            buttonActionConstants.approve,
            buttonActionConstants.reject
          ].includes(item.value)
      ),
    [actions]
  );

  const reviewButton = useMemo(() => {
    if (!reviewItems.length) {
      return <></>;
    }
    let variant = "primary";
    let label = t("requests:requests.ui.review.label");

    if (props.queryFlag) {
      label = t("requests:requests.configured.flags", {
        context: props.queryFlag
      });
      if (props.queryFlag === FLAG.approved) {
        variant = "success";
      }
      if (props.queryFlag === FLAG.rejected) {
        variant = "danger";
      }
    }
    const order = [
      buttonActionConstants.review,
      buttonActionConstants.approve,
      buttonActionConstants.reject
    ];

    const menuItems = order
      .map(c => reviewItems.find(i => i.value === c))
      .filter(i => i)
      .map(item => ({
        name: item.name,
        value: item.value,
        icons: [
          {
            icon: item.icon,
            fillStyle: IconFillStyle.FILLED,
            colorStyle: item.variant
          }
        ]
      }));

    return (
      <ButtonMenu
        variant={variant}
        positionRelative={true}
        menuItems={menuItems}
        label={label}
        onMenuItemClick={clickedItem =>
          reviewItems.find(item => clickedItem.name === item.name)?.handler?.()
        }
        positioning={Positioning.BOTTOM_LEFT}
      />
    );
  }, [props.queryFlag, reviewItems, t]);

  return (
    <Inline className="ot-query-actions" alignment="right">
      {nonReviewItems.map((action, index) => {
        if (action.variant === "FileLink") {
          return (
            <Box key={index} className="ot-query-actions-items">
              <FileLink
                key={index}
                label={action.name}
                apiUrl={`queries/${props.queryId}/answers/download`}
                downloadingLabel={action.name}
                setErrorMessage={action.handler}
              />
            </Box>
          );
        } else if (action.type === buttonActionConstants.workflow) {
          if (!nextActions?.length) {
            return <></>;
          }

          if (nextActions?.length === 1) {
            return (
              <Box
                key={`box-workflow-button-${action.name}`}
                className="ot-query-actions-items"
                alignment="center"
              >
                <Button
                  type="primary"
                  iconName={action.icon}
                  label={getButtonLabelForWorkflowAction({
                    workflowAction: nextActions[0],
                    queryConfig: props.queryConfig,
                    t
                  })}
                  onClick={() => {
                    action.handler(nextActions[0].key);
                  }}
                  disabled={disableWorkflowAction}
                />
              </Box>
            );
          }
          return (
            <Box
              key={`box-workflow-menu-${action.name}`}
              className="ot-query-actions-items"
              alignment="center"
            >
              <ButtonMenu
                type="primary"
                positionRelative={true}
                menuItems={nextActions.map(item => ({
                  name: getButtonLabelForWorkflowAction({
                    workflowAction: item,
                    queryConfig: props.queryConfig,
                    t
                  }),
                  value: item.key
                }))}
                label={fromStep}
                onMenuItemClick={({ value: actionKey }) =>
                  action.handler(actionKey)
                }
                positioning={Positioning.BOTTOM_LEFT}
                disabled={disableWorkflowAction}
              />
            </Box>
          );
        } else {
          return (
            <Box
              key={`actionItems-button-box-${action.name}`}
              className="ot-query-actions-items"
              alignment="center"
            >
              <Button
                key={`actionItems-button-${action.name}`}
                variant={action.variant}
                iconName={action.icon}
                label={action.name}
                onClick={action.handler}
                disabled={action.disabled}
              />
            </Box>
          );
        }
      })}
      {reviewButton}
    </Inline>
  );
};

QueryActions.defaultProps = {};

QueryActions.propTypes = {
  queryConfig: PropTypes.object.isRequired,
  queryType: PropTypes.string.isRequired,
  queryFlag: PropTypes.string,
  queryId: PropTypes.number.isRequired,
  queryStatus: PropTypes.string.isRequired,
  workFlowSteps: PropTypes.object,
  isUserRequestor: PropTypes.bool.isRequired,
  requestHandlers: PropTypes.any.isRequired, // Key lookup with button as key
  disableWorkflowAction: PropTypes.bool
};

export default QueryActions;
